/*
* This will handle the notification bell component and will be used to display if there are any new notifications
* and if clicked, will open a scrollable dropdown with the notifications
*/
import { useState, useEffect } from "react";

import { Menu, Dropdown, Badge, Button, Typography, Spin } from "antd";
import { BellFilled, } from "@ant-design/icons";
import { INotification } from "../../stores/notification/notificationModel";
import {
    notificationBellHOCWrapper, NotificationListType
} from "./notificationBellWrapper";
import NotificationCard from "./notificationCard";
import "./notificationDropdown.css";

const { Title, Text } = Typography;

const NotificationBell = (props: NotificationListType) => {
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [loadingNotifications, setLoadingNotifications] = useState(true);
    useEffect(() => {
        setNotifications(props.notifications);
        setUnreadNotifications(props.unreadNotifications);
        if (props.storeStatus === "ready" || props.storeStatus === "error")
            setLoadingNotifications(false);
        else
            setLoadingNotifications(true);

    }, [props]);

    // Parse the notifications from the store into notification cards
    const menu = (
        <Menu>
            <Title key={"text"} level={5} style={{ fontWeight: "700", paddingTop: "11px", paddingLeft: "27px" }}>{`Notifications (${notifications.length})`} </Title>
            <div id="scrollable-notification-div">
                {
                    notifications.map((notification: INotification, index: number) => {
                        return (
                            <>
                                <Menu.Item key={index} onClick={() => { if (notification.onClickCallback) notification.onClickCallback(); }}>
                                    <NotificationCard
                                        title={notification.title}
                                        message={notification.message}
                                        id={notification.id}
                                        projectId={notification.projectId as string}
                                        read={notification.read}
                                        status={notification.status}
                                        type={notification.type}
                                        subType={notification.subType as string}
                                        createdAt={notification.createdAt}
                                        updatedAt={notification.updatedAt}
                                        extra={notification.extra}
                                    />
                                </Menu.Item>
                                <Menu.Divider />
                            </>
                        );
                    })
                }
            </div>
        </Menu>
    );

    return (
        <div>
            <Dropdown overlay={
                notifications.length > 0 ? menu :
                    // If there are no notifications, display a loading spinner while the notifications are being fetched
                    (
                        loadingNotifications ?
                            <Menu >
                                <Spin size="small" style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyItems: "center",
                                    padding: "10px"

                                }} />
                            </Menu>
                            :
                            <Menu>
                                <Title key={"text"} level={5} style={{ fontWeight: "700", padding: "11px 27px", margin: "0px" }}>{`Notifications`} </Title>
                                <div id="scrollable-notification-div">
                                    <Menu.Item key={1}>
                                        <Text style={{ fontSize: "14px", paddingLeft: "2rem" }}> No new notifications </Text>
                                    </Menu.Item>
                                </div>
                            </Menu>
                    )
            } trigger={["click"]} onVisibleChange={(vis) => {
                if (!vis) {
                    try {
                        props.readAllNotifications();
                    } catch (e) {
                        console.log("Error: ", e);
                    }
                }
            }}>
                <Button
                    style={{
                        backgroundColor: "transparent",
                        border: "transparent",
                        borderWidth: "1px",
                        padding: "0px",
                        margin: "0px"
                    }}
                    type="text"
                >
                    <Badge count={unreadNotifications} size="small">
                        <BellFilled className="notification-bell-icon" />
                    </Badge>
                </Button>
            </Dropdown>
        </div>
    );
};

const NotificationBellWrapper = notificationBellHOCWrapper(NotificationBell);

export default NotificationBellWrapper;
