import { Input } from "antd";
import { observer } from "mobx-react-lite";
import "./FrameIndicator.scss";

export const FrameIndicator = observer(({currentFrame,totalFrames}:any) => {
  function change(e:any) {
    const player = (window as any).p
    if(player){
      player.seekTo(parseInt((e.target as HTMLInputElement).value));
      player.pause();
      player.pausedByUser = true;
    }
  }
  return (
    <div className="FrameIndicator">
      <Input
        style={{ width: "80px" }}
        type="number"
        min="0"
        max={totalFrames}
        onChange={change}
        value={currentFrame}
        className="FrameIndicatorInput"
      ></Input>
      <div className="FrameIndicatorMax">
      / {totalFrames}
      </div>
    </div>
  );
});
