import "./BlingsContainer.scss";
type Props = {
  children: React.ReactNode;
  className?: string;
};
export const BlingsContainer = (props: Props) => {
  const { children, className } = props;
  return <div className={`BlingsContainer ${className || ""}`}>
    {children}
  </div>;
};
