import { CloudUploadOutlined } from "@ant-design/icons";
import { Progress, Upload } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AssetUploadConnectionContext } from "../DynamicFormContext";
import { UPLOADING } from "./consts";
import styles from "./UploadButton.module.scss";
const UploadButton = (props: any) => {
  const {
    format,
    setValues,
    index,
    value,
    values,
    urlFunction,
    disabled,
    setValue,
  } = props;
  const { uploadAssetToProject } = useContext(AssetUploadConnectionContext);
  const [progressState, setProgressState] = useState(0);
  useEffect(() => {
    if (progressState > 0) {
      if (values) {
        const copyOfValues = JSON.parse(JSON.stringify(values));
        copyOfValues[index].const = UPLOADING + " (" + progressState + "%)";
        setValues(copyOfValues);
      } else {
        setValue(UPLOADING + " (" + progressState + "%)");
      }
    }
  }, [progressState]);
  const pcb = (progress: { loaded: number; total: number }) => {
    const num = (progress.loaded / progress.total) * 100;
    setProgressState(parseFloat(num.toFixed(0)));
  };
  return (
    <Upload
      className={styles.UploadButton}
      disabled={disabled}
      fileList={undefined}
      accept={`${format}/*`}
      multiple={false}
      beforeUpload={() => false}
      showUploadList={false}
      onChange={async ({ file }) => {
        if (values) {
          const copyOfValues = JSON.parse(JSON.stringify(values));
          if (!copyOfValues[index]) {
            copyOfValues[index] = {
              title:
                copyOfValues[index] && copyOfValues[index].title
                  ? copyOfValues[index].title
                  : "",
              const: UPLOADING + " (0%)",
              progress: () => {
                return (
                  <Progress
                    style={{ background: "red", height: "80px" }}
                    width={85}
                    type="dashboard"
                    percent={progressState}
                  />
                );
              },
            };
          } else {
            copyOfValues[index].const = UPLOADING + " (0%)";
          }
          setValues(copyOfValues);
        } else {
          setValue(UPLOADING + " (0%)");
        }
        await uploadAssetToProject(
          file,
          format,
          fileUrl => {
            urlFunction(fileUrl);
          },
          pcb
        );
      }}
    >
      <CloudUploadOutlined className={styles.Icon} onClick={() => {}} />
    </Upload>
  );
};
export default UploadButton;
