import React from "react";
import { Input, InputNumber } from "antd";
import { useContext } from "react";
import { WidgetProps } from "@rjsf/core";
import { getValue } from ".";
import { ColorPicker } from "../ColorPicker";
import { FieldState, FieldStateContext } from "../FieldTemplate";
import { MediaWidget } from "./MediaWidget";
import styles from "./TextWidget.module.scss";
import { JSONSchema7Array } from "json-schema";
const { TextArea } = Input;

function instanceOfJSONSchema7Array(value: any): value is JSONSchema7Array {
  return Array.isArray(value);
}

export const TextWidget = (props: WidgetProps) => {
  const fieldState = useContext(FieldStateContext);
  const defVal = getValue(props);

  let placeholder;

  const { examples } = props.schema;

  if (examples && instanceOfJSONSchema7Array(examples)) {
    placeholder = examples.slice(0, 3).join(", ");
    if (examples.length > 2) {
      placeholder += " ...";
    }
  }
  if (props.schema.type === "integer" || props.schema.type === "number") {
    const {
      maximum,
      exclusiveMaximum,
      minimum,
      exclusiveMinimum,
    } = props.schema;
    const max = exclusiveMaximum || maximum;
    const min = exclusiveMinimum || minimum;
    return fieldState === FieldState.READ_ONLY ? (
      <span className={[styles.NumberWidget, styles.Widget].join(" ")}>
        {defVal}
      </span>
    ) : (
      <InputNumber
        className={[styles.NumberWidget, styles.Widget, styles.Input].join(" ")}
        max={max}
        min={min}
        value={defVal}
        onChange={val => props.onChange(val || 0)}
        placeholder={placeholder}
      />
    );
  }
  if (
    props.schema.format === "image" ||
    props.schema.format === "video" ||
    props.schema.format === "audio"
  ) {
    return (
      <MediaWidget
        {...props}
        value={defVal}
        placeholder={placeholder ? placeholder : ""}
      />
    );
  }

  if (props.schema.format === "color") {
    return (
      <div className={[styles.ColorWidget, styles.Widget].join(" ")}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className={styles.Input}>{defVal?.toUpperCase()}</span>
        </div>
        <ColorPicker
          color={defVal as string}
          onChange={val => {
            props.onChange(val.hex);
          }}
          size={3}
          clickeable={fieldState !== FieldState.READ_ONLY}
        />
      </div>
    );
  }
  return fieldState === FieldState.READ_ONLY ? (
    <span className={[styles.TextWidget, styles.Widget].join(" ")}>
      {defVal}
    </span>
  ) : (
    <TextArea
      className={[styles.TextWidget, styles.Input].join(" ")}
      onPressEnter={e => {
        e.preventDefault();
      }}
      onChange={event => {
        props.onChange(event.target.value || undefined);
      }}
      placeholder={placeholder || "add text value"}
      value={defVal}
      autoSize={{ minRows: 1, maxRows: 3 }}
    />
  );
};
