import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import "./BlingsSearch.scss";
type Props<T> = {
  options: Array<T>;
  setResults?: React.Dispatch<React.SetStateAction<Array<any>>>;
  setCurrentInput: React.Dispatch<React.SetStateAction<string>>;
  noContentFound?: string;
  placeholder?: string;
  currentInput: string;
  alwaysOpen?: boolean;
  sort?: boolean;
  handleSelect?: (e: any) => void;
};

const BlingsSearch = <T extends { label: string; key: string | number; }>({
  setResults,
  alwaysOpen,
  options,
  currentInput,
  setCurrentInput,
  placeholder,
  sort = true,
  handleSelect,
  noContentFound = "No results found",
}: Props<T>) => {
  return (
    <>
      <AutoComplete
        value={currentInput}
        onSelect={handleSelect}
        className={"Search"}
        notFoundContent={noContentFound}
        options={(sort
          ? options.sort((a, b) =>
            // Make project order alphabetical
            a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? -1 : 1
          )
          : options
        ).map((p) => ({
          label: p.label,
          value: p.key,
          title: p.label,
        }))}
        optionFilterProp="options"
        filterOption={(inputValue, option) => {
          return option
            ? option.title
                ?.toLowerCase()
                .trim()
                ?.indexOf(currentInput.toLowerCase().trim()) > -1
            : false;
        }}
        {...(alwaysOpen && { open: true })}
      >
        <Input
          className={"SearchInput"}
          allowClear
          onChange={(e) => setCurrentInput(e.target.value)}
          bordered={false}
          placeholder={placeholder}
          prefix={<SearchOutlined className={"SearchIcon"} />}
          {...(setResults && {
            onPressEnter: (e: any) =>
              setResults(
                options.filter((p) =>
                  p.label
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase().trim())
                )
              ),
          })}
        />
      </AutoComplete>
    </>
  );
};

export default BlingsSearch;
