import React from "react";
import { Button, Typography } from "antd";
import { useContext, useEffect } from "react";
import JSONForm, { ErrorListProps, FormProps } from "@rjsf/core";
import jsonFormStyles from "./JSONFormAntd.module.scss";
import baseStyles from "./BaseStyles.module.scss";

import {
  CurrentOpenFieldContext,
  EditableFormContext,
  SchemaContext,
  SchemaUpdateContext,
  idSep,
} from "./DynamicFormContext";
import { FieldTemplate } from "./FieldTemplate";
import { ObjectFieldTemplate } from "./components/ObjectFieldTemplate";
import { ArrayFieldTemplate } from "./components/ArrayFieldTemplate";
import { TextWidget, CheckboxWidget, SelectWidget } from "./Widgets";
import { defaultValuesFromType } from "./helpers";
import { PlusIcon } from "./components/Icons";
import { FormButton } from "./components/FormButton";

export enum InternalSchemaTypes {
  TEXT = "text",
  //INTEGER = "integer",
  COLOR = "color",
  LINK = "link",
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  BOOLEAN = "boolean",
  NUMBER = "number",
}

const ErrorList = (e: ErrorListProps) => (
  <ErrorMessage
    message={`Please Fix ${e.errors.length} Error${
      e.errors.length > 1 ? "s" : ""
    }`}
  />
);

const ErrorMessage = ({ message }: { message?: string }) => {
  if (!message) {
    return <span />;
  }
  return (
    <span>
      <Typography.Text type="danger">{message}</Typography.Text>
    </span>
  );
};

export const AntdForm = (props: FormProps<HTMLElement>) => {
  const schemaContext = useContext(SchemaContext);
  const schemaUpdateContext = useContext(SchemaUpdateContext);
  const [currentOpenField, setCurrentOpenField] = useContext(
    CurrentOpenFieldContext
  );
  const { editable, readable } = useContext(EditableFormContext);
  const addField = () => {
    const newSchema = { ...schemaContext };
    newSchema.properties = newSchema.properties || {};
    schemaUpdateContext.updateSchema({
      key: "",
      type: "string",
      def: defaultValuesFromType[InternalSchemaTypes.TEXT],
      oneOf: undefined,
    });

    setCurrentOpenField(`root${idSep}`);
  };

  const addSubField = () => {
    const newSchema = { ...schemaContext };
    newSchema.properties = newSchema.properties || {};

    const now = new Date(Date.now());
    const currentDate = `${now.getDate()}-${now.getMonth()}-${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
    schemaUpdateContext.updateSchema({
      key: `new @ ${currentDate}`,
      id: `root${idSep}`,
      type: "object",
      properties: {},
      oneOf: undefined,
      createSubGroupAction: true,
    });
  };

  useEffect(() => {
    if (!readable) {
      setCurrentOpenField("none");
    }
  }, []);

  return (
    <div className={jsonFormStyles.DynamicForm}>
      <div className={jsonFormStyles.Header}>
        {currentOpenField === "" ? (
          <Button
            shape="round"
            type="primary"
            ghost
            onClick={() => {
              setCurrentOpenField("none");
            }}
          >
            Edit
          </Button>
        ) : editable ? (
          <div className={jsonFormStyles.MainButtons}>
            <FormButton  onClick={addField}>
              <>
                + Field
              </>
            </FormButton>
            <FormButton onClick={addSubField}>
              <>
          + Group
              </>
            </FormButton>
          </div>
        ) : null}
      </div>
      <JSONForm
        schema={schemaContext}
        className={[baseStyles.BlingsDynamicForm, jsonFormStyles.JSONForm].join(
          " "
        )}
        formData={props.formData}
        ArrayFieldTemplate={ArrayFieldTemplate}
        ObjectFieldTemplate={ObjectFieldTemplate}
        ErrorList={ErrorList}
        onChange={props.onChange}
        onSubmit={props.onSubmit}
        idSeparator={idSep}
        FieldTemplate={FieldTemplate}
        widgets={{
          CheckboxWidget,
          SelectWidget,
          TextWidget,
          ColorWidget: TextWidget,
        }}
      >
        <span></span>
      </JSONForm>
    </div>
  );
};
