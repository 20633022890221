import { SessionsPerDayProps } from "../Analytics";
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";
import { theme } from "../../../theme";
import { TooltipLine } from "./Tooltips";

export const SessionsPerDay = ({ data }: { data?: SessionsPerDayProps }) => {
  if (!data) return null;
  const lineData = data.map((d) => ({
    x: new Date(d.key_as_string),
    y: d.doc_count,
  }));
  const totalViews = data.reduce((prev, curr) => prev + curr.doc_count, 0);
  return (
    <div className="analytics-view sessions" style={{ height: "100%" }}>
      <div className="view-title">
        <h2>Views</h2>
        <h2>
          Total <span>{totalViews}</span>
        </h2>
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <ResponsiveLine
          data={[
            {
              id: "Sessions per day",
              data: lineData,
            },
          ]}
          colors={["#FF154D69"]}
          pointColor={"#FF154D"}
          enableArea={true}
          curve={"monotoneX"}
          yScale={{ type: "linear", min: 0, max: "auto" }}
          axisBottom={{
            legend: "",
            tickValues: 3,
            format: "%Y-%m-%d",
          }}
          axisLeft={{
            legend: "",
            legendOffset: 12,
            format: (e) => Math.floor(e) === e && e,
          }}
          margin={{
            left: 40,
            right: 30,
            top: 30,
            bottom: 30,
          }}
          xScale={{
            type: "time",
            format: "%YYYY-%mm-%ddT%HH:%MM:%SS.%LLLZ",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          motionConfig="gentle"
          useMesh={true}
          defs={[
            linearGradientDef("gradientA", [
              { offset: 0, color: "#FF154D" },
              { offset: 100, color: "#FF154D", opacity: 0.4 },
            ]),
          ]}
          fill={[{ match: "*", id: "gradientA" }]}
          theme={{
            background: "#FAF8F8",
            axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
            textColor: theme.blings_teal,
            grid: { line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 } },
          }}
          tooltip={TooltipLine}
        />
      </div>
    </div>
  );
};
