import React, { useContext } from "react";
import { FieldTemplateProps } from "@rjsf/core";
import { Button, Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import {
  CurrentOpenFieldContext,
  EditableFormContext,
} from "../DynamicFormContext";

import { EditIcon, iconFromInternalType } from "../Icons";
import { internalSchemaTypeFromSchema } from "../helpers";
import { FieldState, FieldStateContext } from "../FieldTemplate";

import styles from "./BaseField.module.scss";

interface BaseFieldProps extends FieldTemplateProps {
  edit: boolean;
}
export const BaseField = (props: BaseFieldProps) => {
  const {
    schema,
    description,
    children,
    id,
    label,
    edit: formOnEditState,
  } = props;
  const [, setCurrentOpenField] = useContext(CurrentOpenFieldContext);
  const { editable } = useContext(EditableFormContext);

  return schema.type === "object" || schema.type === "array" || schema.anyOf ? (
    children
  ) : (
    <div className={styles.BaseField}>
      <div className={styles.Left}>
        <div className={styles.Main}>
        <Space direction="vertical">
          <div className={styles.Label}>
            
            <div className={styles.TypeIcon}>
              {iconFromInternalType(internalSchemaTypeFromSchema(schema), 2)}
            </div>
            <span
              style={{
                whiteSpace: "nowrap",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {label}{" "}
              {description.props.description ? (
                <div className={styles.Description}>
                  <Tooltip
                    title={description.props.description}
                    placement="topLeft"
                  >
                    <span>
                      <InfoCircleOutlined style={{ margin: "0 0.25rem" }} />
                    </span>
                  </Tooltip>
                </div>
              ) : null}
              :
            </span>
            
          </div>

          <FieldStateContext.Provider
            value={formOnEditState ? FieldState.EDIT : FieldState.READ_ONLY}
          >
            {children}
          </FieldStateContext.Provider>
          </Space>
        </div>
      </div>
      {editable && formOnEditState && props.schema.type !== "object" && (
        <div className={styles.Right}>
          <Button
            className={styles.IconBtn}
            onClick={() => {
              setCurrentOpenField(id);
            }}
          >
            <EditIcon size={2} />
          </Button>
        </div>
      )}
    </div>
  );
};
