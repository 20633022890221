import * as React from "react";
import { DynamicForm } from "@blings/dynamic-form";
import { observer } from "mobx-react-lite";
import { JSONSchema7 } from "json-schema";
import { IProjectModel } from "../../stores/project/projectModel";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { MSTContext } from "../../stores/Root";
import { AsyncOpState } from "../../types/enums/async-op-states";

const { useState, useEffect, useContext } = React;

type Props = {
  // fileName: string;
  // fileUploads: IFileUploadsModel;
  // saveDataSource: (filename: string | null) => void;
  project: IProjectModel;
  schema: JSONSchema7;
  newDPFormData?: any;
};

const _CreateDataPointForm = ({ project, schema, newDPFormData }: Props) => {
  const [, setLastError] = useState<any>(undefined);
  // Store the data point ID
  const [formDataId, setFormDataId] = useState(newDPFormData.data_id);

  useEffect(() => {
    // Update the data point ID always that a already existing data point is selected
    setFormDataId(newDPFormData.data_id);
  }, [newDPFormData]);

  const submit = React.useRef<() => void>(() => {
    return;
  });
  const {
    liveControlStore: { getAssetsUploadStatus, uploadAssetToProject },
  } = useContext(MSTContext);

  return (
    <>
      <DynamicForm
        schema={schema}
        formData={newDPFormData}
        editable={false}
        readable={false}
        saveData={async (formData) => {
          console.log(formData);
          try {
            await project.createDataPoint(formData);
          } catch (e: any) {
            setLastError(e.message);
            console.error("err1", e);
          }
          // project.updateNewDPFormData({})
        }}
        submit={submit}
        getAssetsUploadStatus={getAssetsUploadStatus}
        uploadAssetToProject={uploadAssetToProject}
        // When the user changes a field in the form data, updates the form ID
        // There's no need to worry with re-draw of components if the value still the same
        onChange={(formData) => {
          setFormDataId(formData.data_id);
        }}
      />
      <BlingsBtn
        opState={project.createNewDPStatus}
        htmlType={"submit"}
        btnTexts={{
          [AsyncOpState.Error]: "Error Creating Data",
          [AsyncOpState.Saving]: "Creating",
          [AsyncOpState.Untouched]: "Create",
          [AsyncOpState.Changed]:
            // Update the message on the button based on the data id
            project.createdDPList.find((projectDataPoint) => {
              return projectDataPoint.dataId === formDataId;
            })
              ? "Update"
              : "Create",
          [AsyncOpState.Success]: "DONE",
        }}
        onClick={() => submit.current()}
      />
    </>
  );
};

export const CreateDataPointForm = observer(_CreateDataPointForm);