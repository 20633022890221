import { PlayerSettingsUI } from "../PlayerSettings/PlayerSettings";
import { UpdateProject } from "./UpdateProject";
import { UpdateProjectSchemas } from "./ProjectSchemas";
import { IProjectModel } from "../../stores/project/projectModel";
import ProjectDataSettings from "./ProjectDataSettings";
import { Divider, Space, Tabs } from "antd";
import {
  BlockOutlined,
  PlaySquareOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import "./ProjectSettings.scss";

type Props = {
  project: IProjectModel;
};
export const ProjectSettings = ({ project }: Props) => {
  const { TabPane } = Tabs;
  return (
    <Space
      className="ProjectSettings project-tab-padding"
      direction="vertical"
      style={{ width: "100%" }}
    >
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <ProjectOutlined />
              Project
            </span>
          }
          key="1"
        >
          <Divider />
          <Space direction="vertical" size="large">
            <UpdateProject project={project} />
            {project.allowDataConnect && <ProjectDataSettings />}
          </Space>
        </TabPane>
        <TabPane
          tab={
            <span>
              <PlaySquareOutlined />
              Player
            </span>
          }
          key="2"
        >
          <Divider />
          <PlayerSettingsUI project={project} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <BlockOutlined />
              Schema
            </span>
          }
          key="3"
        >
          <Divider />
          <UpdateProjectSchemas project={project} />
        </TabPane>
      </Tabs>
    </Space>
  );
};
