import React from "react";
import { SchemaProvider } from "./DynamicFormContext";
import { IDynamicFormProps } from "./DynamicForm.api";
import { JSONSchema7 } from "json-schema";
import { EMPTY_SCHEMA } from "./components/consts";
// import "./Fonts/fonts.scss";
export const DynamicForm = ({
  schema,
  formData,
  editable,
  saveData,
  submit,
  setCurrentOpenField,
  uploadAssetToProject,
  getAssetsUploadStatus,
  onChange,
  readable = true,
  fieldDescription,
}: IDynamicFormProps) => {
  formData = parseData(schema, formData);
  return (
    <SchemaProvider
      schema={schema?schema:EMPTY_SCHEMA}
      formData={formData}
      saveData={saveData}
      editable={editable}
      submit={submit}
      setCurrentOpenField={setCurrentOpenField}
      uploadAssetToProject={uploadAssetToProject}
      getAssetsUploadStatus={getAssetsUploadStatus}
      onChange={onChange}
      readable={readable}
      fieldDescription={fieldDescription}
    />
  );
};

/**
 * Check if the data from the fields is valid according to the schema.
 * If it's not valid, return the error message and parse them to the correct type.
 * @param schema The schema of object types that the formData is based on
 * @param formData The data for this dynamic form
 * @returns A parsed formData that is based on the schema fields.
 * Right now just parses the number fields to be really numbers.
 */
function parseData(schema, formData: IDynamicFormProps) {
  if (formData && schema && schema.type === "object") {
    if (schema.properties) {
      for (const key in schema.properties) {
        if (schema.properties[key].type === "number") {
          // Check if formData exists
          if (formData[key]) {
            // Check if formData is not a number
            if (typeof formData[key] !== "number") {
              // Replace all ',' with '.'. This way if the number is a float, it will be parsed correctly
              formData[key] = formData[key].replace(/,/g, ".");
              formData[key] = parseFloat(formData[key]);
            }
          }
          if (isNaN(formData[key])) {
            console.error(
              `${key} is not a number and cannot be converted to one. Setting default value to 0`
            );
            formData[key] = 0;
          }
          // console.log("parseData", formData[key]);
        }
      }
  }
}
  return formData;
}
