import { getRoot, Instance, types } from "mobx-state-tree";
import {
  IProjectModel,
  IVidPartModel,
  mainAssetKey,
  ProjectModel,
  sequenceLayerId,
} from "./projectModel";
import _ from "lodash";

import {
  CreateProjectInput,
  Project,
  UpdateProjectInput,
  UpdateProjectVersionInput,
  VidPartInput,
} from "../../API";
import { asyncOpStateType } from "../../types/mst/async-op-state";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { IRootModel } from "../Root";
import {
  createPublished,
  createVersion,
  deleteProjectVersionAndPublished,
  getLatestProjectVersion,
  getPublishedProject,
  listProjectBasicInfo,
  savePublished,
} from "../../utils/projectTools";
import { getJsonDataFromUrl } from "./jsonDataMemo";
import { getAssetLayerPairs } from "./getAssetLayerPairs";
import { projectTitleToAlias } from "../../utils/integration";
export type ProjectBasicInfo = {
  title: string;
  id: string;
  updatedAt: string;
  description: string;
  thumbS3Url: string;
  createdAt: string;
  projectAccountId: string;
};

export interface SimpleCreateProjectInput
  extends Pick<
    CreateProjectInput,
    | "title"
    | "description"
    | "settingsJsonSchemaStr"
    | "stateJsonSchemaStr"
    | "thumbS3Url"
  > {
  dataExample: any;
}

export const ProjectsModel = types
  .model("ProjectsStore", {
    projectsIsLoading: true, // Loading of all projects
    projectIdCurrentlyLoading: types.maybeNull(types.string), // Loading of full data for 1 project,
    projectLoadingStatus: asyncOpStateType,
    projects: types.optional(types.array(types.frozen<ProjectBasicInfo>()), []),
    selectedProject: types.maybe(ProjectModel),
    updateOrCreateProjectStatus: asyncOpStateType,
    projectsSubMenuIsOpen: false,
    // selectedProject: types.maybe(ProjectModel)
  })
  .views((self) => ({
    get selectedProjectId() {
      return self.selectedProject?.id;
    },
  }))
  .actions((self) => ({
    changeUpdateOrCreateProjectStatus(newStatus: AsyncOpState) {
      self.updateOrCreateProjectStatus = newStatus;
    },
  }))
  .actions((self) => ({
    setIsLoading(isLoading: boolean) {
      self.projectsIsLoading = isLoading;
    },
    setLoadingStatus(status: AsyncOpState) {
      self.projectLoadingStatus = status;
    },
    setProjectIdCurrentlyLoading(id: string | null) {
      self.projectIdCurrentlyLoading = id;
    },
    setProjects(projects: ProjectBasicInfo[]) {
      self.projects.replace(projects);
    },
    setSelectedProject(projectModel: IProjectModel | undefined) {
      self.selectedProject = projectModel;
    },
  }))
  .actions((self) => {
    async function createProjectModel(
      publishedVersion: Project,
      draftVersion: UpdateProjectVersionInput
    ) {
      const newProjectModel = {};

      // If the project model has a key which is the same in the published project, update the model
      Object.keys(ProjectModel.properties).forEach((key) => {
        if (_.has(publishedVersion, key)) {
          _.assign(newProjectModel, { [key]: _.get(publishedVersion, key) });
        }
      });

      // Set videoPartNames to the model from published
      const videoPartNames =
        publishedVersion?.videoParts?.map((vdp) => vdp?.name || "") || [];

      // Set workspaceVideoparts to the model from draft
      const draftVideoparts = draftVersion?.videoParts || [];
      const workspaceVideoParts = draftVideoparts?.map((vp) => {
        const modsArray =
          vp.modsArr?.map((mod) => ({
            dataStr: mod.dataStr,
            id: mod.id,
            origin: mod.origin,
          })) || null;
        const vpWithData: VidPartInput = {
          ...vp,
          modsArr: modsArray,
        };
        return vpWithData;
      });
      if (workspaceVideoParts) {
        for (const vp of workspaceVideoParts as IVidPartModel[]) {
          if (!vp.jsonUrl) {
            continue;
          }
          const jsonData = await getJsonDataFromUrl(vp.jsonUrl);
          vp.jsonData = jsonData;
          vp.initialModsArr = vp.modsArr;
          // Update Asset layer pairs
          const [assetLayerPairs, mainPairs] = getAssetLayerPairs(jsonData);
          assetLayerPairs[mainAssetKey] = mainPairs;
          Object.keys(assetLayerPairs).forEach((key) => {
            if (key.indexOf(sequenceLayerId) === 0) {
              delete assetLayerPairs[key];
            }
          });
          vp.assetLayerPairs = assetLayerPairs;
        }
      }

      // Set workspaceUpdatedAt to the model
      const workSpaceUpdatedAt = draftVersion?.updatedAt || "";
      if (workspaceVideoParts) {
        const Project = {
          ...newProjectModel,
          id: publishedVersion.id,
          settings: publishedVersion.settings
            ? JSON.parse(publishedVersion.settings)
            : {},
          projectAccountId: publishedVersion.projectAccountId,
          videoPartNames,
          workSpaceUpdatedAt,
          createdAt: publishedVersion.createdAt || "",
          title: publishedVersion.title,
          workspaceVideoParts: workspaceVideoParts || [],
          aliasId: publishedVersion.aliasId,
          minisiteConfig: publishedVersion.minisiteConfigs?.length
            ? publishedVersion.minisiteConfigs[0]
            : {},
        };
        return ProjectModel.create(Project);
      }
    }
    return {
      async loadProject(id: string) {
        if (self.selectedProject?.id === id) {
          return;
        }
        self.setLoadingStatus(AsyncOpState.Saving);
        self.setProjectIdCurrentlyLoading(id);
        console.log("Loading", id);
        try {
          // Get the latest published and draft versions
          const publishedVersion = await getPublishedProject(id);
          let draftVersion = await getLatestProjectVersion(id);
          if (!draftVersion) {
            if (!publishedVersion) throw new Error("Invalid Project");
            // If there is no draft version, create one from the published version
            draftVersion = await createVersion(publishedVersion);
          }

          // Create the model
          const selectedProjectModel = await createProjectModel(
            publishedVersion,
            draftVersion
          );
          if (selectedProjectModel) {
            self.setSelectedProject(selectedProjectModel);
            self.setLoadingStatus(AsyncOpState.Success);
            self.setProjectIdCurrentlyLoading(null);
          } else {
            self.setLoadingStatus(AsyncOpState.Error);
          }
        } catch (e) {
          self.setLoadingStatus(AsyncOpState.Error);
          console.error(e);
        }
      },
      loadProjectsSuccess(projects: ProjectBasicInfo[]) {
        self.projects.replace(
          projects.sort((a, b) => {
            if (!a.createdAt) {
              return 1;
            }
            if (!b.createdAt) {
              return -1;
            }
            if (b.createdAt && b.createdAt) {
              return a.createdAt > b.createdAt ? -1 : 1;
            }
            return 0;
          })
        );
        self.setIsLoading(false);
      },
    };
  })
  .actions((self) => ({
    async loadProjects() {
      // Get basic info from all projects (published)
      const projects: ProjectBasicInfo[] = await listProjectBasicInfo();

      if (projects.length) {
        self.setProjects(projects.filter((p) => !!p.id));
      }
      self.loadProjectsSuccess(projects);
    },
    async setSelectedProjectById(id?: string) {
      // Only load project if Id is valid
      // If there is a project loading, continue only if the new id is not equal to the one currently selected
      if (id) {
        if (self.projectLoadingStatus === AsyncOpState.Error) {
          if (self.projectIdCurrentlyLoading !== id) {
            //If the id with the error is different than the one we want to load, load it
            await self.loadProject(id);
          }
        } else if (
          !self.projectIdCurrentlyLoading ||
          self.projectIdCurrentlyLoading !== id
        ) {
          await self.loadProject(id);
        }
      }
    },
    async refreshProject(id?: string) {
      // Only load project if Id is valid
      // If there is a project loading, continue only if the new id is not equal to the one currently selected
      if (id) {
        if (
          !self.projectIdCurrentlyLoading ||
          self.projectIdCurrentlyLoading !== id
        ) {
          self.setSelectedProject(undefined); // Clear the project
          await self.loadProject(id);
        }
      }
    },
    setProjectsSubMenuIsOpen(isOpen: boolean) {
      self.projectsSubMenuIsOpen = isOpen;
    },
    async createProjectNotAdmin(cpiSimple: SimpleCreateProjectInput) {
      const groupId = getRoot<IRootModel>(self).accountStore.cognitoGroupId;
      if (!groupId) {
        throw new Error("missing group ID");
      }

      const {
        description,
        title,
        settingsJsonSchemaStr,
        stateJsonSchemaStr,
        thumbS3Url,
      } = cpiSimple;

      const aliasId = projectTitleToAlias(title);
      const cpi: CreateProjectInput = {
        projectAccountId: groupId,
        description,
        title,
        settingsJsonSchemaStr,
        stateJsonSchemaStr,
        allowCreateDataPoint: true,
        analyticsEnabled: true,
        allowDataConnect: true,
        thumbS3Url,
        aliasId,
      };

      return await this.createProject(cpi);
    },
    async createProject(cpi: CreateProjectInput) {
      self.changeUpdateOrCreateProjectStatus(AsyncOpState.Saving);
      let newProjectInfo: Project | null = null;
      try {
        newProjectInfo = await createPublished(cpi);
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Success);
      } catch (e) {
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Error);
        console.error("err", e);
      }
      setTimeout(() => {
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Changed);
      }, 2000);
      this.loadProjects();
      return newProjectInfo;
    },
    async saveProject(upi: UpdateProjectInput) {
      try {
        // @ts-ignore
        delete upi.videoPartsStr;
      } catch (e) {
        console.error("err", e);
      }
      self.changeUpdateOrCreateProjectStatus(AsyncOpState.Saving);

      try {
        await savePublished(upi);
        this.loadProjects();
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Success);
      } catch (e) {
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Error);
        console.error("err", e);
      }
      setTimeout(() => {
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Changed);
      }, 2000);
    },
    async deleteProject(id: string) {
      // admin only!
      try {
        await deleteProjectVersionAndPublished(id);
        this.loadProjects();
      } catch (e) {
        console.error("err delete Project", e);
      }
    },
  }))
  .actions(() => ({
    afterCreate() {
      // self.loadProjects()
    },
  }));

export type IProjectsModel = Instance<typeof ProjectsModel>;
