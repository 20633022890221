import { Button, Card, Spin, Typography } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { rootStore, useMst } from "../../stores/Root";

const { Title, Text, Paragraph } = Typography;

type Props = {
  loading: boolean;
  name: string;
  id: string;
  phone: string;
  email: string;
};

const AccountSettingsWrapper = observer(() => {
  const state = useMst((store) => {
    if (
      !store.accountStore.accountSettings ||
      !store.accountStore.userAttributes
    ) {
      return { loading: true };
    }

    return {
      loading: false,
      email: store.accountStore.userAttributes.email,
      name: store.accountStore.accountSettings.name,
      id: store.accountStore.accountSettings.id,
      phone: store.accountStore.userAttributes.phone_number,
      hubspotConnection: store.accountStore.hubspotConnection,
    };
  });

  return <AccountSettings {...state} />;
});

function AccountSettings({ name, loading, phone, email }: Props) {
  const history = useNavigate();
  return (
    <div className="page-layout-padding">
      <div className="settings">
        <Card
          title={email}
          extra={
            <Button
              type="primary"
              onClick={() => rootStore.accountStore.signOut(history)}
            >
              log out
            </Button>
          }
        >
          {loading ? (
            <div className="loading">
              <Spin />
            </div>
          ) : (
            <>
              <Paragraph>
                <Title level={4}>Account</Title>
                <Text> {name}</Text>
              </Paragraph>

              <Paragraph>
                <Title level={4}>Phone</Title>
                <Text> {phone ? phone : "not set"}</Text>
              </Paragraph>
            </>
          )}
        </Card>
      </div>
    </div>
  );
}

export default AccountSettingsWrapper;
