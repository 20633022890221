import React from "react";
import { Card, Upload, message } from "antd";
import { UploadProps } from "antd/lib/upload";
import { InboxOutlined } from "@ant-design/icons";
import { IuploadDataModel } from "../../stores/uploadData";
import { v4 as uuidv4 } from "uuid";
import { API, Auth, Storage, PubSub } from "aws-amplify";
import mixpanel from "mixpanel-browser";

const { Dragger } = Upload;

export interface UploadProgressEvent {
  percent: number;
}

async function uploadFile(
  file: any,
  uploadData: IuploadDataModel,
  onError?: (error: Error) => void,
  // onProgress?: (event: Pick<UploadProgressEvent, 'percent'>, file: File) => void,
  onProgress?: (p: any, file: any) => void,
  onSuccess?: (response: any, file: XMLHttpRequest) => void
) {
  console.log("onp,", onProgress);
  const original = file.name;
  const user = await Auth.currentAuthenticatedUser();
  const group = user.signInUserSession.accessToken.payload["cognito:groups"][0];

  const validationResult = (await validate(file)) as any;
  if (!validationResult.valid) {
    onError && onError(new Error(validationResult.error));
    mixpanel.track("File upload error", {
      error: validationResult.error,
      fileName: original,
      accountId: group,
    });
    return;
  }

  try {
    const fileName = uuidv4() + ".csv";

    console.log("upload", new Date());
    const response = (await Storage.put(fileName, file, {
      progressCallback(progress: { loaded: number; total: number; }) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        onProgress &&
          onProgress(
            { percent: (progress.loaded / progress.total) * 50 },
            file
          );
      },
    })) as any;
    console.log("process", new Date());

    const key = `public/${fileName}`;

    const apiName = "uploadCsvFile";
    const path = "/uploadcsv";
    const myInit = {
      crossDomain: true,
      body: { original, fileName, key, accountid: group },
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };
    const uploadPromise = API.post(apiName, path, myInit);

    const sub = PubSub.subscribe("updateProgress").subscribe({
      next: (data: any) => {
        const msg = data.value;
        if (msg.fileName !== fileName) return;
        const progress = Number(msg.progress);
        onProgress && onProgress({ percent: 50 + progress * 50 }, file);
        if (progress === 1) {
          onSuccess && onSuccess(response, file);
          file.status = "done";
          sub.unsubscribe();
        }
      },
      error: (error: any) => console.error(error),
      // close: () => console.log('Done'),
    });

    return uploadPromise;
  } catch (error: any) {
    console.error("error uploading file", error);
    onError && onError(error);
  }
}

/**
 * Validate if the passed file is a CSV file
 * @param file The file to validate if it is a CSV file
 */
function validate(file: any) {
  return new Promise((resolve, reject) => {
    // Check if the mime file type is CSV
    if (file.type !== "text/csv") {
      resolve({
        valid: false,
        error: "File type is not supported. Please upload a CSV file.",
      });
    }
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result;
      if (result) {
        try {
          // Check if the file is a CSV using RegEx
          // And if the first field is an id field and if it's not bigger than 100000 rows

          // Start getting the headers from the first line
          const lines = (result as string).split(`\n`);
          const header = lines[0];
          // The headers may have double quotes and commas inside them
          // So we need to split the headers using a RegEx
          const fields = header.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          // Check if the first field is an id field
          const idFieldExists =
            fields.filter((f) => f.trim() === "id" || f.trim() === `"id"`)
              .length === 1;
          if (!idFieldExists) {
            resolve({
              valid: false,
              error: "CSV file must include an id field.",
            });
            return;
          }
          // Check if it's not bigger than 100000 rows
          if (lines.length > 100000) {
            resolve({
              valid: false,
              error: "CSV file is too large. Please contact support.",
            });
          }

          // Make a custom RegEx based on the header fields to check if the file is a CSV
          const regex = new RegExp(`^(${fields.map((f) => `("[^"]*"|'[^']*'|[^,]*)`).join(",")})$`, "g");
          //console.log("regex", regex);
          // For each line after the first one check if it matches the RegEx
          for (let i = 1; i < lines.length; i++) {
            const line = lines[i];
            // Check if the line matches the RegEx
            if(line.match(regex) === null) {
              resolve({
                valid: false,
                error: `CSV file is not valid. Please check line ${i + 1}.`,
              });
              return;
            }
          }

          resolve({ valid: true });
        } catch (e) {
          resolve({ valid: false, error: "CSV file format is invalid." });
        }
      }
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

// function getBase64(file: any) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       let result = reader.result;
//       if (result) {
//         result = (result as string).split(",", 2)[1];
//         // result = result.replace(/^data:.+;base64,/, '');
//       }
//       resolve(result);
//     };
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// }

const UploadNewFile = ({
  onUpload,
  uploadData,
}: {
  onUpload: (fileName: string) => void;
  uploadData: IuploadDataModel;
}) => {
  const uploader: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".csv",
    showUploadList: true,
    onChange: (info) => {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);

      } else if (status === "error") {
        message.error(
          info.file.error.message || `${info.file.name} file upload failed.`
        );
      }
    },
    customRequest({
      // action,
      // data,
      file,
      // filename,
      // headers,
      onError,
      onProgress,
      onSuccess,
      // withCredentials,
    }) {
      uploadFile(file, uploadData, onError, onProgress, onSuccess).then(r => {
        if (r) {
          onUpload(r.data.filename);
        }
      });

    },
  };

  return (
    <Card>
      <Dragger {...uploader} multiple={true}>
        <p className="ant-upload-drag-icon">
          {/*<Icon type="inbox" />*/}
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag a CSV file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload</p>
        <p className="ant-upload-hint">* File must include an id field</p>
      </Dragger>
    </Card>
  );
};

export default UploadNewFile;
