import { useEffect, useRef, useState } from "react";
import { RestoreIcon, SpinnerIcon, UploadIcon } from "../../assets/Icons";
import "./BlingsInput.scss";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
//import Dragger from "antd/lib/upload/Dragger";
import { Tooltip, Upload } from "antd";
type Props = {
  label?: string;
  placeholder?: string;
  value: string | undefined;
  onChange: (value: string) => void;
  showRestoreButton?: boolean;
  defaultValue?: string;
  onRestoreClick?: () => void;

  initialValue?: string;
  //showUploadButton?: boolean;
  style?: React.CSSProperties;
  uploadAssetsToProject?: (
    file: File | UploadFile,
    s3Folder: string,
    cb?: (key: string) => void,
    progressCallback?: any
  ) => Promise<void>;
};
export const BlingsInput = (props: Props) => {
  const inputEl = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    // Auto resize textarea
    if (inputEl && inputEl.current) {
      const t = inputEl.current;
      t.style.height = "";
      t.style.height = t.scrollHeight + "px";
    }
  }, [props.value]);
  const {
    label,
    placeholder,
    value,
    onChange,
    showRestoreButton,
    defaultValue,
    onRestoreClick,
    uploadAssetsToProject,
    style,
    initialValue,
  } = props;

  return (
    <div className="BlingsInput" style={style}>
      <div className="left">
        {label ? <span className="label">{label}</span> : null}
        <textarea
          ref={inputEl}
          className="input"
          value={value}
          {...(initialValue && { defaultValue: initialValue })}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder={placeholder}
        />
      </div>
      <div className="right">
        {defaultValue ? (
          <span
            className={[
              "restore-button",
              !showRestoreButton ? "disabled" : "",
            ].join(" ")}
            onClick={() => {
              onChange && onChange(defaultValue);
              onRestoreClick && onRestoreClick();
            }}
          >
            <Tooltip
              title={showRestoreButton ? `Restore to: ${defaultValue}` : ""}
            >
              <div>
                <RestoreIcon />
              </div>
            </Tooltip>
          </span>
        ) : null}
        {uploadAssetsToProject ? (
          <UploadButton
            uploadAssetsToProject={uploadAssetsToProject}
            onChange={onChange}
          />
        ) : null}{" "}
        {/* Change for upload button component */}
      </div>
    </div>
  );
};

type PropsUpload = {
  uploadAssetsToProject: (
    file: File | UploadFile,
    s3Folder: string,
    cb?: (key: string) => void,
    progressCallback?: any
  ) => Promise<void>;
  onChange: Props["onChange"];
};
const UploadButton = (props: PropsUpload) => {
  const { uploadAssetsToProject, onChange } = props;
  const [isUploading, setIsUploading] = useState(false);
  return (
    <Upload
      className="upload-button"
      showUploadList={false}
      customRequest={({ file, onError, onSuccess }) => {
        const f = file as RcFile;
        const { type } = f;
        setIsUploading(true);
        uploadAssetsToProject(f, (type || "unknown").split("/")[0], (key) => {
          setIsUploading(false);
          onChange(key);
          onSuccess && onSuccess(file);
        }).catch((e) => {
          setIsUploading(false);
          onError && onError(e);
        });
      }}
    >
      {isUploading ? <SpinnerIcon /> : <UploadIcon />}
    </Upload>
  );
};
