import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";
import { WatchTimePerScene } from "../Analytics";
import { TooltipLineNoFormat } from "./Tooltips";
import { theme } from "../../../theme";
import { Select } from "antd";
import { useEffect, useState } from "react";
const FRAME_RATE = 25;
const TIME_FRAME = 5;
const FRAME_PRECISION = FRAME_RATE * TIME_FRAME;

export const WatchTime = ({ data }: { data?: Array<WatchTimePerScene> }) => {
  const watchTimes = data?.map((scene) => ({
    id: scene.key,
    data: fillMissingData(
      scene.perFrame.buckets.map((d) => ({
        x: parseInt(d.key) / FRAME_RATE,
        y: d.sum.value,
      }))
    ),
  }));
  useEffect(() => {
    if (!selectedScene) {
      setSelectedScene(watchTimes?.length ? watchTimes[0].id : selectedScene);
    }
  }, [watchTimes]);
  const [selectedScene, setSelectedScene] = useState(
    watchTimes?.length ? watchTimes[0].id : ""
  );
  if (!watchTimes) return null;
  return (
    <div style={{ height: "100%" }} className="watch-time analytics-view">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="view-title">
          <h2>Watch time</h2>
        </div>
        <Select
          onChange={(selected) => {
            setSelectedScene(selected);
          }}
          value={selectedScene}
          style={{ marginBottom: "1rem", width: "15rem" }}
        >
          {watchTimes.map((wt) => (
            <Select.Option value={wt.id} key={wt.id}>
              {wt.id}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <ResponsiveLine
          data={watchTimes.filter((wt) => wt.id === selectedScene)}
          pointColor={"#FF154D"}
          enableArea={true}
          curve={"monotoneX"}
          yScale={{ type: "linear", min: 0, max: "auto" }}
          axisBottom={{
            legend: "",
            tickValues: 3,
            tickRotation: -45,
            format: (d) => d + "s",
          }}
          xFormat={(d) => `${d.toString()}s`}
          axisLeft={{
            legend: "",
            legendOffset: 12,
            format: (e) => Math.floor(e) === e && e,
          }}
          margin={{
            left: 40,
            right: 30,
            top: 30,
            bottom: 30,
          }}
          motionConfig="gentle"
          useMesh={true}
          colors={["#FF154D69"]}
          defs={[
            linearGradientDef("gradientC", [
              { offset: 0, color: "#FF154D" },
              { offset: 100, color: "#FF154D", opacity: 0.4 },
            ]),
          ]}
          fill={[{ match: "*", id: "gradientC" }]}
          theme={{
            background: "#FAF8F8",
            axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
            textColor: theme.blings_teal,
            grid: { line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 } },
          }}
          tooltip={TooltipLineNoFormat}
        />
      </div>
    </div>
  );
};

function fillMissingData(watchTime: Array<{ x: number; y: number }>) {
  const existingTimes = watchTime.map((w) => w.x);
  const maxTime = Math.max(...existingTimes);
  const existingTimesSet = new Set(existingTimes);
  const missingTimes = [];
  for (let i = 0; i < maxTime; i += FRAME_PRECISION) {
    if (!existingTimesSet.has(i)) missingTimes.push({ x: i, y: 0 });
  }
  return [...watchTime, ...missingTimes].sort((a, b) => a.x - b.x);
}
