import React from "react";
import { ArrayFieldTemplateProps } from "@rjsf/core";
import { Button, Typography } from "antd";

import styles from "./ArrayFieldTemplate.module.scss";

export const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  return (
    <div className={styles.ArrayFieldTemplate}>
      {props.items.map((element, i) => (
        <span className={"array-item"} key={i}>
          {element.children}
        </span>
      ))}
      {props.canAdd && (
        <Button shape="round" type="primary" ghost onClick={props.onAddClick}>
          Add item
        </Button>
      )}
    </div>
  );
};
