// Return all the layers contained in the scene. Including layers embedded in assets which are scenes
export function getAssetLayerPairs(jsonFile: any) {
  // Goes through all the assets in the scene.
  // If an asset contains a scene (ie. some layers), then append an array [name, layerNames[]]
  const assetPairs = jsonFile.assets
    .map((a: any) =>
      a.layers ? [a.nm || a.id, a.layers.map((l: any) => l.nm)] : []
    )
    .filter((x: any) => x.length);
  // Aggregate the data in an Object where the key is the name of the asset/scene
  const assetLayerPairs: any = {};
  for (const p of assetPairs) {
    assetLayerPairs[p[0]] = p[1];
  }
  // An array of all the layers that the current scene contains directly
  const mainPairs = jsonFile.layers.map((l: any) => l.nm);
  return [assetLayerPairs, mainPairs];
}
