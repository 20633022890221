/**
 * This file will define the default interface to be used when subscribing to the notification store.
 * This subscription will be called whenever a notification that matches the interface fields is added to the store.
 * For the subscription to be called, the subscription must be added to the store and have a callback function that will receive the notification as input.
 */

import { types } from 'mobx-state-tree';
import { NotificationType, NotificationSendType } from '../../types/Notification';


export const NotificationSubscriptionModel = types.model('NotificationSubscription', {
    id: types.string,
    /**
     * This subscription will be called whenever a notification that was send using the defined type is received.
     */
    sendType: types.enumeration(["Normal", "Instant", "All"]),

    /**
     * Will be called when a notification with the defined type is received.
     * The type maybe "All" or an array of types.
     * If the type is "All", the callback will be called for every notification received.
     */
    type: types.union(types.literal("All"), types.array(types.enumeration(["System", "Render", "Analitycs", "Extension", "Other"]))),
    //types.maybeNull(types.enumeration(["System", "Render", "Analitycs", "Other", "All"])),

    /**
     * Will be called when a notification with the defined subType is received.
     * If the subType is "All", the callback will be called for every notification received.
     */
    subType: types.optional(types.union(types.literal("All"), types.array(types.string), types.undefined), undefined),

    /**
     * If true, the callback will be called only once and then the subscription will be removed from the store.
     * If false, the callback will be called every time a notification that matches the subscription is received.
     */
    executeOnlyOnce: types.boolean,
}).volatile(self => ({
    // Set the callback as a function that does nothing. Gambiarra pura
    /**
     * The callback function that will be called when a notification that matches the subscription is received.
     * @param notification The notification received from the server
     * @returns Should not return anything because the notification store will not handle the returned value
     */
    callback: null as any
})).actions(self => ({
    /**
     * Since the MobX State Tree does now allow to create callbacks in the model nor in the volatile fields,
     * We need to create a function that will set the callback.
     * @param callback The callback function that will be called when a notification that matches the subscription is received.
     */
    setCallback(callback: (notification: any) => void) {
        self.callback = callback;
    }
}));

export interface INotificationSubscription {

    /**
     * The identifier of the subscription.
     */
    id: string;

    /**
     * This subscription will be called whenever a notification that was send using the defined type is received.
     */
    sendType: NotificationSendType,

    /**
     * Will be called when a notification with the defined type is received.
     * If the type is "All", the callback will be called for every notification received.
     */
    type: [NotificationType] | "All";

    /**
     * Will be called when a notification with the defined subType is received.
     * If the subType is "All", the callback will be called for every notification received.
     */
    subType?: [string] | "All";

    /**
     * If true, the callback will be called only once and then the subscription will be removed from the store.
     * If false, the callback will be called every time a notification that matches the subscription is received.
     */
    executeOnlyOnce: boolean;

    /**
     * The callback function that will be called when a notification that matches the subscription is received.
     * @param notification The notification received from the server
     * @returns Should not return anything because the notification store will not handle the returned value
     */
    callback: (notification: any) => void;
}