import { Card } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { rootStore } from "../../stores/Root";
import "./SceneCard.scss";
const { Meta } = Card;

const SceneCard = observer(({
  scene,
  isSelected,
}: {
  scene: string;
  isSelected: boolean;
}) => {
  const [bg, setBg] = useState("white");
  const randomHex = () => {
    let hexCode1 = "";
    const hexValues1 = "0123456789abcdef";

    for (let i = 0; i < 6; i++) {
      hexCode1 += hexValues1.charAt(
        Math.floor(Math.random() * hexValues1.length)
      );
    }
    return hexCode1;
  }
  function generate() {
    const deg = Math.floor(Math.random() * 360);

    const gradient =
      "linear-gradient(" +
      deg +
      "deg, " +
      "#" +
      randomHex() +
      ", " +
      "#" +
      randomHex() +
      ")";
    if (rootStore.uiStore.isPsychedelic) setBg(gradient);
  }
  useEffect(() => {
    generate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootStore.uiStore.isPsychedelic]);
  return (
    <Card
      className={["Card", isSelected ? "CardSelected" : ""].join(" ")}
      style={{ background:bg }}
    >
      <Meta title={scene} />
    </Card>
  );
  });

export default SceneCard;
