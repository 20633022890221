import { API, graphqlOperation } from "aws-amplify";
import { cast } from "mobx-state-tree";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { IProjectBaseModel, MinisiteConfigModel } from "./projectModel";
import { updateProject } from "../../graphql/mutations";
import { PlayerIntegrationData } from "../../view/IntegrationPage/SiteIntegration/DataIntegration/LandingPageIntegration/LandingPageIntegration";

let clearStateTimeout: NodeJS.Timeout;
export const saveMiniSiteConfigActions = (self: IProjectBaseModel) => ({
  async saveMiniSiteConfig(config: PlayerIntegrationData) {
    clearTimeout(clearStateTimeout);
    this.changeSaveMiniSiteConfigStatus(AsyncOpState.Saving);
    const { title, description, thumbUrl, faviconUrl, alias, scenes } = config;
    try {
      await API.graphql(
        graphqlOperation(updateProject, {
          input: {
            id: self.id,
            minisiteConfigs: [
              {
                scenes,
                description,
                thumbUrl,
                faviconUrl,
                title,
              },
            ],
            aliasId: alias,
          },
        })
      );
      this.changeSaveMiniSiteConfigStatus(AsyncOpState.Changed);

      this.replaceMiniSiteConfig(config);
    } catch (e) {
      this.changeSaveMiniSiteConfigStatus(AsyncOpState.Error);
      clearStateTimeout = setTimeout(() => {
        this.changeSaveMiniSiteConfigStatus(AsyncOpState.Changed);
      }, 3000);
    }
  },
  replaceMiniSiteConfig(config: PlayerIntegrationData, aliasId?: string) {
    self.minisiteConfig = cast(config);
    if (aliasId) self.aliasId = aliasId;
  },
  changeSaveMiniSiteConfigStatus(newStatus: AsyncOpState) {
    self.saveMiniSiteConfigStatus = newStatus;
  },
});
