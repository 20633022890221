import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  createContext,
} from "react";
import {
  JSONSchema7,
  JSONSchema7Definition,
  JSONSchema7TypeName,
} from "json-schema";
import { AntdForm } from "./JSONFormAntd";
import {
  deleteFormDataValueFromSchemaPath,
  getFormDataValueFromSchemaPath,
  getSchemaFromSchemaPath,
  setFormDataValueFromSchemaPath,
} from "./helpers";
import { AsyncOpState } from "./stores/enums/async-op-states";
import { UploadFile } from "antd/lib/upload/interface";

export const SchemaContext = createContext<JSONSchema7>({});
export const SchemaUpdateContext = createContext<{
  updateSchema: (data: UpdateSchemaData) => void;
  deleteSchema: (id: string) => void;
  setFormDataValue: (id: string, value: any) => void;
}>({
  updateSchema: () => {
    return;
  },
  deleteSchema: () => {
    return;
  },
  setFormDataValue: () => {
    return;
  },
});
export const AssetUploadConnectionContext = createContext<{
  uploadAssetToProject: (
    file: File | UploadFile,
    s3Folder: string,
    cb?: (key: string) => void,
    pcb?: any
  ) => Promise<void>;
  getAssetsUploadStatus: (id: string) => AsyncOpState | undefined;
}>({
  uploadAssetToProject: async (
    //@ts-ignore
    file: File | UploadFile,
    //@ts-ignore
    s3Folder: string,
    //@ts-ignore
    cb?: (key: string) => void,
    pcb?: any
  ) => {
    return await Promise.resolve();
  },
  //@ts-ignore
  getAssetsUploadStatus: (id: string) => {
    return undefined;
  },
});
export const CurrentOpenFieldContext = createContext<
  [string, Dispatch<SetStateAction<string>>]
>([
  "",
  () => {
    return;
  },
]);
export const EditableFormContext = createContext<{
  editable: boolean;
  readable: boolean;
  fieldDescription?: { dataType?: string; key?: string; value?: string };
}>({ editable: false, readable: true });
export type UpdateSchemaData = {
  key: string;
  desc?: string;
  type: JSONSchema7TypeName | JSONSchema7TypeName[] | undefined;
  req?: boolean;
  id?: string;
  format?: string;
  def?: any;
  properties?:
    | {
        [k: string]: JSONSchema7Definition;
      }
    | undefined;
  fieldDescription?: { dataType: string; key: string; value: string };
  createSubGroupAction?: boolean;
  oneOf: any;
};

export const idSep = "==/==";

export function SchemaProvider({
  schema,
  formData,
  saveData,
  editable,
  readable,
  submit,
  setCurrentOpenField,
  uploadAssetToProject,
  getAssetsUploadStatus,
  onChange,
  fieldDescription,
}: {
  schema: JSONSchema7;
  formData: any;
  saveData: (data: HTMLElement, schema?: any) => any;
  editable: boolean;
  readable?: boolean;
  submit: React.MutableRefObject<() => void>;
  setCurrentOpenField?: React.Dispatch<React.SetStateAction<string>>;
  uploadAssetToProject: (
    file: File | UploadFile,
    s3Folder: string,
    cb?: (key: string) => void,
    pcb?: any
  ) => Promise<void>;
  getAssetsUploadStatus: (id: string) => AsyncOpState | undefined;
  onChange?: (formData: any, schema: JSONSchema7) => void;
  fieldDescription?: { dataType?: string; key?: string; value?: string };
}) {
  const [currentOpen, setCurrentOpen] = useState("");
  const [schemaState, setSchema] = useState(schema);
  const [formDataState, setFormDataState] = useState(formData);
  useEffect(() => {
    if (setCurrentOpenField) setCurrentOpenField(currentOpen);
  }, [currentOpen]);
  //const firstSchema = useRef(JSON.stringify(schema));
  //const firstFormData = useRef(JSON.stringify(formData));

  // useEffect(() => {
  //   if (Object.keys(formData).length && JSON.stringify(schema) === firstSchema.current) setFormDataState(formData);
  // }, [formData]);

  useEffect(() => {
    setFormDataState(formData);
  }, [formData]);

  function deleteSchema(id: string) {
    const path = id.split(idSep);
    const formDataCopy = JSON.parse(JSON.stringify(formDataState));
    deleteFormDataValueFromSchemaPath(formDataCopy, path);
    const schemaCopy = JSON.parse(JSON.stringify(schemaState));
    const { object, key } = getSchemaFromSchemaPath(schemaCopy, path);
    delete object.properties[key];
    setFormDataState(formDataCopy);
    setSchema(schemaCopy);
    cleanAndCallOnChange(formDataCopy, schemaCopy);
    setCurrentOpen("none");
  }

  function setFormDataValue(id: string, value: any) {
    const path = id.split(idSep);
    const formDataCopy = JSON.parse(JSON.stringify(formDataState));
    setFormDataValueFromSchemaPath(formDataCopy, path, value);
    setFormDataState(formDataCopy);
    cleanAndCallOnChange(formDataCopy, schemaState);
  }
  function updateSchema({
    key,
    desc: description,
    type,
    id,
    properties,
    def,
    format,
    oneOf,
    createSubGroupAction
  }: UpdateSchemaData) {
    if (!key) {
      if (!id) {
        //adding field
        if (currentOpen === `root${idSep}`) {
          // current open has no key
          return;
        }
      } else {
        if (currentOpen !== id) {
          // closing field with no key
          setCurrentOpen(id);
        }
      }
    }
    const newSchema = {
      [key]: {
        type,
        properties,
        description,
        format,
        default: def,
        oneOf: oneOf,
      },
    };
    const schemaCopy = JSON.parse(JSON.stringify(schemaState)) as JSONSchema7;
    let object = schemaCopy as any;
    const preProps: any = {};
    let postProps: any = {};
    const formDataCopy = { ...formDataState };

    if (id) {
      const path = id.split(idSep);
      const o = getSchemaFromSchemaPath(schemaCopy, path);

      if (key !== o.key) {
        let value = getFormDataValueFromSchemaPath(formDataCopy, path);
        if (createSubGroupAction) {
          value = {};
        }
        deleteFormDataValueFromSchemaPath(formDataCopy, path);
        path[path.length - 1] = key;
        setFormDataValueFromSchemaPath(formDataCopy, path, value);
      }
      object = o.object;
      let found = false;
      Object.keys(object.properties).forEach(key => {
        if (key === o.key) return (found = true);
        if (!found) preProps[key] = object.properties[key];
        else postProps[key] = object.properties[key];
        return;
      });
    } else {
      postProps = { ...object.properties };
    }
    object.properties = { ...preProps, ...newSchema, ...postProps };
    setFormDataState(formDataCopy);
    setSchema(schemaCopy);
    cleanAndCallOnChange(formDataCopy, schemaCopy);
    return true;
  }

  function cleanAndCallOnChange(formData: any, schema: JSONSchema7) {
    if (onChange) {
      const schemaCopy = JSON.parse(JSON.stringify(schema));
      const formDataCopy = JSON.parse(JSON.stringify(formData));
      if (schemaCopy.properties[""]) {
        delete schemaCopy.properties[""];
        delete formDataCopy[""];
      }
      onChange(formDataCopy, schemaCopy);
    }
  }
  function cleanAndCallSaveData(formData: any, schema: JSONSchema7) {
    const schemaCopy = JSON.parse(JSON.stringify(schema));
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    if (schemaCopy.properties[""]) {
      delete schemaCopy.properties[""];
      delete formDataCopy[""];
    }
    saveData(formDataCopy, schemaCopy);
    if (!readable) setCurrentOpen("none");
    else setCurrentOpen("");
  }
  const saving = useRef(false);

  useEffect(() => {
    if (saving.current === false) return;
    cleanAndCallSaveData(formDataState, schemaState);
    saving.current = false;
  }, [schemaState]);

  if (submit !== null)
    submit.current = () => {
      cleanAndCallSaveData(formDataState, schemaState);
    };
  return (
    <SchemaContext.Provider value={schemaState}>
      <SchemaUpdateContext.Provider
        value={{ updateSchema, deleteSchema, setFormDataValue }}
      >
        <CurrentOpenFieldContext.Provider value={[currentOpen, setCurrentOpen]}>
          <EditableFormContext.Provider
            value={{
              editable,
              readable: readable as boolean,
              fieldDescription,
            }}
          >
            <AssetUploadConnectionContext.Provider
              value={{ uploadAssetToProject, getAssetsUploadStatus }}
            >
              <AntdForm
                schema={schemaState}
                onChange={e => {
                  setFormDataState(e.formData);
                  cleanAndCallOnChange(e.formData, schemaState);
                }}
                formData={formDataState}
              />
            </AssetUploadConnectionContext.Provider>
          </EditableFormContext.Provider>
        </CurrentOpenFieldContext.Provider>
      </SchemaUpdateContext.Provider>
    </SchemaContext.Provider>
  );
}
