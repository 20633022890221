import { getRoot, types } from "mobx-state-tree";
import { Storage } from "aws-amplify";
import { asyncOpStateType } from "./async-op-states";
import { CDN, getENV } from "../config";
import { AsyncOpState } from "../types/enums/async-op-states";
import { IRootModel } from "./Root";
import { UploadFile } from "antd/lib/upload/interface";

export const liveControlModel = types
  .model({
    uploadAssetStatus: types.map(
      types.model({ id: types.identifier, status: asyncOpStateType })
    ),
  })
  .views((self) => ({
    getPlatformStorageProjectAssetsRoot(): string {
      return `projects/${
        getRoot<IRootModel>(self).projectsStore.selectedProjectId || ""
      }/assets`;
    },
  }))
  .actions((self) => ({
    updateUploadAssetStatus(id: string, status: AsyncOpState) {
      self.uploadAssetStatus.set(id, { id, status });
    },
    getAssetsUploadStatus(id: string) {
      return self.uploadAssetStatus.get(id)?.status;
    },
    /* updateAsyncStatus(self, "uploadAssetStatus"), */
  }))
  .actions((self) => ({
    getFullUrlOfAsset(assetOriginalName: string, folder: string) {
      return (
        self.getPlatformStorageProjectAssetsRoot() +
        `/${folder}/${Date.now() + "_" + assetOriginalName}`
      );
    },
    async uploadAssetToProject(
      file: File | UploadFile,
      s3Folder: string,
      cb?: (key: string) => void,
      progressCallback?: any
    ) {
      self.updateUploadAssetStatus(file.name, AsyncOpState.Saving);

      try {
        const res = await this.uploadFileToS3(
          this.getFullUrlOfAsset(file.name, s3Folder),
          file,
          file.type,
          progressCallback
        );
        if (cb) {
          cb(`${CDN[getENV()]}/${res.key}`);
        }

        self.updateUploadAssetStatus(file.name, AsyncOpState.Success);
      } catch (e) {
        self.updateUploadAssetStatus(file.name, AsyncOpState.Error);
        console.error("err", e);
      }

      setTimeout(() => {
        self.updateUploadAssetStatus(file.name, AsyncOpState.Changed);
      }, 2000);
    },
    uploadFileToS3(
      Key: string,
      file: string | File | UploadFile,
      type?: string,
      pcb?: any
    ) {
      return Storage.put(Key, file, {
        level: "public",
        contentType: type || "application/json",
        progressCallback(progress) {
          pcb && pcb(progress);
        },
      }) as Promise<{ key: string }>;
    },
  }));
