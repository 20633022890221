export enum PATHS {
  home = "",
  settings = "settings",
  project = "project",
  create_new_project = "create",
  data = "data",
  admin = "admin",
  oauthCallback = "oauth-callback",
}

export enum PROJECT_PATHS_HIDE_EDITVIDEO {
  demo = "demo",
  analytics = "analytics",
  customAnalytics = "analytics-custom",
  liveControl = "live-control",
  createDataPoint = "dp",
  settings = "settings",
  // editVideo = "edit-video", // Hide edit video
  integration = "integration",
  // social = "social",
  // DataAutomation = "data-automation",
  // Tutorials = "tutorials",
}
export enum PROJECT_PATHS {
  demo = "demo",
  analytics = "analytics",
  customAnalytics = "analytics-custom",
  liveControl = "live-control",
  createDataPoint = "dp",
  settings = "settings",
  editVideo = "edit-video",
  integration = "integration",
  // social = "social",
  // DataAutomation = "data-automation",
  // Tutorials = "tutorials",
}

export const toPath = (...paths: string[]) => {
  return [""].concat(...paths).join("/");
};
export const pathToPieces = (path: string): string[] => {
  return path.split("/");
};

export const camelCaseToRegular = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
};
