import {
  CreateProjectInput,
  Project,
  ProjectVersion,
 
  UpdateProjectInput,
 
  UpdateProjectVersionInput,
} from "../API";
import { API, graphqlOperation } from "aws-amplify";
import {
  createProject,
  createProjectVersion,
  deleteProject,
  deleteProjectVersion,
  updateProject,
  updateProjectVersion,
} from "../graphql/mutations";
import { insertIntoGraphQlString } from "modifygraphqlstring";
import { getProject, getProjectVersion } from "../graphql/queries";
import { ProjectBasicInfo } from "../stores/project/projectsModel";
type ProjectMinimalInfo = {
  title: string;
  id: string;
  updatedAt: string;
  createdAt: string;
};
export async function createVersion(project: Project | ProjectVersion) {
  const input = {
    id: project.id,
    accountOwner: isProjectVersion(project)
      ? project.accountOwner
      : project.account.id,
    settings: project.settings,
    settingsJsonSchemaStr: project.settingsJsonSchemaStr,
    stateJsonSchemaStr: project.stateJsonSchemaStr,
    videoParts: project.videoParts,
  };
  const {
    data: { createProjectVersion: projectVersion },
  } = (await API.graphql(
    graphqlOperation(createProjectVersion, {
      input,
    })
  )) as { data: { createProjectVersion: ProjectVersion } };
  return projectVersion;
}

export async function createPublished(cpi: CreateProjectInput) {
  const {
    data: { createProject: project },
  } = (await API.graphql(
    graphqlOperation(createProject, {
      input: cpi,
    })
  )) as { data: { createProject: Project } };
  return project;
}

export async function updateVersion(project: UpdateProjectVersionInput) {
  const input = {
    id: project.id,
    ...(project?.settings && { settings: project.settings }),
    ...(project?.settingsJsonSchemaStr && {
      settingsJsonSchemaStr: project.settingsJsonSchemaStr,
    }),
    ...(project?.stateJsonSchemaStr && {
      stateJsonSchemaStr: project.stateJsonSchemaStr,
    }),
    ...(project?.videoParts && { videoParts: project.videoParts }),
  };
  const modifiedUpdateProject = insertIntoGraphQlString(
    insertIntoGraphQlString(updateProjectVersion, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  );
  return (await API.graphql(
    graphqlOperation(modifiedUpdateProject, {
      input,
    })
  )) as { data: ProjectVersion };
}


export const savePublished = async (cpi: UpdateProjectInput) => {
  await API.graphql(graphqlOperation(updateProject, { input: cpi }));
};

export const deleteProjectVersionAndPublished = async (id: string) => {
  await API.graphql(graphqlOperation(deleteProject, { input: { id } }));
  await API.graphql(graphqlOperation(deleteProjectVersion, { input: { id } }));
};
export async function publishProject(projectVersion: UpdateProjectInput) {
  const {
    id,
    settings,
    settingsJsonSchemaStr,
    stateJsonSchemaStr,
    videoParts,
  } = projectVersion;
  const input = {
    id,
    settings,
    settingsJsonSchemaStr,
    stateJsonSchemaStr,
    videoParts,
  };

  const modifiedUpdateProject = insertIntoGraphQlString(
    insertIntoGraphQlString(updateProject, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  );

  return (await API.graphql(
    graphqlOperation(modifiedUpdateProject, { input })
  )) as {
    data: Project;
  };
}

export async function getPublishedProject(id: string) {
  const modifiedGetProject = insertIntoGraphQlString(getProject, {
    path: ["videoParts"],
    key: "modsArr",
    value: {
      id: true,
      dataStr: true,
      origin: true,
    },
  });

  const {
    data: { getProject: project },
  } = (await API.graphql(
    graphqlOperation(modifiedGetProject, {
      id,
    })
    )) as { data: { getProject: Project } };
  return project;
}

export async function getPublishedProjectBasicInfo(id: string) {
  const getProject = `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      title
      updatedAt
      createdAt
    }
  }
`;

  const {
    data: { getProject: project },
  } = (await API.graphql(
    graphqlOperation(getProject, {
      id,
    })
  )) as {
    data: { getProject: ProjectMinimalInfo };
  };
  return project;
}

export async function getLatestProjectVersion(id: string) {
  // TODO add logic for fetching latest version when we have multiple versions per project id
  const modifiedGetProjectVersion = insertIntoGraphQlString(getProjectVersion, {
    path: ["videoParts"],
    key: "modsArr",
    value: {
      id: true,
      dataStr: true, // TODO add origin
      origin: true,
    },
  });
  const {
    data: { getProjectVersion: projectVersion },
  } = (await API.graphql(
    graphqlOperation(modifiedGetProjectVersion, {
      id,
    })
    )) as { data: { getProjectVersion: UpdateProjectVersionInput  } };
  return projectVersion;
}

function isProjectVersion(
  project: Project | ProjectVersion
): project is ProjectVersion {
  return (project as ProjectVersion).accountOwner !== undefined;
}

export const listProjectBasicInfo = async () => {
  const listProjectBasicInfoQuery = `query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        updatedAt
        projectAccountId
        createdAt
        thumbS3Url
      }
      nextToken
    }
  }`;
  let nextToken = null;
  const projects: ProjectBasicInfo[] = [];
  do {
    const projectsData = (await API.graphql(
      graphqlOperation(listProjectBasicInfoQuery, { nextToken })
    )) as { data: any };
    nextToken = projectsData?.data?.listProjects?.nextToken;
    if (projectsData?.data?.listProjects?.items) {
      projects.push(...projectsData.data.listProjects.items);
    }
  } while (nextToken);
  return projects;
};
