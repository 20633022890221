import * as React from "react";
import { Card } from "antd";
import { observer } from "mobx-react-lite";

import { IProjectModel } from "../../stores/project/projectModel";
import { DATA_ID_SCHEMA_PROP } from "../../stores/project/createDataPointActions";
import { CreateDataPointForm } from "./CreateDataPointForm";
import { useEffect } from "react";
import { HoverText } from "../../components/HoverText";
import "./CreateDataPointPage.scss";

type Props = {
  // fileName: string;
  // fileUploads: IFileUploadsModel;
  // saveDataSource: (filename: string | null) => void;
  project: IProjectModel;
};

function CreateDataPointPage(props: Props) {
  const { project } = props;
  // const selectRef = useRef(null);
  // const [value, setValue] = useState(fileName || undefined);
  const schema = JSON.parse(project.stateJsonSchemaStr || "{}");
  if (schema.required) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    schema.required.push(DATA_ID_SCHEMA_PROP);
  } else {
    schema.required = [DATA_ID_SCHEMA_PROP];
  }
  const newDpProp = {
    [DATA_ID_SCHEMA_PROP]: {
      // $id: `#/properties/${DATA_ID_SCHEMA_PROP}`,
      // default: "",
      // description: "Unique ID",
      // examples: ["abc123"],
      title: "New Data-Point ID ",
      type: "string",
      minLength: 3,
      // required: true,
    },
  };
  schema.properties = { ...newDpProp, ...schema.properties };

  useEffect(() => {
    project.loadExistingDPs();
  }, [project]);

  if (!project.allowCreateDataPoint) {
    return <span />;
  }
  return (
    <div className="CreateDataPoint project-tab-padding">
      {project.createdDPList?.length ? (
        <Card
          title="Existing data-points"
          style={{ width: "60%", minWidth: "400px" }}
        >
          {project.createdDPList.map((dp) => (
            <HoverText
              onClick={() => {
                project.updateNewDPFormData({
                  ...dp.record,
                  [DATA_ID_SCHEMA_PROP]: dp.dataId,
                });
              }}
              key={dp.dataId}
              title={dp.dataId}
              full={JSON.stringify(dp.record)}
            />
          ))}
        </Card>
      ) : undefined}
      <Card
        size="small"
        title="Create New Data-Point"
        actions={[]}
        className={"create-new-data-point-form"}
        style={{ width: "60%", minWidth: "400px" }}
      >
        {/*todo: change to CsvTable from nex-render*/}
        <CreateDataPointForm
          project={project}
          schema={schema}
          newDPFormData={project.newDPFormData}
        />
      </Card>
    </div>
  );
}

// const UploadDataPointWrapper = observer(() =>
// {
//   const { id } = useParams();
//
//   const state = useMst((store) => {
//     const project = store.projectsStore.selectedProject;
//     console.log(123, project)
//     return {
//       fileName: project && project.fileName,
//       fileUploads: store.accountStore.fileUploads.reverse(),
//       saveDataSource: project && project.saveFileName,
//     };
//   });
//
//   return <CreateDataPoint {...state} />;
// });

export default observer(CreateDataPointPage);
