import { CrmIntegration } from "./DataIntegration/CrmIntegration";
import { CsvIntegration } from "./DataIntegration/CsvIntegration";
import { IProjectModel } from "../../../stores/project/projectModel";
import { IuploadDataModel } from "../../../stores/uploadData";
import { SdkIntegration } from "./SdkIntegration";
import { useState } from "react";
import { BlingsTabs } from "../../../components/BlingsTabs";
import "./SiteIntegration.scss";
import { Divider } from "antd";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
};
export const SiteIntegration = (props: Props) => {
  const { project, uploadData } = props;

  const { minisiteConfig: config, aliasId, title: projectTitle } = project;
  const [state, setState] = useState<{ alias: string, scenes: Array<string>, title: string, description: string, thumbnail: string, favicon: string; }>({ alias: aliasId, scenes: config?.scenes || [], title: config?.title || projectTitle, description: config?.description || "", thumbnail: config?.thumbUrl || "", favicon: config?.faviconUrl || "" });
  const [selectedTab, setSelectedTab] = useState(0);
  const buttons = [
    { title: "CRM Integration", subtitle: "Integrate data from your CRM account in this project. Suitable for up to 4 dynamic keys." },
    { title: "Spreadsheet (CSV)", subtitle: "Used for un-sensitive data. Mostly for you to get the feeling of Blings.io solution." },
    { title: "SDK", subtitle: "Use our SDK to have full control of your customization." },
  ];
  const content = [
    <CrmIntegration project={project} state={state} setState={setState} />,
    <CsvIntegration project={project} state={state} setState={setState} />,
    <SdkIntegration project={project} uploadData={uploadData} />,
  ];
  const [value, setValue] = useState<string>("");
  return <div className="SiteIntegration">
    <h1 className="page-title">Integration</h1>
    <span>You can connect your data in various ways. Choose your preferred way:</span>
    <div className="tab-buttons">
      {buttons.map((button, index) => {
        return <div onClick={() => setSelectedTab(index)} key={index} className={(selectedTab === index ? "selected-tab-button" : "") + " tab-button"}>
          <div className="title">{button.title}</div>
          <div className="subtitle">{button.subtitle}</div>
        </div>;
      })}
    </div>
    <Divider />
    <BlingsTabs content={content} currentTab={selectedTab} />
  </div>;
};
