/**
 * This file will manage the player versions that need to be instantiated for different videos
 * It will download the latest version inside that major version, instantiate it and save it in the playerVersions object for future use if needed
 */

import { getENV } from "../config";
import { Player } from "@blings/blings-player";

type PlayerVersion = {
    major: number;
    downloadedCode: string;
    instantiatedMiniSDKCreator: any;
}

declare global {
    interface Window { BlingsPlayer: any; }
}

export default class PlayerManager{
    private static _singleton: PlayerManager;
    private playerVersions: PlayerVersion[] = [];
    private currentPlayerVersion: PlayerVersion | undefined;
    private miniSDKSource = "https://cors.blings.io/" + (getENV() === "dev" ? "https://assets.blings.io/scripts/msdkDev" : "https://assets.blings.io/scripts/msdk");
    // TODO: Get the correct dev link
    private playerSource = "https://cors.blings.io/" + (getENV() === "dev" ? "https://assets.blings.io/sdk/blingsSDK_v" : "https://assets.blings.io/sdk/blingsSDK_v");

    constructor() {
        if (PlayerManager._singleton) {
            return PlayerManager._singleton;
        }
        PlayerManager._singleton = this;
        if(window["BlingsPlayer"]) delete window["BlingsPlayer"];
    }

    public static get(): PlayerManager {
        if (!PlayerManager._singleton)
            PlayerManager._singleton = new PlayerManager();
        return PlayerManager._singleton;
    }
    
    

    /**
     * Download the latest version of the miniSDK for a given major version
     * @param major The major version of the player that we want to download
     */
    private async downloadMiniSDK(major: number): Promise<string> {
        let url: string
        if (major === -1) url = this.miniSDKSource + ".js"
        else url = this.miniSDKSource + major + ".js"
        const response = await fetch(url);
        const code = await response.text();
        return code;
    }

    /**
     * Download a specific version of the player
     * @param version A specific version of the player that we want to download. The version should be in the format "major-minor-patch"
     * @returns Returns the code of the player
     */
    private async downloadPlayer(version: string): Promise<string> {
        const response = await fetch(this.playerSource + version + ".js");
        const code = await response.text();
        return code;
    }

    /**
     * Instantiate the minisdk and return the BlingsPlayer object that we can use to create a player
     * @param miniSDKCode The code of the minisdk that we want to instantiate
     * @returns Returns the BlingsPlayer object that we can use to create a player
     */
    private async instantiateMiniSDK(miniSDKCode: string): Promise<any> {
        try {
            new Function(miniSDKCode + "return BlingsPlayer;")();
            // Await until the BlingsPlayer window object is created and while the BlingsPlayer dont have the create function
            while (!window["BlingsPlayer"]) await new Promise(resolve => setTimeout(resolve, 10));
            while (!window["BlingsPlayer"].version) await new Promise(resolve => setTimeout(resolve, 10));
            return window["BlingsPlayer"];
        }catch(e){
            console.log("ERROR", e);
        }
    }

    private async instantiatePlayer(playerCode: string): Promise<any> {
        try {
            new Function(playerCode + "return BlingsPlayer;")();
            // Await until the BlingsPlayer window object is created and while the BlingsPlayer dont have the create function
            while (!window["BlingsPlayer"]) await new Promise(resolve => setTimeout(resolve, 10));
            while (!window["BlingsPlayer"].create) await new Promise(resolve => setTimeout(resolve, 10));
            return window["BlingsPlayer"];
        }catch(e){
            console.log("ERROR", e);
        }
    }

    /**
     * Create a player for a given major version and return it
     * @param playerParams The parameters that will be used to create the player 
     * @param playerMajorVersion The major version of the player that we want to instantiate. We will download the latest version of that major version.
     * If the version is not specified, we will download the latest version of the player
     */
    public async createPlayer(playerParams: any, playerMajorVersion = -1): Promise<Player> {
        // Check if the player version is already downloaded
        const playerVersionIndex = this.playerVersions.findIndex((downloadedVersions) => downloadedVersions.major === playerMajorVersion);
        
        // If the player version is already downloaded, we will instantiate it
        if (playerVersionIndex !== -1) {
            
            // If the player version we want to instantiate is the same as the current player version, we utilize the already instantiated player creator
            if (window["BlingsPlayer"] && window["BlingsPlayer"].version.toString().split(".")[0] === this.currentPlayerVersion?.major.toString()) {
                return this.currentPlayerVersion?.instantiatedMiniSDKCreator.create(playerParams);
            }

            // If the player version we want to instantiate is different from the current player version, we will instantiate a new player creator
            // First we will delete the current player creator from the window object
            if (window["BlingsPlayer"]) delete window["BlingsPlayer"];
            

            // The player version is already downloaded, so we will instantiate a player using the already instantiated player creator
            const playerCreator = this.playerVersions[playerVersionIndex].instantiatedMiniSDKCreator;

            // Instantiate the player using the player creator
            return playerCreator.create(playerParams);
        }
        else {
            if (window["BlingsPlayer"]) delete window["BlingsPlayer"];

            // Normal pathway
            //if (playerMajorVersion !== 3) {
                console.log("Creating player version", playerMajorVersion);
                // The player version is not downloaded, so we will download it and instantiate it
                const miniSDKCode = await this.downloadMiniSDK(playerMajorVersion);
                const miniSDKCreator = await this.instantiateMiniSDK(miniSDKCode);

                // We create a new player version object and add it to the player versions array
                const newPlayerVersion: PlayerVersion = {
                    major: playerMajorVersion,
                    downloadedCode: miniSDKCode,
                    instantiatedMiniSDKCreator: miniSDKCreator,
                }
                this.playerVersions.push(newPlayerVersion);

                // And then we instantiate the player using the player creator
            const player = await newPlayerVersion.instantiatedMiniSDKCreator.create(playerParams);
            
            // WORKAROUND FOR PLAYER VERSION 3
            // If we are creating a player version 3, we have to make a workaround because the timeline is not shown correctly
            if (playerMajorVersion === 3) {
                console.log("Creating player version 3");
                // Get the timeline element
                const timelineElement = player.uiElements.ctrlContainer;

                // On this element, there is a rule ".__Blings__player__container .__blings__ctrl-container" that has the opacity set to 0.00001
                // We need to disable this rule so that the timeline is shown correctly
                const styleSheet = document.styleSheets[0];
                
                // Check if the rule is already disabled
                if (styleSheet.cssRules[0].cssText.indexOf("opacity: 1 !important") === -1){
                    // If the rule is not disabled, we disable it
                    styleSheet.insertRule(".__Blings__player__container .__blings__ctrl-container { opacity: 1 !important; }", 0);
                }
            }

            return player;
        }
    }
}