/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateProjectSub = /* GraphQL */ `
  subscription OnUpdateProjectSub($id: ID) {
    onUpdateProjectSub(id: $id) {
      id
      projectAccountId
      title
      account {
        id
        gid
        name
        aliasId
        createdAt
        updatedAt
      }
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      allowCreateDataPoint
      allowDataConnect
      analyticsReportUrl
      createdAt
      dataFiles {
        fileName
        tag
        createdAt
      }
      description
      fileName
      jsonChanges
      jsonVidUrl
      playerSettings {
        posterFrame
        showTimeline
        storiesMode
        color_loader
        color_ctrlBtns
        color_rail
        color_progress
        color_thumb
        color_bg
        muted
        autoplay
        autoReplay
        showBlingsLogo
      }
      projectVersions {
        nextToken
      }
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      analyticsEnabled
      owner
      renders {
        nextToken
      }
      notifications {
        nextToken
      }
      aliasId
      minisiteConfigs {
        scenes
        title
        description
        thumbUrl
        faviconUrl
      }
      playerVersionToUse
      customHtml
      updatedAt
    }
  }
`;
export const onUpdateProjectVersionSub = /* GraphQL */ `
  subscription OnUpdateProjectVersionSub($id: ID) {
    onUpdateProjectVersionSub(id: $id) {
      id
      accountOwner
      owner
      stateJsonSchemaStr
      settingsJsonSchemaStr
      settings
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      publishedProject {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      updatedAt
      playerVersionToUse
      createdAt
      projectProjectVersionsId
    }
  }
`;
export const onSendInstantNotification = /* GraphQL */ `
  subscription OnSendInstantNotification($owner: String!) {
    onSendInstantNotification(owner: $owner) {
      type
      subType
      status
      title
      message
      owner
      projectId
      extra
    }
  }
`;
export const onCreateAccount = /* GraphQL */ `
  subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
    onCreateAccount(filter: $filter) {
      id
      gid
      name
      projects {
        nextToken
      }
      fileuploads {
        originalName
        createdAt
        fileName
        fileStatus
        fileError
        numRecords
        numErrors
        totalRecords
        writtenRecords
      }
      integrations {
        hubspot
      }
      aliasId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAccount = /* GraphQL */ `
  subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
    onUpdateAccount(filter: $filter) {
      id
      gid
      name
      projects {
        nextToken
      }
      fileuploads {
        originalName
        createdAt
        fileName
        fileStatus
        fileError
        numRecords
        numErrors
        totalRecords
        writtenRecords
      }
      integrations {
        hubspot
      }
      aliasId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAccount = /* GraphQL */ `
  subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
    onDeleteAccount(filter: $filter) {
      id
      gid
      name
      projects {
        nextToken
      }
      fileuploads {
        originalName
        createdAt
        fileName
        fileStatus
        fileError
        numRecords
        numErrors
        totalRecords
        writtenRecords
      }
      integrations {
        hubspot
      }
      aliasId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $owner: String
  ) {
    onCreateNotification(filter: $filter, owner: $owner) {
      id
      type
      subType
      status
      title
      message
      read
      owner
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      createdAt
      extra
      updatedAt
      projectNotificationsId
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $owner: String
  ) {
    onUpdateNotification(filter: $filter, owner: $owner) {
      id
      type
      subType
      status
      title
      message
      read
      owner
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      createdAt
      extra
      updatedAt
      projectNotificationsId
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
    $owner: String
  ) {
    onDeleteNotification(filter: $filter, owner: $owner) {
      id
      type
      subType
      status
      title
      message
      read
      owner
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      createdAt
      extra
      updatedAt
      projectNotificationsId
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject(
    $filter: ModelSubscriptionProjectFilterInput
    $owner: String
  ) {
    onCreateProject(filter: $filter, owner: $owner) {
      id
      projectAccountId
      title
      account {
        id
        gid
        name
        aliasId
        createdAt
        updatedAt
      }
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      allowCreateDataPoint
      allowDataConnect
      analyticsReportUrl
      createdAt
      dataFiles {
        fileName
        tag
        createdAt
      }
      description
      fileName
      jsonChanges
      jsonVidUrl
      playerSettings {
        posterFrame
        showTimeline
        storiesMode
        color_loader
        color_ctrlBtns
        color_rail
        color_progress
        color_thumb
        color_bg
        muted
        autoplay
        autoReplay
        showBlingsLogo
      }
      projectVersions {
        nextToken
      }
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      analyticsEnabled
      owner
      renders {
        nextToken
      }
      notifications {
        nextToken
      }
      aliasId
      minisiteConfigs {
        scenes
        title
        description
        thumbUrl
        faviconUrl
      }
      playerVersionToUse
      customHtml
      updatedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject(
    $filter: ModelSubscriptionProjectFilterInput
    $owner: String
  ) {
    onDeleteProject(filter: $filter, owner: $owner) {
      id
      projectAccountId
      title
      account {
        id
        gid
        name
        aliasId
        createdAt
        updatedAt
      }
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      allowCreateDataPoint
      allowDataConnect
      analyticsReportUrl
      createdAt
      dataFiles {
        fileName
        tag
        createdAt
      }
      description
      fileName
      jsonChanges
      jsonVidUrl
      playerSettings {
        posterFrame
        showTimeline
        storiesMode
        color_loader
        color_ctrlBtns
        color_rail
        color_progress
        color_thumb
        color_bg
        muted
        autoplay
        autoReplay
        showBlingsLogo
      }
      projectVersions {
        nextToken
      }
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      analyticsEnabled
      owner
      renders {
        nextToken
      }
      notifications {
        nextToken
      }
      aliasId
      minisiteConfigs {
        scenes
        title
        description
        thumbUrl
        faviconUrl
      }
      playerVersionToUse
      customHtml
      updatedAt
    }
  }
`;
export const onCreateProjectVersion = /* GraphQL */ `
  subscription OnCreateProjectVersion(
    $filter: ModelSubscriptionProjectVersionFilterInput
    $owner: String
  ) {
    onCreateProjectVersion(filter: $filter, owner: $owner) {
      id
      accountOwner
      owner
      stateJsonSchemaStr
      settingsJsonSchemaStr
      settings
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      publishedProject {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      updatedAt
      playerVersionToUse
      createdAt
      projectProjectVersionsId
    }
  }
`;
export const onUpdateProjectVersion = /* GraphQL */ `
  subscription OnUpdateProjectVersion(
    $filter: ModelSubscriptionProjectVersionFilterInput
    $owner: String
  ) {
    onUpdateProjectVersion(filter: $filter, owner: $owner) {
      id
      accountOwner
      owner
      stateJsonSchemaStr
      settingsJsonSchemaStr
      settings
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      publishedProject {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      updatedAt
      playerVersionToUse
      createdAt
      projectProjectVersionsId
    }
  }
`;
export const onDeleteProjectVersion = /* GraphQL */ `
  subscription OnDeleteProjectVersion(
    $filter: ModelSubscriptionProjectVersionFilterInput
    $owner: String
  ) {
    onDeleteProjectVersion(filter: $filter, owner: $owner) {
      id
      accountOwner
      owner
      stateJsonSchemaStr
      settingsJsonSchemaStr
      settings
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      publishedProject {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      updatedAt
      playerVersionToUse
      createdAt
      projectProjectVersionsId
    }
  }
`;
export const onCreateServerRender = /* GraphQL */ `
  subscription OnCreateServerRender(
    $filter: ModelSubscriptionServerRenderFilterInput
    $cognitoUserIdentifier: String
  ) {
    onCreateServerRender(
      filter: $filter
      cognitoUserIdentifier: $cognitoUserIdentifier
    ) {
      id
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      scenes
      data
      env
      outputFileName
      quality
      frameToStart
      frameToEnd
      format
      status {
        ready
        status
        downloadLink
        code
      }
      cognitoUserIdentifier
      cognitoGroupOwner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateServerRender = /* GraphQL */ `
  subscription OnUpdateServerRender(
    $filter: ModelSubscriptionServerRenderFilterInput
    $cognitoUserIdentifier: String
  ) {
    onUpdateServerRender(
      filter: $filter
      cognitoUserIdentifier: $cognitoUserIdentifier
    ) {
      id
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      scenes
      data
      env
      outputFileName
      quality
      frameToStart
      frameToEnd
      format
      status {
        ready
        status
        downloadLink
        code
      }
      cognitoUserIdentifier
      cognitoGroupOwner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteServerRender = /* GraphQL */ `
  subscription OnDeleteServerRender(
    $filter: ModelSubscriptionServerRenderFilterInput
    $cognitoUserIdentifier: String
  ) {
    onDeleteServerRender(
      filter: $filter
      cognitoUserIdentifier: $cognitoUserIdentifier
    ) {
      id
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      scenes
      data
      env
      outputFileName
      quality
      frameToStart
      frameToEnd
      format
      status {
        ready
        status
        downloadLink
        code
      }
      cognitoUserIdentifier
      cognitoGroupOwner
      createdAt
      updatedAt
    }
  }
`;
