import { Form } from "antd";
import { TextButton } from "../../components/TextButton";
import { useState } from "react";
import "./TextLayerComponent.scss";
import staticText from "../../utils/staticText";
import { TextLayer } from "../../types/LayerTypes";
import { BlingsInput } from "../../components/BlingsInput/BlingsInput";
import { BlingsButton } from "../../components/BlingsButton/BlingsButton";
const { textLayer: text } = staticText;
type Props = {
  layer: TextLayer;
  update: (layer: TextLayer, newValue: string, isSave?: boolean) => void;
  collapse: (layer: TextLayer) => void;
};

const TextLayerComponent = ({ layer, collapse, update }: Props) => {
  const [value, setValue] = useState<string | undefined>(undefined);
  const [currentTimer, setCurrentTimer] = useState<any>(null);

  // Button handling to close and reset value of the text box
  const handleButton = () => {
    collapse(layer); // close panel on save
  };
  const handleChange = (newValue: any, isSave?: boolean) => {
    if (currentTimer) clearTimeout(currentTimer);
    setValue(newValue);
    if (!isSave) {
      const timer = setTimeout(() => {
        console.log("Updating");
        update(layer, newValue);
      }, 1000);
      setCurrentTimer(timer);
    } else {
      if (newValue) update(layer, newValue, isSave);
    }
  };
  // Method to remove \r and \n from the string
  const cleanString = (str: string) => {
    return str.replace(/(\r\n|\n|\r)/gm, "");
  };
  return (
    <Form className={"EditTextForm"}>
      <Form.Item>
        <BlingsInput
          defaultValue={layer.text}
          initialValue={layer.controlledText || layer.text}
          label={text.CHANGE_TEXT}
          showRestoreButton={
            layer?.controlledText !== undefined &&
            cleanString(layer.text) !== cleanString(layer.controlledText)
          }
          value={value}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item>
        <div className={"Buttons"}>
          <TextButton text={text.CANCEL} onClick={handleButton} />
          <BlingsButton
            disabled={!value}
            onClick={() => {
              handleChange(value, true);
            }}
          >
            {text.SAVE}
          </BlingsButton>
        </div>
      </Form.Item>
    </Form>
  );
};

export default TextLayerComponent;
