import React, { useContext, useEffect, useState } from "react";
import { ObjectFieldTemplateProps } from "@rjsf/core";
import { Collapse, Input, Typography } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "./ObjectFieldTemplate.module.scss";
import {
  EditableFormContext,
  idSep,
  SchemaContext,
  SchemaUpdateContext,
} from "../DynamicFormContext";
import { defaultValuesFromType } from "../helpers";
import { InternalSchemaTypes } from "../JSONFormAntd";
import { PlusIcon } from "./Icons";
const { Panel } = Collapse;
const { Text } = Typography;

export const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  const [currentKey, setCurrentKey] = useState<string | string[]>([]);
  const [currentId, setCurrentId] = useState<string>("");
  const [currentEditableGroup, setCurrentEditableGroup] = useState<string>("");
  const [keyName, setKeyName] = useState<string | undefined>(undefined);
  const {editable} = useContext(EditableFormContext);
  if (!props.properties.length) return null;
  const schemaContext = useContext(SchemaContext);
  const schemaUpdateContext = useContext(SchemaUpdateContext);
  const addSubField = (id: string | undefined) => {
    if (id) {
      const newSchema = { ...schemaContext };
      newSchema.properties = newSchema.properties || {};

      schemaUpdateContext.updateSchema({
        key: "",
        id: id + idSep,
        type: "string",
        def: defaultValuesFromType[InternalSchemaTypes.TEXT],
        oneOf: undefined,
      });
    }
  };

  const addSubGroup = (id: string | undefined) => {
    if (id) {
      const newSchema = { ...schemaContext };
      newSchema.properties = newSchema.properties || {};
      const now = new Date(Date.now());
      const currentDate = `${now.getDate()}-${now.getMonth()}-${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;

      schemaUpdateContext.updateSchema({
        key: `new @ ${currentDate}`,
        id: id + "==/==",
        type: "object",
        properties: {},
        oneOf: undefined,
        createSubGroupAction: true,
      });
    }
  };

  const renameSubGroup = (properties: any) => {
    if (currentId && keyName) {
      schemaUpdateContext.updateSchema({
        key: keyName,
        id: currentId,
        properties,
        type: "object",
        def: defaultValuesFromType[InternalSchemaTypes.TEXT],
        oneOf: undefined,
      });
    }
  };
  const deleteGroup = (id: string) => {
    schemaUpdateContext.deleteSchema(id);
  };
  return (
    <Collapse
      className={styles.ObjectFieldTemplate}
      activeKey={currentKey}
      onChange={setCurrentKey}
    >
      {props.properties.map(element => {
        const panelJsx = (method, text) => (
          <div
            className={styles.Text}
            onClick={e => {
              e.stopPropagation();
              method(element.content.props.idSchema?.$id || undefined);
            }}
          >
            <PlusIcon /> {text}
          </div>
        );

        const isEditMode =
          currentEditableGroup === element.content.props.schema.title ||
          currentEditableGroup === element.name;
        if (typeof element.content.props.formData === "object") {
          const errors = Object.keys(element.content.props.errorSchema);
          const currentTitle =
            element.content.props.schema.title || element.name;
          return (
            <Panel
              className={styles.Group}
              extra={
                errors.length ? (
                  <Text type="danger">{errors.join(", ")}</Text>
                ) : (
                  false
                )
              }
              key={element.content.props.idSchema.$id}
              header={
                <div className={styles.ObjectPanel}>
                  {isEditMode ? (
                    <>
                      <Input
                        onBlur={e => {
                          renameSubGroup(
                            element.content.props.schema.properties
                          );
                          setCurrentEditableGroup("");
                        }}
                        style={{ backgroundColor: "var(--blings_teal_80)" }}
                        defaultValue={currentTitle}
                        onClick={e => e.stopPropagation()}
                        value={keyName}
                        onPressEnter={() => {
                          renameSubGroup(
                            element.content.props.schema.properties
                          );
                          setCurrentEditableGroup("");
                        }}
                        onChange={e => {
                          setKeyName(e.target.value);
                          setCurrentId(element.content.props.idSchema.$id);
                        }}
                      />
                      <div
                        onClick={() =>
                          deleteGroup(element.content.props.idSchema.$id)
                        }
                        style={{placeSelf:'center'}}
                      >
                        <DeleteOutlined />
                      </div>
                    </>
                  ) : (
                    currentTitle
                  )}
                  {!isEditMode && editable && (
                    <div
                      style={{ alignSelf: "center", marginLeft: "0.2rem" }}
                      onClick={e => {
                        e.stopPropagation();
                        setCurrentEditableGroup(currentTitle);
                      }}
                    >
                      <EditOutlined twoToneColor={"#374146"} />
                    </div>
                  )}
                  {currentKey.includes(element.content.props.idSchema.$id) && editable && (
                    <div className={styles.ExtraOptions}>
                      {panelJsx(addSubGroup, "Group")}
                      {panelJsx(addSubField, "Field")}
                    </div>
                  )}
                </div>
              }
            >
              {element.content}
            </Panel>
          );
        }
        return element.content;
      })}
    </Collapse>
  );
};
