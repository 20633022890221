import { IProjectModel } from "../../stores/project/projectModel";
import {
  Card,
  Checkbox,
  Col,
  Divider,
  InputNumber,
  Radio,
  Row,
  Space,
  Spin,
  Switch,
} from "antd";
import { Storage } from "aws-amplify";
import { DemoSdkPlayer } from "../ProjectDemoPage/DemoSDKPlayer";
import { useState } from "react";
import { jsonSchemaGetExamples } from "../../helpers/jsonSchema.helpers";
import { bool } from "aws-sdk/clients/signer";
import { ColorPicker } from "../../components/ColorPicker";
import { useMst } from "../../stores/Root";
import { BLINGS_COLOR, GRAY_COLOR } from "../../utils/color.consts";
import { CDN, getENV } from "../../config";
import { Player } from "@blings/blings-player";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { FEEDBACK_OPTIONS, FEEDBACK_TIME } from "../../consts";
type Props = {
  project: IProjectModel;
};
export const PlayerSettingsUI = ({ project }: Props) => {
  const state = useMst((store) => {
    const selectedProject = store.projectsStore.selectedProject;
    return {
      selectedProject,
      playerSettings: selectedProject?.playerSettings,
    };
  });
  const [saveState, setSaveState] = useState<AsyncOpState>(
    AsyncOpState.Changed
  );
  const [currentThumbnail, setCurrentThumbnail] = useState<string | undefined>(
    project.thumbS3Url || undefined
  );
  const [thumbnailSaving, setThumbnailSaving] = useState<boolean>(false);
  const data = jsonSchemaGetExamples(
    JSON.parse(project.stateJsonSchemaStr || "{}")
  );
  const [posterFrame, setPosterFrame] = useState<number>(
    state.playerSettings?.posterFrame || 30
  );
  const [loaderColor, setLoaderColor] = useState<string>(
    state.playerSettings?.color_loader || BLINGS_COLOR
  );
  const [buttonsColor, setButtonsColor] = useState<string>(
    state.playerSettings?.color_ctrlBtns || GRAY_COLOR
  );
  const [backgroundColor, setBackgroundColor] = useState<string>(
    state.playerSettings?.color_bg || "transparent"
  );
  const [progressColor, setProgressColor] = useState<string>(
    state.playerSettings?.color_progress || GRAY_COLOR
  );
  const [railColor, setRailColor] = useState<string>(
    state.playerSettings?.color_rail || GRAY_COLOR
  );
  const [thumbColor, setThumbColor] = useState<string>(
    state.playerSettings?.color_thumb || GRAY_COLOR
  );
  const [autoReplay, setAutoReplay] = useState<bool>(
    state.playerSettings?.autoReplay || false
  );
  const [autoplay, setAutoplay] = useState<bool>(
    state.playerSettings?.autoplay || false
  );
  const [muted, setMuted] = useState<bool>(
    state.playerSettings?.muted || false
  );
  const [showTimeline, setShowTimeline] = useState<bool>(
    state.playerSettings?.showTimeline || true
  );
  const [storieMode, setStorieMode] = useState<bool>(
    state.playerSettings?.storiesMode || false
  );

  const playerSettingsForPlayer = {
    posterFrame: posterFrame,
    colors: {
      loader: loaderColor,
      ctrlBtns: buttonsColor,
      rail: railColor,
      progress: progressColor,
      thumb: thumbColor,
      bg: backgroundColor,
    },
    autoplay: autoplay,
    autoReplay: autoReplay,
    muted: muted,
    showTimeline: showTimeline,
    storiesMode: storieMode,
    showBlingsLogo: state.playerSettings?.showBlingsLogo||0
  };

  const PlayerSettingsInput: { [key: string]: any } = {
    posterFrame: posterFrame,
    showTimeline: showTimeline,
    storiesMode: storieMode,
    color_loader: loaderColor,
    color_ctrlBtns: buttonsColor,
    color_rail: railColor,
    color_progress: progressColor,
    color_thumb: thumbColor,
    color_bg: backgroundColor,
    muted: muted,
    autoplay: autoplay,
    autoReplay: autoReplay,
    showBlingsLogo: state.playerSettings?.showBlingsLogo||0
  };
  async function generateThumbnailForProject() {
    const player: Player = (window as any).p;
    if (player && posterFrame === player.posterFrame) {
      try {
        const options = {
          frame: posterFrame,
          download: false,
        };
        console.log(`Rendering _thumbnail_frame_${options.frame}.png`);
        const file = await player.downloadFrame(options);
        if (file) {
          console.log(`Uploading _thumbnail_frame_${options.frame}.png`);
          const res = await Storage.put<{ key: string }>(
            `${project.id}_thumbnails/_thumbnail_frame_${options.frame}.png`,
            file,
            {
              level: "public",
              contentType: file.type || "application/json",
            }
          );
          return `${CDN[getENV()]}/${res.key}`;
        }
        return;
      } catch (e) {
        console.error(e);
      }
      return;
    }
  }

  async function save() {
    setSaveState(AsyncOpState.Saving);
    try {
      await state.selectedProject.savePlayerSettings(PlayerSettingsInput);
      setSaveState(AsyncOpState.Success);
      setTimeout(() => {
        setSaveState(AsyncOpState.Changed);
      }, FEEDBACK_TIME);
      if (
        !currentThumbnail ||
        !currentThumbnail.endsWith(posterFrame + ".png")
      ) {
        setThumbnailSaving(true); // fFr save thumbnail feedback
        // Wait 1 sec for the video
        await new Promise((resolve) => setTimeout(resolve, FEEDBACK_TIME));
        // Generate Thumbnail:
        const newThumbnail = await generateThumbnailForProject();
        console.log("Saving thumbnail");
        // Uplaod:
        await state.selectedProject.uploadAndSetThumbnail(newThumbnail);
        setCurrentThumbnail(newThumbnail);
        setThumbnailSaving(false); // For save thumbnail feedback
      }
    } catch (error) {
      setSaveState(AsyncOpState.Error);
      console.error(error);
      setTimeout(() => setSaveState(AsyncOpState.Changed), FEEDBACK_TIME);
    }
  }

  return (
    <div className={"PlayerSetting"}>
      <Row>
        <Col span={8}>
          <Card>
            <Space direction="vertical" size="large" wrap>
              <div>
                <Space size="large" wrap>
                  <Space wrap>
                    <span>poster frame:</span>
                    <InputNumber
                      min={0}
                      value={posterFrame}
                      onChange={(e) => {
                        setPosterFrame(e || 0);
                      }}
                    />
                  </Space>
                  <Checkbox
                    style={{ display: "none" }}
                    checked={autoplay}
                    onChange={(e) => {
                      setAutoplay(e.target.checked);
                    }}
                  >
                    Autoplay
                  </Checkbox>
                  <Checkbox
                    checked={autoReplay}
                    onChange={(e) => {
                      setAutoReplay(e.target.checked);
                    }}
                  >
                    Loop
                  </Checkbox>
                  <Checkbox
                    checked={muted}
                    onChange={(e) => {
                      setMuted(e.target.checked);
                    }}
                  >
                    Start video with mute
                  </Checkbox>
                </Space>
              </div>
              <div>
                <Space size="large" wrap>
                  <Space wrap={false}>
                    <label>Loader:</label>
                    <ColorPicker
                      color={loaderColor}
                      onChange={(color) => setLoaderColor(color.hex)}
                    />
                  </Space>
                  <Space wrap={false}>
                    <label>Background:</label>
                    <ColorPicker
                      color={backgroundColor}
                      onChange={(color) => setBackgroundColor(color.hex)}
                    />
                  </Space>

                  <Space wrap={false}>
                    <label>Buttons:</label>
                    <ColorPicker
                      color={buttonsColor}
                      onChange={(color) => setButtonsColor(color.hex)}
                    />
                  </Space>
                </Space>
              </div>
              <Divider orientation="left" />
              <div>
                <Space size="middle" wrap>
                  <Switch
                    size="small"
                    checked={showTimeline}
                    onChange={setShowTimeline}
                  />
                  <span>Timeline</span>
                </Space>
              </div>
              <Card bordered={true}>
                {showTimeline ? (
                  <>
                    <Space direction="vertical" size="large" wrap>
                      <Space size="small" wrap>
                        <Radio.Group
                          onChange={(e) => {
                            setStorieMode(e.target.value);
                          }}
                          value={storieMode}
                        >
                          <Radio value={false}>Timeslider</Radio>
                          <Radio value={true}>Story Mode</Radio>
                        </Radio.Group>
                      </Space>
                      <Space size="large" wrap>
                        <Space wrap={false}>
                          <label>Progress:</label>
                          <ColorPicker
                            color={progressColor}
                            onChange={(color) => setProgressColor(color.hex)}
                          />
                        </Space>

                        <Space wrap={false}>
                          <label>Rail:</label>

                          <ColorPicker
                            color={railColor}
                            onChange={(color) => setRailColor(color.hex)}
                          />
                        </Space>
                        {!storieMode ? (
                          <Space wrap={false}>
                            <label>Thumb:</label>
                            <ColorPicker
                              color={thumbColor}
                              onChange={(color) => setThumbColor(color.hex)}
                            />
                          </Space>
                        ) : null}
                      </Space>
                    </Space>
                  </>
                ) : null}
              </Card>
              <Divider orientation="left" />
            </Space>
            <Space>
              <BlingsBtn
                opState={saveState}
                htmlType={"submit"}
                btnTexts={FEEDBACK_OPTIONS}
                onClick={() => {
                  save();
                }}
              />
              {thumbnailSaving ? <Spin>Uploading thumbnail</Spin> : null}
            </Space>
          </Card>
        </Col>
        <Col span={16}>
          <div style={{ marginLeft: "10em", maxWidth: "75%" }}>
            <DemoSdkPlayer
              project={project}
              data={data}
              settings={playerSettingsForPlayer}
              frameIndicator={true}
            />
          </div>
        </Col>
      </Row>
      <Row></Row>
    </div>
  );
};
