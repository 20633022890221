import { Instance, types } from "mobx-state-tree";
import {
  asyncOpStateType,
  INITIAL_ASYNC_OP_STATE,
} from "../../types/mst/async-op-state";
import { createDataPointActions } from "./createDataPointActions";
import { saveSchemasActions } from "./saveSchemasActions";
import { saveFileNameActions } from "./saveFileNameActions";
import { saveMiniSiteConfigActions } from "./saveMiniSiteConfigActions";
import {
  ProjectProps,
  DataFileProps,
  VidPartProps,
  ModProps,
} from "../platformSchema";
import { ModInput, PlayerSettingsInput } from "../../API";
import { emptySchema } from "../../helpers/jsonSchema.helpers";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { flattenObject } from "../../helpers/object.helpers";
import { generateCSV } from "../../helpers/file.helpers";
import { downloadFile } from "../../helpers/file.helpers";
import { getAssetLayerPairs } from "./getAssetLayerPairs";
import { TextLayer } from "../../types/LayerTypes";
import { updateAsyncStatus } from "../async-op-states";
import {
  getLatestProjectVersion,
  publishProject,
  savePublished,
  updateVersion,
} from "../../utils/projectTools";
import { rootStore } from "../Root";
const PLATFORM_CREATE_DATA_POINT_AUTO_SOURCE_ID_PREFIX = "PLATFORM_SOURCE_ID";
export const mainAssetKey = "main_asset_keys";
export const sequenceLayerId = "sequence_";
export const seqImgId = "imgSeq_";
export const PLATFORM_TEXT_CONTROL = "PLATFORM_TEXT_CONTROL";
export const DataFileModel = types.model("DataFile", {
  ...DataFileProps,
  // fileName: types.string,
  // tag: types.maybeNull(types.string),
  // createdAt: types.maybeNull(types.string),
});

export const ModModel = types.model("Mod", {
  ...ModProps,
  origin: types.optional(types.maybeNull(types.string), null),
});
/*
export const PlayerSettingsModel = types.Date.model("PlayerSettings", {
  jsonData: types.maybeNull(types.frozen())
})
*/
export const VidPartModel = types
  .model("VidPart", {
    ...VidPartProps,
    jsonData: types.maybeNull(types.frozen()),
    assetLayerPairs: types.maybeNull(types.map(types.array(types.string))),
    modsArr: types.maybeNull(types.array(types.maybeNull(ModModel))),
    initialModsArr: types.frozen(),
    mods: types.maybeNull(types.string),
    // fileName: types.string,
    // tag: types.maybeNull(types.string),
    // createdAt: types.maybeNull(types.string),
  })
  .actions((self) => ({
    /**
     * This method will delete the specific connector from the modsArray based on layerName and type
     * @param layerName
     * @param type
     */
    deleteMod(layerName: string, type: string) {
      // Get the index of the connector that we want to delete from modsArr
      const indexOfConnector = self?.modsArr?.findIndex((mod: any) => {
        const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
        return dataStr.layerName === layerName && dataStr.type === type;
      });
      if (typeof indexOfConnector === "number") {
        const oldMods = [...(self.modsArr || [])];
        oldMods.splice(indexOfConnector, 1);
        self.modsArr?.replace(oldMods);
      }
    },
    /**
     * Create a value connector on the layer
     * @param layerName
     * @param value
     * @param asset
     */
    createTextMod(layerName: string, value: string, asset: string | undefined) {
      // Generate a new Id
      let id = 0;
      if (self.modsArr) {
        id =
          self.modsArr.reduce(
            (maxId, mod) => Math.max(mod?.id || 0, maxId),
            -1
          ) + 1 || 0;
      }

      const newMod = {
        dataStr: JSON.stringify({
          layerName,
          type: "text",
          value,
          assetId: asset,
        }),
        id,
        origin: PLATFORM_TEXT_CONTROL,
      };
      const mods = [...(self.modsArr || []), newMod];
      self.modsArr?.replace(mods);
    },
  }))
  .actions((self) => ({
    updateJsonData(jsonData: any) {
      self.jsonData = jsonData;
      const [assetLayerPairs, mainPairs] = getAssetLayerPairs(jsonData);
      assetLayerPairs[mainAssetKey] = mainPairs;
      Object.keys(assetLayerPairs).forEach((key) => {
        if (key.indexOf(sequenceLayerId) === 0) {
          delete assetLayerPairs[key];
        }
      });
      self.assetLayerPairs = assetLayerPairs;
    },
    // Reset the initial Mods array to the latest saved version
    setInitialMods(modsArr: any) {
      self.initialModsArr = modsArr;
    },
    /**
     * Option 1: Set the text back to original state so delete connector
     * Option 2: Set Text to a new state.
     *  - If layer has a live-control, delete the connector
     *  - Add Mod to ModsArr (value and origin)
     */
    updateTextMods(textLayer: TextLayer, newText: string) {
      // Option 1
      if (textLayer.text === newText && textLayer?.controlledText) {
        return self.deleteMod(textLayer.layerData.nm, "text");
      }
      // Option 2
      self.modsArr?.forEach((mod) => {
        const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
        if (dataStr?.layerName === textLayer.layerData.nm) {
          if (
            dataStr?.liveControlKey ||
            (dataStr?.value && mod?.origin === PLATFORM_TEXT_CONTROL)
          )
            self.deleteMod(textLayer.layerData.nm, "text"); // Delete the mod if it has a text control
        }
      });
      return self.createTextMod(
        textLayer.layerData.nm,
        newText,
        textLayer?.asset
      );
    },
  }));

export const MinisiteConfigModel = types.model("MinisiteConfig", {
  scenes: types.maybeNull(types.array(types.string)),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  thumbUrl: types.maybeNull(types.string),
  faviconUrl: types.maybeNull(types.string),
});

export const PlayerSettingsModel = types.model("PlayerSettingsStore", {
  posterFrame: types.maybeNull(types.number),
  showTimeline: types.maybeNull(types.boolean),
  storiesMode: types.maybeNull(types.boolean),
  color_loader: types.maybeNull(types.string),
  color_ctrlBtns: types.maybeNull(types.string),
  color_rail: types.maybeNull(types.string),
  color_progress: types.maybeNull(types.string),
  color_thumb: types.maybeNull(types.string),
  color_bg: types.maybeNull(types.string),
  muted: types.maybeNull(types.boolean),
  autoplay: types.maybeNull(types.boolean),
  autoReplay: types.maybeNull(types.boolean),
  showBlingsLogo: types.maybeNull(types.number)
});
export type IDataFileModel = Instance<typeof DataFileModel>;

const DataPointModel = types.model({
  dataId: types.string,
  sourceId: types.string,
  record: types.frozen(),
});
export type IDataPointModel = Instance<typeof DataPointModel>;

export const ProjectBaseModel = types
  .model("Project", {
    ...ProjectProps,
    account: types.maybeNull(types.model({ id: types.string })),
    workSpaceUpdatedAt: types.optional(types.string, ""),
    updatedAt: types.maybeNull(types.string),
    dataFiles: types.maybeNull(types.array(DataFileModel)),
    workspaceVideoParts: types.optional(types.array(VidPartModel), []),
    videoPartNames: types.optional(types.array(types.string), []),
    newDPFormData: types.optional(types.frozen(), {}), // immutable !
    saveLiveControlStatus: asyncOpStateType,
    savePerVideoDataStatus: asyncOpStateType,
    saveMiniSiteConfigStatus: asyncOpStateType,
    settings: types.maybeNull(types.frozen()), // immutable !
    saveModsStatus: asyncOpStateType,
    createNewDPStatus: asyncOpStateType,
    createdDPList: types.optional(types.array(DataPointModel), []),
    isPublishing: types.optional(types.boolean, false),
    customHtml: types.maybeNull(types.string),
    // projectVersions: types.maybeNull(undefined), TODO
    playerSettings: types.maybeNull(PlayerSettingsModel), //,  -- check
    minisiteConfig: types.optional(MinisiteConfigModel, {}),
    playerVersionToUse: types.maybeNull(types.string),
  })
  .views((self) => ({
    get platformAutoSourceId() {
      return PLATFORM_CREATE_DATA_POINT_AUTO_SOURCE_ID_PREFIX + "-" + self.id;
    },
    get settingsSchema() {
      if (self.settingsJsonSchemaStr)
        return JSON.parse(self.settingsJsonSchemaStr);
      return JSON.parse(JSON.stringify(emptySchema));
    },
    get stateJsonSchema() {
      if (self.stateJsonSchemaStr) return JSON.parse(self.stateJsonSchemaStr);
      return JSON.parse(JSON.stringify(emptySchema));
    },
    // returns true if the draft is up to date with the published version
    get isLiveVersion() {
      return !!(
        self?.updatedAt &&
        ((self?.workSpaceUpdatedAt &&
          self.workSpaceUpdatedAt < self.updatedAt) ||
          self?.updatedAt === self?.createdAt)
      );
    },
  }))
  .actions((self) => ({
    updateSaveModsStatus: updateAsyncStatus(self, "saveModsStatus"),
    setIsPublishing(isPublishing: boolean) {
      self.isPublishing = isPublishing;
    },
    setCreatedDPList(list: IDataPointModel[]) {
      self.createdDPList.replace(list);
    },
  }))
  .actions((self) => ({
    updatePlayerSettings(newSettings: any) {
      self.playerSettings = newSettings;
    },
    /**
     * Update the scenes with the latest changes and save in draft
     */
    async saveMod(sceneName: string, layer: TextLayer, type: string) {
      const scene = self.workspaceVideoParts.find(
        (vp) => vp.name === sceneName
      );
      const initialModsArr = scene?.initialModsArr
        ? [...scene.initialModsArr]
        : [];
      const modsArr = scene?.modsArr || [];
      const indexOfConnectorInitial = initialModsArr.findIndex((mod: any) => {
        const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
        return (
          dataStr.layerName === layer.layerData.nm && dataStr.type === type
        );
      });
      const indexOfConnectorCurrent = modsArr.findIndex((mod: any) => {
        const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
        return (
          dataStr.layerName === layer.layerData.nm && dataStr.type === type
        );
      });
      // If a connector has been changed
      if (indexOfConnectorInitial > -1 && indexOfConnectorCurrent > -1) {
        initialModsArr[indexOfConnectorInitial] =
          modsArr?.[indexOfConnectorCurrent];
      }
      // If a connector has been deleted
      else if (indexOfConnectorInitial > -1 && indexOfConnectorCurrent === -1) {
        initialModsArr.splice(indexOfConnectorInitial, 1);
      }
      //If a connector has been added
      else if (indexOfConnectorInitial === -1 && indexOfConnectorCurrent > -1) {
        initialModsArr.push(modsArr?.[indexOfConnectorCurrent]);
      }
      self.updateSaveModsStatus(AsyncOpState.Saving);
      try {
        const updatedScenes = self.workspaceVideoParts?.map((vp) => ({
          name: vp.name,
          jsonUrl: vp.jsonUrl,
          modsArr: sceneName === vp.name ? initialModsArr : vp.initialModsArr,
          mods: vp.mods,
          updatedAt: vp.updatedAt,
        }));
        await updateVersion({
          id: self.id,
          videoParts: updatedScenes,
        });
        scene?.setInitialMods(initialModsArr);
        self.updateSaveModsStatus(AsyncOpState.Success);
      } catch (e) {
        self.updateSaveModsStatus(AsyncOpState.Error);

        console.error("err", e);
      }
    },
    changeSavePlayerSettingsStatus(newStatus: AsyncOpState) {
      self.saveLiveControlStatus = newStatus;
    },
    async savePlayerSettings(
      playerSettings: PlayerSettingsInput,
      thumbnail: string
    ) {
      const beforePlayerSettings = JSON.stringify(self.playerSettings);
      this.updatePlayerSettings(playerSettings);
      this.changeSavePlayerSettingsStatus(AsyncOpState.Saving);
      try {
        const cpi = { id: self.id, playerSettings, thumbS3Url: thumbnail };
        await savePublished(cpi);
      } catch (e) {
        console.error("err", e);
        this.changeSavePlayerSettingsStatus(AsyncOpState.Error);
        setTimeout(() => {
          this.changeSavePlayerSettingsStatus(INITIAL_ASYNC_OP_STATE);
          this.updatePlayerSettings(JSON.parse(beforePlayerSettings));
        }, 3000);
      }
    },
    async uploadAndSetThumbnail(thumbnail: string) {
      try {
        const cpi = { id: self.id, thumbS3Url: thumbnail };
        await savePublished(cpi);
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * Get the published version, then update it with the new videopart changes and upload it.
     * Finally, load projects
     */
    async publishProject() {
      self.setIsPublishing(true);
      console.log("Publishing");
      const latestProjectVersion = await getLatestProjectVersion(self.id);
      const updatedVideoparts =
        latestProjectVersion.videoParts?.map((vp) => {
          const workSpaceVideopart = self.workspaceVideoParts.find(
            (wvp) => wvp.name === vp.name
          );
          const modsArray: ModInput[] | undefined =
            workSpaceVideopart?.modsArr?.map((mod) => ({
              dataStr: mod?.dataStr || "",
              id: mod?.id || 0,
              origin: mod?.origin || null,
            }));
          return { ...vp, modsArr: modsArray || vp.modsArr }; //Update the mods Array
        }) || [];
      latestProjectVersion.videoParts = updatedVideoparts;
      if (latestProjectVersion) await publishProject(latestProjectVersion);
      self.setIsPublishing(false);
      if (self.id) await rootStore.projectsStore.loadProject(self.id);
    },
    downloadExampleCSV() {
      const perVideoExample = self.stateJsonSchema.examples[0] || null;
      if (!perVideoExample) throw new Error("Example per video data not set");
      const flattenData = flattenObject(perVideoExample);
      const keys = ["id", ...Object.keys(flattenData)];
      const values = ["example_id", ...Object.values(flattenData)] as (
        | string
        | number
      )[];
      const csv = generateCSV(keys, [values]);
      downloadFile(csv, `${self.title}.csv`, "text/csv");
    },
  }));

export const ProjectModel = ProjectBaseModel.actions(createDataPointActions)
  .actions(saveSchemasActions)
  .actions(saveFileNameActions)
  .actions(saveMiniSiteConfigActions);

export type IProjectBaseModel = Instance<typeof ProjectBaseModel>;
export type IProjectModel = Instance<typeof ProjectModel>;
export type IVidPartModel = Instance<typeof VidPartModel>;
export type IModModel = Instance<typeof ModModel>;
