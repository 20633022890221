import "./TextButton.scss";

type Props = {
  onClick: () => void;
  text: string;
  className?: string;
};

export const TextButton = ({ onClick, text, className }: Props) => {
  return (
    <span
      onClick={onClick}
      className={["TextButton", className ? className : ""].join(" ")}
    >
      {text}
    </span>
  );
};
