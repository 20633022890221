import React from "react";
import { Popover, Typography } from "antd";
import "./HoverText.scss";
const { Text } = Typography;

interface HoverTextProps {
  title: string;
  full: string;
  popTitle?: string;
  onClick?: (d: any) => void;
}

export function HoverText({ title, full, popTitle, onClick }: HoverTextProps) {
  const classes = `HoverText ${!!onClick ? "clickable" : ""}`;
  return (
    <Popover className={classes} content={full} title={popTitle}>
      <Text
        // @ts-ignore
        onClick={onClick}
        code
      >
        {title}
      </Text>
    </Popover>
  );
}
