import { API } from "aws-amplify";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PLATFORM_REST_API } from "../../consts";
import { useMst } from "../../stores/Root";
const CONNECT_HUBSPOT = "/integration/hubspot/getAccessToken";
export const OAuthCallback = () => {
  const { platform } = useParams<{ platform: string }>();
  switch (platform) {
    case "hubspot":
    default:
      return <Hubspot />;
  }
};
export interface ITokenResponseIF {
  refreshToken: string;
  expiresIn: number;
  accessToken: string;
  idToken?: string;
  tokenType: string;
}
const Hubspot = () => {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");
  const state = useMst((store) => {
    return {
      hubspotConnection: store.accountStore.hubspotConnection,
      connectHubspot: store.accountStore.connectHubspot,
    };
  });
  useEffect(() => {
    const connect = async () => {
      const myInit = {
        crossDomain: true,
        body: { code },
      };
      const data: ITokenResponseIF = await API.post(
        PLATFORM_REST_API,
        CONNECT_HUBSPOT,
        myInit
      );
      return state.connectHubspot(data);
    };
    if (!state.hubspotConnection) connect();
  }, [state.hubspotConnection]);
  return (
    <p>
      {state.hubspotConnection ? "You can now close this page" : "Please wait"}
    </p>
  );
};
