import { useContext, useState, useRef, useEffect } from "react";
import { Card, Divider } from "antd";
import { observer } from "mobx-react-lite";
import { Space } from "antd";
import { DynamicForm } from "@blings/dynamic-form";
import { MSTContext } from "../../stores/Root";
import { ProjectCrudForm } from "../AdminPage/ProjectCrudForm";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { SimpleCreateProjectInput } from "../../stores/project/projectsModel";
import {
  emptySchema,
  fillSchemaExamplesWithData,
} from "../../helpers/jsonSchema.helpers";
import "./ProjectCreatePage.scss";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { PATHS } from "../../PATHS";
import { useNavigate } from "react-router-dom";

export const ProjectCreatePage = observer(() => {
  const history = useNavigate();
  const {
    accountStore: { cognitoGroupId },
    projectsStore: { createProjectNotAdmin, updateOrCreateProjectStatus },
  } = useContext(MSTContext);
  const [creating, setCreating] = useState(false);
  const [formData, setFormData] = useState<SimpleCreateProjectInput>();
  const submitLiveControl = useRef<() => void>(() => {
    return;
  });
  const submitData = useRef<() => void>(() => {
    return;
  });
  const formRef = useRef<HTMLFormElement>();
  const [saveState, setSaveState] = useState({
    form: false,
    liveControl: false,
    data: false,
  });
  useEffect(() => {
    if (
      saveState.data &&
      saveState.liveControl &&
      saveState.form &&
      !creating
    ) {
      setCreating(true);
      (async () => {
        const project = await createProjectNotAdmin(
          formData as SimpleCreateProjectInput
        );
        setCreating(false);
        if (project?.id) {
          history(
            `${PATHS.project}/${project.id}/${PATHS.settings}`
          );
        }
      })();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saveState]);

  if (!cognitoGroupId) {
    return <span></span>;
  }
  const {
    liveControlStore: { getAssetsUploadStatus, uploadAssetToProject },
  } = useContext(MSTContext);
  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}
      className="project-tab-padding Project-Create-Page"
    >
      <ProjectCrudForm<SimpleCreateProjectInput>
        actionText="Create"
        isCreate={true}
        isAdmin={false}
        onFinish={(cpiSimple) => {
          setFormData(cpiSimple);
          setSaveState({ ...saveState, form: true });
          submitLiveControl.current();
          submitData.current();
        }}
        formRef={formRef}
        btnInForm={false}
      />
      <div className="schemas">
        <div className="schema-card">
          <Card title={"Per Video Data Schema:"}>
            <p style={{ textDecoration: "underline" }}>
              {" "}
              Manually create a single video.{" "}
            </p>
            <ul>
              <li>
                Here you can generate a video for a specific user and send via
                PURL (personalized URL).{" "}
              </li>
              <li>
                Fill the user data in the placeholders to create a unique video.
              </li>
            </ul>
            <Divider />
            <DynamicForm
              schema={{
                $schema: "http://json-schema.org/draft-07/schema",
                $id: "http://example.com/example.json",
                type: "object",
                title: "",
                description: "",
                default: {},
                examples: [],
                required: [],
                properties: {},
                additionalProperties: true,
              }}
              formData={{}}
              editable={true}
              readable={false}
              saveData={(data, schema) => {
                fillSchemaExamplesWithData(schema, data);
                setFormData({
                  ...(formData as SimpleCreateProjectInput),
                  stateJsonSchemaStr: JSON.stringify(schema),
                });
                setSaveState({ ...saveState, data: true });
              }}
              submit={submitData}
              getAssetsUploadStatus={getAssetsUploadStatus}
              uploadAssetToProject={uploadAssetToProject}
            />
          </Card>
        </div>
        <div className="schema-card" style={{ marginRight: "5em" }}>
          <Card title={"Live Control Schema:"}>
            <p style={{ textDecoration: "underline" }}>
              {" "}
              Instantly change and update ALL videos.{" "}
            </p>
            <ul>
              <li>
                Here you can control all the video parameters in real time.{" "}
              </li>
              <li>
                Click on the open fields (text/URL/Image etc.) to edit the
                video. The changes will be reflected instantly in ALL the
                videos.
              </li>
            </ul>
            <Divider />
            <DynamicForm
              schema={JSON.parse(JSON.stringify(emptySchema))}
              formData={{}}
              editable={true}
              readable={false}
              saveData={(data, schema) => {
                fillSchemaExamplesWithData(schema, data);
                setFormData({
                  ...(formData as SimpleCreateProjectInput),
                  settingsJsonSchemaStr: JSON.stringify(schema),
                });
                setSaveState({ ...saveState, liveControl: true });
              }}
              submit={submitLiveControl}
              getAssetsUploadStatus={getAssetsUploadStatus}
              uploadAssetToProject={uploadAssetToProject}
            />
          </Card>
        </div>
      </div>
      <Divider />
      <Space>
        <BlingsBtn
          opState={updateOrCreateProjectStatus}
          htmlType={"submit"}
          btnTexts={{ [AsyncOpState.Changed]: "Create" }}
          onClick={() => {
            formRef.current?.submit();
          }}
        />
      </Space>
    </Space>
  );
});
