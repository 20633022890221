import { getRealENV } from "../../../../../config";
import { JSONSchema6 } from "json-schema";
import { flattenSchema } from "../../../../../helpers/jsonSchema.helpers";
import { BlingsContainer } from "../../../../../components/BlingsContainer/BlingsContainer";
import "./IntegrationLink.scss";
import { Button } from "antd";
type Props = {
  projectAliasId: string;
  accountAliasId: string;
  schema?: JSONSchema6;
};

const env = getRealENV();
export const IntegrationLink = (props: Props) => {
  const { projectAliasId, accountAliasId, schema } = props;

  const params = schema ? Object.keys(flattenSchema(schema)).map(key => `${key.toLowerCase().replaceAll(" ", "_")}={{${key.toUpperCase()}}}`).join("&") : "blingsId={{CSV_ID}}";
  const paramsMap = schema ? flattenSchema(schema) : { blingsId: "CSV_ID" };
  const paramsHtml = Object.keys(paramsMap).map((key, i) => {
    return <span key={key}>{i !== 0 ? "&" : ""}{key}=<span className="token">{`{{${key.toUpperCase()}}}`}</span></span>;
  });
  const link = `https://${accountAliasId}.mp5.live/${env === "master" ? "" : `${env}/`}${projectAliasId}?${params}`;
  const linkHtml = <span>
    {`https://${accountAliasId}.mp5.live/${env === "master" ? "" : `${env}/`}${projectAliasId}?`}
    {paramsHtml}
  </span>;
  return <BlingsContainer className="IntegrationLink">
    <span>Copy your integration link and customize your tokens</span>
    <div>
      <div className="link-container">
        <div className="link">{linkHtml}</div>
      </div>
      <Button onClick={() => { navigator.clipboard.writeText(link); }}>COPY</Button>
    </div>
  </BlingsContainer>;
};