import { Row, Col, Select, Spin } from "antd";
import * as React from "react";
import { DynamicForm } from "@blings/dynamic-form";
import csv from "csvtojson";
import { Storage } from "aws-amplify";
import _ from "lodash";
import { IProjectModel } from "../../stores/project/projectModel";
import { IuploadDataModel } from "../../stores/uploadData";
import { jsonSchemaGetExamples } from "../../helpers/jsonSchema.helpers";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { MSTContext } from "../../stores/Root";
import { DemoSdkPlayer } from "./DemoSDKPlayer";
import "./DemoPage.scss";
import { cleanEmptyStringInValue } from "../../helpers/object.helpers";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
  isLoading: boolean;
};
const { useState, useEffect, useRef } = React;

const { Option, OptGroup } = Select;

// https://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
function flattenKeys(data: any) {
  const result = {} as any;
  function recurse(cur: any, prop: any) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i++) recurse(cur[i], prop + "[" + i + "]");
      if (l === 0) result[prop] = [];
    } else {
      let isEmpty = true;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "." + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return Object.keys(result);
}

function DemoPage({ project, isLoading }: Props) {
  const [sourceRecordsFromCsv, setSourceRecordsFromCsv] =
    useState<any>(undefined);
  const [sourceRecordsFromManualDp, setSourceRecordsFromManualDp] =
    useState<any>(undefined);
  const [selectedDataID, setSelectedDataId] = useState<string | undefined>(
    undefined
  );
  const dataExamples = jsonSchemaGetExamples(
    JSON.parse(project.stateJsonSchemaStr || "{}")
  );
  const [data, setData] = useState<any>(
    dataExamples ? cleanEmptyStringInValue(dataExamples) : undefined
  );

  const [recordChanged, setChangedFromRecordData] = useState<boolean>(false);

  useEffect(() => {
    const fetchSourceRecord = async (fileName: string) => {
      const res = (await Storage.get(fileName, { download: true })) as any;
      const text = await res.Body.text();
      csv()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        .fromString(text)
        .then((lines) => {
          const srs = {} as any;
          lines.forEach((line) => {
            srs[line.id] = line;
          });
          setSourceRecordsFromCsv(srs);
        });
    };

    if (project.fileName) {
      fetchSourceRecord(project.fileName);
    }
  }, [project.fileName]);

  useEffect(() => {
    const currentId = project.id;
    project.loadExistingDPs().then(() => {
      if (project.id === currentId && project.createdDPList.length) {
        const srs = {} as any;
        project.createdDPList.forEach((dp) => {
          srs[dp.dataId] = dp.record;
        });
        setSourceRecordsFromManualDp(srs);
      }
    });
  }, [project]);
  const submit = useRef<() => void>(() => {
    return;
  });
  const schema = JSON.parse(project.stateJsonSchemaStr || "{}");

  const fillDataFromRecord = (dataId: string) => {
    const record =
      sourceRecordsFromManualDp && sourceRecordsFromManualDp[dataId]
        ? sourceRecordsFromManualDp[dataId]
        : sourceRecordsFromCsv[dataId];
    const paths = flattenKeys(record);
    paths.forEach((path) => {
      const schemePath = path.split(".").join(".properties.");
      const type = _.get(schema.properties, `${schemePath}.type`);
      if (!type) return;
      const strValue = _.get(record, path);
      let value = strValue;
      switch (type) {
        case "integer":
        case "number":
          value = Number(strValue);
          break;
        case "boolean":
          value = strValue === "true";
          break;
        default:
          break;
      }
      _.set(record, path, value);
    });
    setData(cleanEmptyStringInValue(record));
    setChangedFromRecordData(false);
  };
  const {
    liveControlStore: { getAssetsUploadStatus, uploadAssetToProject },
  } = React.useContext(MSTContext);

  const showDemoSdk =
    project.videoPartNames && project.videoPartNames.length !== 0;
  if (!showDemoSdk || isLoading) {
    return (
      <div className={"DemoPage"}>
        <div className="text">
          Create a video using Blings extension,
          <br />
          and see a demo here
        </div>
      </div>
    );
  }

  // For the spin in the load dataPoint not show forever
  let shouldSpin = true;
  setTimeout(() => {
    shouldSpin = false;
  }, 5000);
  return (
    <div className={"DemoPage project-tab-padding"}>
      <Row wrap={false}>
        <Col flex={"30em"}>
          {
            // Add the spin animation to the data points while it's loading
            sourceRecordsFromCsv || sourceRecordsFromManualDp ? (
              <div>
                <span>Apply data from source:</span>
                <Select
                  showSearch
                  value={selectedDataID}
                  style={{ marginLeft: "10px", width: "200px" }}
                  onChange={(dataId: string) => {
                    setSelectedDataId(dataId);
                    fillDataFromRecord(dataId);
                  }}
                >
                  {sourceRecordsFromManualDp ? (
                    <OptGroup label="Data points">
                      {Object.keys(sourceRecordsFromManualDp).map((k) => (
                        <Option value={k} key={k}>
                          {k}
                        </Option>
                      ))}
                    </OptGroup>
                  ) : null}
                  {sourceRecordsFromCsv ? (
                    <OptGroup label="CSV records">
                      {Object.keys(sourceRecordsFromCsv).map((k) => (
                        <Option value={k} key={k}>
                          {k}
                        </Option>
                      ))}
                    </OptGroup>
                  ) : null}
                </Select>
              </div>
            ) : (
              <Spinner timeout={5000} />
            )
          }
          <DynamicForm
            schema={schema}
            formData={data}
            editable={false}
            readable={false}
            saveData={(d) => {
              setData(cleanEmptyStringInValue(d));
              setChangedFromRecordData(true);
            }}
            submit={submit}
            uploadAssetToProject={uploadAssetToProject}
            getAssetsUploadStatus={getAssetsUploadStatus}
          />
          <BlingsBtn
            opState={AsyncOpState.Changed}
            htmlType={"submit"}
            btnTexts={{
              [AsyncOpState.Untouched]: "Preview",
              [AsyncOpState.Changed]: "Preview",
            }}
            onClick={() => submit.current()}
          />
        </Col>
        <Col flex={"auto"}>
          <div style={{ margin: "auto", maxWidth: "60%", maxHeight: "100%" }}>
            {showDemoSdk && (
              <DemoSdkPlayer
                selectedDataID={selectedDataID}
                project={project}
                data={data}
                recordChanged={recordChanged}
                frameIndicator={false}
                renderMp4={true}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

/**
 * Create a spinner component with a timeout to hide it
 * @param props The props of the DemoPage component
 * - timeout: The timeout for the spinner to be shown
 * @returns The Spinner component
 */
function Spinner({ timeout }: { timeout: number; }) {
  const [spinnerTimeout, setSpinnerTimeout] = useState(timeout);
  const [shouldSpin, setShouldSpin] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldSpin(false);
    }, spinnerTimeout);
    return () => {
      clearTimeout(timer);
    };
  }, [spinnerTimeout]);
  return (
    <div>
      {shouldSpin ? (
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "space-around",
          }}
        >
          <Spin />
        </div>
      ) : null}
    </div>
  );
}

export default DemoPage;
