import { UsersPerDayProps } from "../Analytics";
import { ResponsiveLine } from "@nivo/line";
import { theme } from "../../../theme";
import { linearGradientDef } from "@nivo/core";
import { TooltipLine } from "./Tooltips";
export const UsersPerDay = ({ data }: { data?: UsersPerDayProps; }) => {
  if (!data) return null;
  const userIds = new Set();
  const processedData = data.map(day => {
    day.perUserId.buckets.forEach(userId => userIds.add(userId.key));
    return { x: new Date(day.key_as_string), y: day.perUserId.buckets.length };
  });

  const totalUsers = userIds.size;
  return (
    <div className="analytics-view users" style={{ height: "100%" }}>
      <div className="view-title">
        <h2>Unique users</h2>
        <h2>
          Total <span>{totalUsers}</span>
        </h2>
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <ResponsiveLine
          data={[
            {
              id: "Unique users per day",
              data: processedData,
            },
          ]}
          enableArea={true}
          colors={["#FF154D69"]}
          pointColor={"#FF154D"}
          curve={"monotoneX"}
          yScale={{ type: "linear", min: 0, max: "auto" }}
          axisLeft={{
            legend: "",
            legendOffset: 12,
            format: (e) => Math.floor(e) === e && e,
          }}
          axisBottom={{
            legend: "",
            legendOffset: 15,
            tickValues: 3,
            format: "%Y-%m-%d",
          }}
          xScale={{
            type: "time",
            format: "%YYYY-%mm-%ddT%HH:%MM:%SS.%LLLZ",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          motionConfig="gentle"
          useMesh={true}
          defs={[
            linearGradientDef("gradientB", [
              { offset: 0, color: "#FF154D" },
              { offset: 100, color: "#FF154D", opacity: 0.4 },
            ]),
          ]}
          fill={[{ match: "*", id: "gradientB" }]}
          theme={{
            background: "#FAF8F8",
            axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
            textColor: theme.blings_teal,
            grid: { line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 } },
          }}
          tooltip={TooltipLine}
          margin={{
            left: 40,
            right: 30,
            top: 30,
            bottom: 30,
          }}
        />
      </div>
    </div>
  );
};
