import React from "react";
import styles from "./FormButton.module.scss";

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  children: string | JSX.Element;
  disabled?: boolean;
  type?: "submit";
  className?: string;
  outline?: boolean;
};

export const FormButton = ({
  onClick,
  children,
  disabled,
  type,
  className,
  outline = false,
}: Props) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={[
        styles.BlingsButton,
        disabled ? styles.Disabled : "",
        className ? className : "",
        outline ? styles.Outlined : "",
      ].join(" ")}
    >
      {children}
    </button>
  );
};
