import { Card } from "antd";
import React from "react";
import { AUDIO, IMAGE, VIDEO } from "./consts";
import styles from "./MediaContent.module.scss";

const MediaContent = (props: any) => {
  const { src, format } = props;
  return (
    <Card className={format === AUDIO ? styles.AudioCard : styles.MediaCard}>
      {(format === IMAGE && <img src={src} className={styles.Image} />) ||
        (format === VIDEO && (
          <video controls src={src} className={styles.Video} />
        )) ||
        (format === AUDIO && (
          <audio src={src} controls className={styles.Audio} />
        ))}
    </Card>
  );
};
export default MediaContent;
