import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import { useEffect, useState } from "react";
import { TextIcon } from "../../assets/Icons";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import { Layer as LayersFromScene, TextLayer } from "../../types/LayerTypes";
import staticText from "../../utils/staticText";
import { useNavigate } from "react-router-dom";
import { panelKey } from "./LayersView";
import "./SearchLayers.scss";
type Props = {
  scene: string;
  projectId: string;
  layers: LayersFromScene[];
  handlePanelCollapse: (keys: string[] | string) => void;
  filterLayers: (searchString: string) => void;
  setCurrentInput: React.Dispatch<React.SetStateAction<string>>;
  currentInput: string;
};

const { searchLayer: Text } = staticText;
const SearchLayers = ({
  scene,
  layers,
  handlePanelCollapse,
  projectId,
  filterLayers,
  currentInput,
  setCurrentInput,
}: Props) => {
  const history = useNavigate();
  const [allScenesOptions, setAllScenesOptions] = useState<any>([]);
  const handleSelect = (e: any) => {
    history(
      toPath(PATHS.project, projectId, PROJECT_PATHS.editVideo, e[3])
    );
    handlePanelCollapse(panelKey(e[0] || "", e[1] || "", e[3] || ""));
  };

  const renderItem = (title: string) => (
    <div
      style={{
        display: "flex",
      }}
    >
      <div style={{ marginLeft: "0.1rem", marginRight: "0.5rem" }}>
        <TextIcon />
      </div>
      {title}
    </div>
  );
  const processLayersFromScene = (
    layersFromScene: LayersFromScene | undefined
  ) => {
    if (layersFromScene) {
      const allStaticTextLayers =
        layersFromScene["allStaticTextLayers"].map(
          (staticLayer: TextLayer) => ({
            value: [
              staticLayer.layerData.nm,
              staticLayer.asset,
              staticLayer.controlledText || staticLayer.text,
              layersFromScene.scene,
            ],
            label: renderItem(staticLayer.controlledText || staticLayer.text),
          })
        ) || [];
      const allDynamicLayers =
        layersFromScene["allDynamicTextLayers"].map(
          (dynamicLayer: TextLayer) => ({
            value: [
              dynamicLayer.layerData.nm,
              dynamicLayer.asset,
              dynamicLayer.text,
              layersFromScene.scene,
            ],
            label: renderItem(dynamicLayer.text),
            disabled: true,
          })
        ) || [];
      return [...allStaticTextLayers, ...allDynamicLayers];
    }
    //If we are looking through all scenes
    else {
      return [];
    }
  };

  useEffect(() => {
    const allScenesOptions = layers
      .map((layer: LayersFromScene) => ({
        label: layer.scene,
        options: [...processLayersFromScene(layer)],
      }))
      .filter((layer) => layer.options.length); // Remove scenes with no layers
    setAllScenesOptions(allScenesOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, scene]);
  return (
    <>
      <AutoComplete
        value={currentInput}
        onSelect={handleSelect}
        className={"Search"}
        notFoundContent={Text.CONTENT_NOT_FOUND}
        options={[...allScenesOptions]}
        filterOption={(inputValue, option) =>
          option?.label &&
          option?.value &&
          option?.value
            ?.filter((currentOption: any) => !!currentOption) // Remove undefined options
            ?.some(
              (possibleValue: string) =>
                possibleValue
                  ?.toLowerCase()
                  .trim()
                  ?.indexOf(currentInput.toLowerCase().trim()) > -1
            )
        }
      >
        <Input
          className={"SearchInput"}
          allowClear
          onChange={(e) => setCurrentInput(e.target.value)}
          onPressEnter={(e: any) =>
            filterLayers(e.target.value?.toLowerCase().trim())
          }
          bordered={false}
          placeholder={Text.PLACEHOLDER}
          prefix={<SearchOutlined className={"SearchIcon"} />}
        />
      </AutoComplete>
    </>
  );
};

export default SearchLayers;
