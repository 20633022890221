import * as React from "react";
import { useEffect, useState } from "react";
import { DatePicker, Spin } from "antd";
import { API } from "aws-amplify";
import "chart.js/auto";
import moment from "moment";
import { PLATFORM_REST_API } from "../../consts";
import { SessionsPerDay } from "./Analytics/SessionsPerDay";
import { SessionsPerCountry } from "./Analytics/SessionsPerCountry";
import { Funnel } from "./Analytics/Funnel";
import "./Analytics/Analytics.scss";
import { UsersPerDay } from "./Analytics/UsersPerDay";
import { TopCtas } from "./Analytics/TopCtas";
import { WatchTime } from "./Analytics/WatchTime";
import { SessionsPerDeviceType } from "./Analytics/SessionsPerDeviceType";
import { SessionsPerOs } from "./Analytics/SessionsPerOs";
export type SessionsPerDayProps = Array<{
  key_as_string: string;
  key: number;
  doc_count: number;
}>;
export type UsersPerDayProps = Array<{
  key_as_string: string;
  key: number;
  doc_count: number;
  perUserId: {
    buckets: Array<{ key: string; doc_count: number }>;
  };
}>;
export type SessionsPerCountryProps = Array<{ key: string; doc_count: number }>;
export type SessionsPerOsProps = Array<{ key: string; doc_count: number }>;
export type SessionsPerDeviceTypeProps = Array<{
  key: string;
  doc_count: number;
}>;
export type PerEventsProps = Array<PlayerEvent>;
export type PlayerEvent = {
  key: "ready" | "scene-change" | "first-play";
  doc_count: number;
  sceneChange: { buckets: Array<{ key: string; doc_count: number }> };
};
export type CtaPerScene = {
  key: string;
  doc_count: number;
  perCta: { buckets: Array<EventsPerCta> };
};
export type WatchTimePerScene = {
  key: string;
  doc_count: number;
  perFrame: { buckets: Array<WatchTimePerFrame> };
};
export type WatchTimePerFrame = {
  key: string;
  doc_count: number;
  sum: { value: number };
};
export type EventsPerCta = { key: string; doc_count: number };
export type ScenePath = { key: string; doc_count: number };
type AnalyticsResponse = {
  playerEvents: { perEvent: { buckets: PerEventsProps } } | null;
  sessions: {
    sessionsPerDay: { buckets: SessionsPerDayProps };
    sessionsPerCountry: { buckets: SessionsPerCountryProps };
    sessionsPerOs: { buckets: SessionsPerOsProps };
    sessionsPerDevice: { buckets: SessionsPerDeviceTypeProps };
    usersPerDay: { buckets: UsersPerDayProps };
  } | null;
  watchTimes: { perScene: { buckets: Array<WatchTimePerScene> } } | null;
  scenePaths: { paths: { buckets: Array<ScenePath> } } | null;
  ctas: { ctaPerScene: { buckets: Array<CtaPerScene> } } | null;
};
const DEFAULT_RANGE = 30 * 24 * 60 * 60 * 1000;

export function AnalyticsNew({ projectId }: { projectId: string }) {
  const [fromDate, setFromDate] = useState(Date.now() - DEFAULT_RANGE);
  const [toDate, setToDate] = useState(Date.now());
  const [data, setData] = useState<AnalyticsResponse | null>(null);
  useEffect(() => {
    (async () => {
      const data = await API.get(PLATFORM_REST_API, `/analytics/sessions`, {
        crossDomain: true,
        queryStringParameters: { projectId, from: fromDate, to: toDate },
      });
      setData(data as AnalyticsResponse);
    })();
  }, [fromDate, toDate]);
  return (
    <div className="analytics project-tab-padding">
      <div className="title">
        <h1 className="page-title">Analytics</h1>
        <DatePicker.RangePicker
          className="date-picker"
          defaultValue={[moment(fromDate), moment(toDate)]}
          onChange={(values) => {
            if (!values) return;
            const to = (values[1] && values[1].unix() * 1000) || Date.now();
            const from =
              (values[0] && values[0].unix() * 1000) || to - DEFAULT_RANGE;
            setToDate(to);
            setFromDate(from);
          }}
          showTime={{format: 'HH:mm'}}
          format={(value) => value.format("MMM DD, YYYY HH:mm")}
        />
      </div>

      {data ? (
        <div className="views-container">
          <div className="first-row">
            <Funnel
              data={{
                events: data.playerEvents?.perEvent.buckets,
                ctas: data.ctas?.ctaPerScene.buckets,
              }}
            />
            <TopCtas data={data.ctas?.ctaPerScene.buckets} />
          </div>
          <SessionsPerDay data={data.sessions?.sessionsPerDay.buckets} />
          <UsersPerDay data={data.sessions?.usersPerDay.buckets} />
          <WatchTime data={data.watchTimes?.perScene.buckets} />
          <SessionsPerDeviceType
            data={data.sessions?.sessionsPerDevice.buckets}
          />
          <SessionsPerOs data={data.sessions?.sessionsPerOs.buckets} />
          <SessionsPerCountry
            data={data.sessions?.sessionsPerCountry.buckets}
          />

          {/* <ScenePath data={data.scenePaths} /> */}
        </div>
      ) : (
        <Spin style={{ margin: "10px" }} />
      )}
    </div>
  );
}
