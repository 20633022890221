import {
  CtaPerScene,
  EventsPerCta,
  PerEventsProps,
  PlayerEvent,
} from "../Analytics";
import { ResponsiveBar } from "@nivo/bar";
import { theme } from "../../../theme";
import { TooltipFunnel } from "./Tooltips";
export const Funnel = ({
  data,
}: {
  data: { events?: PerEventsProps; ctas?: Array<CtaPerScene>; };
}) => {
  if (!data.events?.length) return null;
  const events = data.events.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue.key] = currentValue;
      return previousValue;
    },
    {} as {
      [k: string]: PlayerEvent;
    }
  );
  type Scene = {
    name: string;
    changes: number;
    cta?: EventsPerCta[];
  };
  const scenes =
    events["scene-change"]?.sceneChange.buckets.reduce((prev, curr) => {
      prev[curr.key] = { name: curr.key, changes: curr.doc_count };
      return prev;
    }, {} as { [s: string]: Scene; }) || {};
  if (data.ctas?.length)
    data.ctas.forEach((scene) => {
      scenes[scene.key] = scenes[scene.key] || { name: scene.key, count: 0 };
      scenes[scene.key].cta = scene.perCta.buckets;
    });
  const totalSessions = events["ready"]?.doc_count;
  type EventType = "player" | "scene" | "cta";
  const funnelData: Array<{
    key: string;
    events: number;
    type: EventType;
    percentage: number;
  }> = [
      {
        key: "Player Loaded",
        events: events["ready"]?.doc_count,
        type: "player",
        percentage: parseFloat(
          ((events["ready"]?.doc_count * 100) / totalSessions).toFixed(2)
        ),
      },
      {
        key: "First Play",
        events: events["first-play"]?.doc_count,
        type: "player",
        percentage: parseFloat(
          ((events["first-play"]?.doc_count * 100) / totalSessions).toFixed(2)
        ),
      },
    ];

  Object.values(scenes)
    .sort((a, b) => b.changes - a.changes)
    .forEach((scene) => {
      if (scene.name === "no-scene") return;
      funnelData.push({
        key: scene.name,
        events: scene.changes,
        type: "scene",
        percentage: parseFloat(
          ((scene.changes * 100) / totalSessions).toFixed(2)
        ),
      });
      if (scene.cta) {
        const ctaInScene = scene.cta.map((cta) => ({
          key: `${scene.name} CTA:${cta.key}`,
          type: "cta" as EventType,
          events: cta.doc_count,
          percentage: parseFloat(
            ((cta.doc_count * 100) / totalSessions).toFixed(2)
          ),
        }));
        funnelData.push(...ctaInScene);
      }
    });
  const colors = {
    player: theme.primary,
    scene: theme.primary,
    cta: theme.blings_teal,
  };

  return (
    <div className="analytics-view funnel" style={{ height: "100%" }}>
      <div className="view-title">
        <h2>Funnel</h2>
        <h2>
          Total <span>{events["ready"]?.doc_count}</span>
        </h2>
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <ResponsiveBar
          data={funnelData}
          keys={["events"]}
          indexBy="key"
          layers={[
            "grid",
            "axes",
            "bars",
            "markers",
            "legends",
            "annotations",
            ({ bars }) => {
              return (
                <g>
                  {bars.map(({ width, height, x, data }) => {
                    const translateX = x + width / 2;
                    const translateY = -height - 40;
                    return (
                      <text
                        style={{ position: "absolute", transform: `translateX(${translateX}px) translateY(80%) translateY(${translateY}px)`, fontSize: "11px" }}
                        text-anchor="middle"
                        dominant-baseline="central"
                      >
                        {`${data.data.percentage ? data.data.percentage + "%" : ""}`}
                      </text>
                    );
                  })}
                </g>
              );
            },
          ]}
          colors={(d) => colors[d.data.type]}
          theme={{
            background: "#FAF8F8",
            axis: { domain: { line: { stroke: "gray", strokeWidth: 1 } } },
            grid: { line: { stroke: theme.blings_teal_80, strokeWidth: 0.1 } },
          }}
          padding={0.5}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: "View Count",
            legendPosition: "end",
            legendOffset: 15,
            //format: (l) => l + "%", //Y axis label
          }}
          axisBottom={{
            tickRotation: -25,
            format: (v) => {
              return v.length > 18 ? (
                <tspan>
                  {"..." + v.substr(-17)}
                  <title>{v}</title>
                </tspan>
              ) : (
                v
              );
            }
          }}
          margin={{
            left: 50,
            right: 30,
            top: 30,
            bottom: 70,
          }}
          tooltip={TooltipFunnel}
          valueFormat={() => ""}
          legends={[
            {
              dataFrom: "indexes",
              data: [
                {
                  id: "videoEvents",
                  label: "Video Events",
                  color: colors.player,
                },
                { id: "ctas", label: "CTAs", color: colors.cta },
              ],
              anchor: "top-right",
              direction: "column",
              itemHeight: 20,
              itemWidth: 80,
              translateY: 0,
            },
          ]}
        />
      </div>
    </div>
  );
};
