import React, { useContext, useState, useEffect } from "react";
import { FieldTemplateProps } from "@rjsf/core";
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tooltip,
} from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import {
  CurrentOpenFieldContext,
  EditableFormContext,
  SchemaContext,
  SchemaUpdateContext,
  idSep,
} from "../DynamicFormContext";
import {
  defaultValuesFromType,
  getSchemaFromSchemaPath,
  internalSchemaTypeFromSchema,
  InternalSchemaTypes,
  SCHEMA_TYPES_STRUCTURE,
  selectOptionsFromObject,
  typeAndFormatFromInternalType,
} from "../helpers";
import styles from "./FullEditField.module.scss";
import { JSONSchema7TypeName } from "json-schema";
import { FieldState, FieldStateContext } from "../FieldTemplate";
import { CopyIcon, DeleteIcon } from "../Icons";
import AddOptionsForm from "./AddOptionsForm";
import TextArea from "antd/lib/input/TextArea";

export const FullEditField = (props: FieldTemplateProps) => {
  const schemaContext = useContext(SchemaContext);
  const [currentOpenField, setCurrentOpenField] = useContext(
    CurrentOpenFieldContext
  );
  const { fieldDescription } = useContext(EditableFormContext);
  const duplicateField = () => {
    const path = props.id.split(idSep);
    const { object: fieldParent, key } = getSchemaFromSchemaPath(
      schemaContext,
      path
    );
    const fieldNamePrefix = key;
    let exists = 0;
    while (
      fieldParent.properties[
        `${fieldNamePrefix}${exists > 0 ? " (" + exists + ")" : ""}`
      ]
    ) {
      exists++;
    }
    const newKey = `${fieldNamePrefix}${exists > 0 ? " (" + exists + ")" : ""}`;
    const internalType = internalSchemaTypeFromSchema(props.schema);
    schemaUpdateContext.updateSchema({
      key: newKey,
      id: path.slice(0,-1).join(idSep) + idSep, //remove last element of path and create the id with idSep between every level
      type: props.schema.type,
      format: props.schema.format,
      def: defaultValuesFromType[internalType],
      desc: props.schema.description,
      properties: props.schema.properties,
      oneOf: props.schema.oneOf ? props.schema.oneOf : undefined,
    });
    path[path.length - 1] = newKey;
    setCurrentOpenField(path.join("_"));
  };
  function deleteField() {
    schemaUpdateContext.deleteSchema(props.id);
  }
  const { label, description, children, schema, id } = props;

  // Contains the current Key of the open field (the ID)
  // This is used to identify this schema
  // And is used to determine if the field is open or not
  const [key, setKey] = useState(label);

  const [desc, setDescription] = useState(description.props.description);
  const [internalType, setInternalType] = useState<InternalSchemaTypes>(
    internalSchemaTypeFromSchema(schema)
  );

  const [type, setType] = useState(schema.type);
  const [format, setFormat] = useState(schema.format);
  const [isSwitchOpen, setIsSwitchOpen] = useState(
    type === "string" &&
      format !== undefined &&
      format !== "color" &&
      format !== "text"
  );
  const [switchState, setSwitchState] = useState(schema.oneOf ? true : false);
  const [values, setValues] = useState(schema.oneOf ? schema.oneOf : []);
  const schemaUpdateContext = useContext(SchemaUpdateContext);

  useEffect(() => {
    const it = internalSchemaTypeFromSchema(schema);
    setFormat(schema.format);
    setType(schema.type);
    setInternalType(it);
  }, [schema.type, schema.format]);
  function saveSchema() {
    schemaUpdateContext.updateSchema({
      id,
      key,
      desc,
      type,
      format,
      properties: schema.properties,
      def: defaultValuesFromType[internalType],
      oneOf: switchState ? values : undefined,
    });
  }
  useEffect(() => {
    if (
      type === "string" &&
      format !== undefined &&
      format !== "color" &&
      format !== "text"
    ) {
      setIsSwitchOpen(true);
    } else {
      setIsSwitchOpen(false);
      setSwitchState(false);
    }
    if (schema.format !== format || schema.type !== type) saveSchema();
  }, [type, format]);
  useEffect(() => {
    //update and do not change the key
    schemaUpdateContext.updateSchema({
      id,
      key: id.split(idSep)[id.split(idSep).length - 1],
      desc,
      type,
      format,
      properties: schema.properties,
      def: defaultValuesFromType[internalType],
      oneOf: switchState ? values : undefined,
    });
  }, [switchState, values]);
  useEffect(() => {
    const validValues: any = [];
    values.forEach(val => {
      if (val && (val as any).title) {
        validValues.push(val);
      }
    });
    setValues(validValues);
    saveSchema();
  }, [currentOpenField]);
  return (
    <div className={styles.FullEditField}>
      <div className={styles.FullEditFieldContent}>
        <Space direction="vertical" size={"small"}>
          <span className={styles.Bold}>Data type</span>
          <Form.Item
            className={styles.Form}
            tooltip={
              fieldDescription && fieldDescription.dataType
                ? {
                    title: fieldDescription.dataType,
                    icon: <QuestionCircleTwoTone twoToneColor={"#374146"} />,
                  }
                : null
            }
          >
            <Select
              className={styles.Select}
              listHeight={300}
              style={{ width: "10rem" }}
              placeholder="Type"
              // @ts-ignore
              onSelect={value => {
                // Setting the open field key before changing the type we maintain field open
                const path = props.id.split(idSep);
                path[path.length - 1] = key;
                setCurrentOpenField(path.join(idSep));

                // Set the new type of this field (Text, color, number, etc...)
                setInternalType(value as InternalSchemaTypes);
                const { type, format } = typeAndFormatFromInternalType(
                  value as InternalSchemaTypes
                );
                setType(type as JSONSchema7TypeName);
                setFormat(format);
                if (format === "color")
                  schemaUpdateContext.setFormDataValue(
                    id,
                    defaultValuesFromType[value as InternalSchemaTypes]
                  );
              }}
              value={internalType}
            >
              {selectOptionsFromObject(SCHEMA_TYPES_STRUCTURE)}
            </Select>
          </Form.Item>
        </Space>
        {isSwitchOpen && (
          <div>
            <Space size={15}>
              <div className={styles.SwitchOption}>
                <span
                  className={switchState ? styles.Unselected : styles.Selected}
                >
                  Single option
                </span>
              </div>
              <Switch
                className={switchState ? styles.SwitchOn : styles.SwitchOff}
                defaultChecked={switchState}
                onChange={e => {
                  !switchState &&
                    !values.length &&
                    setValues([
                      {
                        title: "",
                        const: children.props.children[0].props.formData,
                      },
                      {
                        title: "",
                        const: "",
                      },
                    ]);
                  setSwitchState(e);
                }}
              />
              <Space direction="horizontal" size={"small"}>
                <div className={styles.SwitchOption}>
                  <span
                    className={
                      switchState ? styles.Selected : styles.Unselected
                    }
                  >
                    List of options
                  </span>
                </div>
                <Tooltip title="you can add list of options with names to dropdown.">
                  <QuestionCircleTwoTone twoToneColor={"#374146"} />
                </Tooltip>
              </Space>
            </Space>
          </div>
        )}

        {isSwitchOpen && (
          <>
            <br />
            <Divider className={styles.Divider} />
          </>
        )}
        <div style={{ width: "100%" }}>
          <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
            <span className={styles.Bold}>
              {`Key  `}
              <Tooltip
                title={
                  <TextArea
                    style={{
                      backgroundColor: "var(--blings_that_gray)",
                      resize: "none",
                      color: "white",
                    }}
                    className={styles.DescInput}
                    value={desc}
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Add a description"
                  />
                }
              >
                <QuestionCircleTwoTone twoToneColor={"#374146"} />
              </Tooltip>
            </span>

            <Form.Item className={styles.Form}>
              <Input
                value={key}
                onChange={e => setKey(e.target.value)}
                placeholder="Enter the key's name"
              />
            </Form.Item>
            {(switchState && (
              <Space
                direction="vertical"
                size={"small"}
                style={{ width: "100%" }}
              >
                <span className={styles.Bold}>List of options</span>
                <div id="AddOptionsForm" style={{ width: "100%" }}>
                  <AddOptionsForm
                    setValuesInForm={setValues}
                    ValuesInForm={values}
                    format={format}
                    container={document.getElementById("AddOptionsForm")}
                  />
                </div>
              </Space>
            )) || (
              <Space
                direction="vertical"
                size={"small"}
                style={{ width: "100%" }}
              >
                <span className={styles.Bold}>Value</span>
                <Form.Item
                  className={styles.Form}
                  tooltip={
                    fieldDescription && fieldDescription.value
                      ? {
                          title: fieldDescription.value,
                          icon: (
                            <QuestionCircleTwoTone twoToneColor={"#374146"} />
                          ),
                        }
                      : null
                  }
                >
                  <FieldStateContext.Provider value={FieldState.FULL_EDIT}>
                    {children}
                  </FieldStateContext.Provider>
                </Form.Item>
              </Space>
            )}
          </Space>
        </div>
      </div>

      <div className={styles.Footer}>
        <div className={styles.Right}>
          <Button className={styles.IconBtn} onClick={duplicateField}>
            <CopyIcon size={2} />
          </Button>
          <Button className={styles.IconBtn} onClick={deleteField}>
            <DeleteIcon size={2} />
          </Button>
        </div>
        <Button
          className={styles.Button}
          disabled={!key}
          type="primary"
          onClick={() => setCurrentOpenField("none")}
        >
          Done
        </Button>
      </div>
    </div>
  );
};
