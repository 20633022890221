/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://0zzznrvp6f.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "addPubSubPermission",
            "endpoint": "https://tath820876.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "extension",
            "endpoint": "https://uex3ezdcog.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "platformRest",
            "endpoint": "https://zcxzhdzd3d.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "projectsRestaApi",
            "endpoint": "https://w5erij9aaf.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "uploadCsvFile",
            "endpoint": "https://swdg0qobp0.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://zklv7uqixfbfdi2g3bqegxofqy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:db03db1c-11f0-4fc2-9682-d77646539369",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_XVo9FCZoE",
    "aws_user_pools_web_client_id": "6je83o0jnuirmas5fohbt4cu8k",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "platform4-hostingbucket-dev-staging",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://platform4-hostingbucket-dev-staging.s3-website-eu-west-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Config-staging",
            "region": "eu-west-1"
        },
        {
            "tableName": "SourceDataRecords-staging",
            "region": "eu-west-1"
        },
        {
            "tableName": "Versions-staging",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "platform-storage-bucket-staging",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
