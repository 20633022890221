import React, { useContext } from "react";
import { Input } from "antd";
import { WidgetProps } from "@rjsf/core";
import { FieldState, FieldStateContext } from "../FieldTemplate";
import styles from "./MediaWidget.module.scss";
import MediaContent from "../components/MediaContent";
import UploadButton from "../components/UploadButton";
import { UPLOADING } from "../components/consts";
const { TextArea } = Input;

export const MediaWidget = (props: WidgetProps) => {
  const { value, schema } = props;
  const fieldState = useContext(FieldStateContext);

  const format = schema.format as string;
  return (
    <div
      className={[
        styles.MediaWidget,
        fieldState === FieldState.FULL_EDIT ? styles.Edit : "",
      ].join(" ")}
    >
      {fieldState === FieldState.READ_ONLY ? (
        <span className={styles.ValueText}>
          <a href={value} target="_blank">
            {value}
          </a>
        </span>
      ) : (
        <div className={styles.ValueField}>
          <div className={styles.ValueFieldTextContainer}>
            <TextArea
              className={[styles.ValueText, styles.Input].join(" ")}
              onChange={event =>
                props.onChange(event.target.value || undefined)
              }
              placeholder={props.placeholder || "add media url"}
              value={value || ""}
              autoSize={{ minRows: 1, maxRows: 3 }}
            />
            <UploadButton
              format={format}
              index={name}
              value={value}
              setValue={props.onChange}
              urlFunction={(fileUrl: string) => {
                props.onChange(fileUrl);
              }}
            />
          </div>
        </div>
      )}
      {value && !value.startsWith(UPLOADING) && (
        <MediaContent src={value} format={format} />
      )}
    </div>
  );
};
