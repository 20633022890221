import { AsyncOpState } from "../../types/enums/async-op-states";
import { UpdateProjectMutation, UpdateProjectVersionMutation } from "../../API";
import { API, graphqlOperation } from "aws-amplify";
import { updateProject, updateProjectVersion } from "../../graphql/mutations";
import { INITIAL_ASYNC_OP_STATE } from "../../types/mst/async-op-state";
import { IProjectBaseModel } from "./projectModel";
import { updateVersion } from "../../utils/projectTools";

// type x = Pick<IProjectModel, 'model'>

export const saveSchemasActions = (self: IProjectBaseModel) => ({
  updateLiveControlData(newSettings: any) {
    self.settings = newSettings;
  },
  updateLiveControlSchema(newSchema: any) {
    self.settingsJsonSchemaStr = JSON.stringify(newSchema);
  },
  updatePerVideoSchema(newSchema: any) {
    self.stateJsonSchemaStr = JSON.stringify(newSchema);
  },
  async savePerVideoData(newSchema: any) {
    const beforeSchema = self.stateJsonSchemaStr;
    this.updatePerVideoSchema(newSchema);
    this.changeSavePerVideoDataStatus(AsyncOpState.Saving);
    try {
      const input = {
        id: self.id,
        stateJsonSchemaStr: JSON.stringify(newSchema),
      };
      const resp = (await API.graphql(
        graphqlOperation(updateProject, { input })
      )) as { data: UpdateProjectMutation };
      const respVersion = await updateVersion(input);


      if (resp.data.updateProject && respVersion.data) {
        this.savePerVideoDataSuccess();
      } else {
        throw new Error("");
      }
    } catch (e) {
      this.changeSavePerVideoDataStatus(AsyncOpState.Error);
      setTimeout(() => {
        this.changeSavePerVideoDataStatus(INITIAL_ASYNC_OP_STATE);
        this.updatePerVideoSchema(JSON.stringify(beforeSchema));
      }, 3000);
    }
  },
  async saveLiveControl(newData: any, newSchema: any) {
    const beforeSettings = JSON.stringify(self.settings);
    const beforeSchema = self.settingsJsonSchemaStr;
    this.updateLiveControlData(newData);
    if (newSchema) this.updateLiveControlSchema(newSchema);
    this.changeSaveLiveControlStatus(AsyncOpState.Saving);
    try {
      const input = {
        id: self.id,
        settings: JSON.stringify(newData),
        ...(newSchema && { settingsJsonSchemaStr: JSON.stringify(newSchema) }),
      };
      const resp = (await API.graphql(
        graphqlOperation(updateProject, { input })
      )) as { data: UpdateProjectMutation };
      const respVersion = await updateVersion({ ...input })
      if (resp.data.updateProject && respVersion.data) {
        this.saveLiveControlSuccess();
      } else {
        throw new Error("");
      }
    } catch (e) {
      this.changeSaveLiveControlStatus(AsyncOpState.Error);
      setTimeout(() => {
        this.changeSaveLiveControlStatus(INITIAL_ASYNC_OP_STATE);
        this.updateLiveControlData(JSON.parse(beforeSettings));
        this.updateLiveControlSchema(JSON.stringify(beforeSchema));
      }, 3000);
    }
  },
  saveLiveControlSuccess() {
    this.changeSaveLiveControlStatus(AsyncOpState.Success);
    setTimeout(() => {
      this.changeSaveLiveControlStatus(INITIAL_ASYNC_OP_STATE);
    }, 3000);
  },
  savePerVideoDataSuccess() {
    this.changeSavePerVideoDataStatus(AsyncOpState.Success);
    setTimeout(() => {
      this.changeSavePerVideoDataStatus(INITIAL_ASYNC_OP_STATE);
    }, 3000);
  },
  changeSaveLiveControlStatus(newStatus: AsyncOpState) {
    self.saveLiveControlStatus = newStatus;
  },
  changeSavePerVideoDataStatus(newStatus: AsyncOpState) {
    self.savePerVideoDataStatus = newStatus;
  },
});