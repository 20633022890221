import { Button, Modal, Select } from "antd";
import { useState } from "react";
import { useMst } from "../../../../stores/Root";
import { IFileUploadModel } from "../../../../stores/account";
import { IProjectModel } from "../../../../stores/project/projectModel";
import { IaccountModel } from "../../../../stores/account";
import { IuploadDataModel } from "../../../../stores/uploadData";
import UploadNewFile from "../../../DataUploads/UploadNewFile";
import { LandingPageIntegration } from "./LandingPageIntegration/LandingPageIntegration";
import { IntegrationLink } from "./IntegrationLink/IntegrationLink";
import { renderUploadTime } from "../../../DataUploads/DataUploadsTable";
import "./CsvIntegration.scss";
import { observer } from "mobx-react-lite";

type Props = {
  project: IProjectModel;
  state: {
    alias: string;
    scenes: Array<string>;
    title: string;
    description: string;
    thumbnail: string;
    favicon: string;
  };
  setState: React.Dispatch<React.SetStateAction<{
    alias: string;
    scenes: Array<string>;
    title: string;
    description: string;
    thumbnail: string;
    favicon: string;
  }>>;
};
export const CsvIntegration = (props: Props) => {
  const { project, state, setState } = props;
  return <div className="CsvIntegration">
    <LandingPageIntegration project={project} state={state} setState={setState} />
    <DataIntegration projectAliasId={state.alias} />
  </div>;
};

type DataIntegrationProps = {
  projectAliasId: string;
};
const DataIntegration = observer((props: DataIntegrationProps) => {
  const state: {
    fileUploads: Array<IFileUploadModel>;
    projects: Array<IProjectModel>;
    accountStore: IaccountModel;
    uploadDataStore: IuploadDataModel;
    downloadExampleCSV: () => void;
    selectedCsvFileName: string | null;
    saveDataSource: (filename: string | null) => void;
  } = useMst((store) => ({
    fileUploads: store.accountStore.fileUploads || [],
    projects: store.projectsStore.projects,
    accountStore: store.accountStore,
    uploadDataStore: store.uploadDataStore,
    saveDataSource: store.projectsStore.selectedProject?.saveFileName,
    downloadExampleCSV: store.projectsStore.selectedProject?.downloadExampleCSV,
    selectedCsvFileName: store.projectsStore.selectedProject?.fileName
  }));
  const [uploadedFileName, setUploadedFileName] = useState<string>("");
  const { projectAliasId } = props;
  const { fileUploads, projects, accountStore, uploadDataStore, downloadExampleCSV, saveDataSource, selectedCsvFileName } = state;
  const [selected, setSelected] = useState<number | null>(fileUploads.findIndex((file: IFileUploadModel) => file.fileName === selectedCsvFileName));
  const fileProjectMap = projects.reduce<{ [fileName: string]: Array<string>; }>((acc, project) => {
    const { fileName } = project;
    if (!fileName) return acc;
    acc[fileName] = acc[fileName] || [];
    acc[fileName].push(project.title);
    return acc;
  }, {});
  const options = [
    { label: "Upload a new CSV", value: -1 },
    {
      label: "USE EXISTING CSV", options:
        fileUploads.map((fu, i) => {
          const fourCharsID = fu.fileName.slice(0, 4);
          const time = renderUploadTime(fu.createdAt);
          const name = fu.originalName || "";
          const details = `${time} [#${fourCharsID}]`;
          return ({
            label: <div
              title={`${name} - ${details}`}
              key={fu.fileName}
            >
              <span className="fontW700">{name} </span>
              {details}
              {fileProjectMap[fu.fileName] ? <span>{fileProjectMap[fu.fileName].join(",")}</span> : null}
            </div>,
            value: i,
          }

          );
        })

    },
  ];
  return <div className="DataIntegration">
    <div>
      <h1 className="page-subtitle">2. DATA INTEGRATION</h1>
      <Select options={options} value={selected} onChange={e => setSelected(e)} style={{ minWidth: "600px" }} />
      <Modal open={selected === -1} okButtonProps={{ disabled: uploadedFileName === "" }} onOk={
        () => setSelected(fileUploads.findIndex((file: IFileUploadModel) => {
          return file.fileName === uploadedFileName;
        }))}>
        <h2>Upload a new CSV</h2>
        <UploadNewFile uploadData={uploadDataStore}
          onUpload={(fileName) => { accountStore.loadAccountData(); setUploadedFileName(fileName); }} />
      </Modal>
      <Button disabled={selected === null} onClick={() => saveDataSource(fileUploads[selected as number].fileName)}>Save</Button>
      <a onClick={downloadExampleCSV}>Download example csv</a>
      <IntegrationLink accountAliasId={accountStore.accountSettings?.aliasId || ""} projectAliasId={projectAliasId} />
    </div>
    <div>
      <video autoPlay src="https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761"></video>
    </div>
  </div>;
});