import React from "react";
import { Checkbox } from "antd";
import { useContext, useState } from "react";
import { WidgetProps } from "@rjsf/core";
import { FieldState, FieldStateContext } from "../FieldTemplate";
import "./CheckboxWidget.scss";
import { getValue } from ".";

export const CheckboxWidget = (props: WidgetProps) => {
  const fieldState = useContext(FieldStateContext);
  const defVal = getValue(props);
  const [checked, setChecked] = useState<boolean>(defVal);
  return (
    <Checkbox
      className="CheckboxWidget widget"
      checked={checked}
      onChange={event => {
        setChecked(!checked);
        props.onChange(event.target.checked);
      }}
      disabled={fieldState === FieldState.READ_ONLY}
    />
  );
};
