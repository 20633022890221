import { BarTooltipProps } from "@nivo/bar";
import { PointTooltipProps } from "@nivo/line";
import { PieTooltipProps } from "@nivo/pie";
export function TooltipLine({ point }: PointTooltipProps) {
  return (
    <div
      style={{
        backgroundColor: "var(--blings_teal)",
        color: "white",
        padding: ".5rem",
        borderRadius: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <div style={{ backgroundColor: "white", width: "1rem", height: "1rem", border: "1px solid white", marginRight: "0.5rem" }}><div style={{ backgroundColor: point.color, height: "100%", width: "100%" }}></div></div> */}
      {new Date(point.data.xFormatted).toLocaleDateString(navigator.language, {
        month: "short",
        day: "numeric",
      })}
      : {point.data.y}
      <div
        style={{
          backgroundColor: "#374146",
          position: "absolute",
          top: "75%",
          transform: "rotate(45deg)",
          width: "1rem",
          height: "1rem",
        }}
      ></div>
    </div>
  );
}
export function TooltipLineNoFormat({ point }: PointTooltipProps) {
  return (
    <div
      style={{
        backgroundColor: "var(--blings_teal)",
        color: "white",
        padding: ".5rem",
        borderRadius: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <div style={{ backgroundColor: "white", width: "1rem", height: "1rem", border: "1px solid white", marginRight: "0.5rem" }}><div style={{ backgroundColor: point.color, height: "100%", width: "100%" }}></div></div> */}
      {point.data.xFormatted}: {point.data.y}
      <div
        style={{
          backgroundColor: "#374146",
          position: "absolute",
          top: "75%",
          transform: "rotate(45deg)",
          width: "1rem",
          height: "1rem",
        }}
      ></div>
    </div>
  );
}

export function TooltipPie({
  datum,
}: PieTooltipProps<{
  id: string;
  value: number;
  percentage: string;
}>) {
  return (
    <div
      style={{
        backgroundColor: "var(--blings_teal)",
        color: "white",
        padding: ".5rem",
        borderRadius: "5px",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "1rem",
          height: "1rem",
          border: "1px solid white",
          marginRight: "0.5rem",
        }}
      >
        <div
          style={{
            backgroundColor: datum.color,
            height: "100%",
            width: "100%",
          }}
        ></div>
      </div>
      {datum.data.id}: {`${datum.data.value} (${datum.data.percentage}%)`}
      <div
        style={{
          backgroundColor: "#374146",
          position: "absolute",
          top: "75%",
          transform: "rotate(45deg)",
          width: "1rem",
          height: "1rem",
        }}
      ></div>
    </div>
  );
}

export function TooltipFunnel({
  data,
}: BarTooltipProps<{
  key: string;
  events: number;
  type: string;
  percentage: number;
}>) {
  return <div style={{ backgroundColor: "var(--blings_teal)", color: "white", padding: ".5rem", borderRadius: "5px", position: "relative", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
    {/* <div style={{ backgroundColor: "white", width: "1rem", height: "1rem", border: "1px solid white", marginRight: "0.5rem" }}><div style={{ backgroundColor: color, height: "100%", width: "100%" }}></div></div> */}
    {`${data.percentage.toLocaleString()}% (${data.events.toLocaleString()})`}
    <div style={{ backgroundColor: "#374146", position: "absolute", top: "75%", transform: "rotate(45deg)", width: "1rem", height: "1rem" }}></div>
  </div>;
}
