
const awsmobile = {
  "aws_project_region": "eu-west-1",
  "aws_cognito_identity_pool_id": "eu-west-1:0bc7c9b2-8171-44b5-9b71-90244a3175a6",
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_0kZbFWMIr",
  "aws_user_pools_web_client_id": "1pcnq1pb8s975c1nokahlirb4j",
  "oauth": {},
  "aws_appsync_graphqlEndpoint": "https://7ipvqfvqivgn5kjqyojjbokwaa.appsync-api.eu-west-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-west-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  'aws_appsync_apiKey': 'da2-pbsobpat5nazlcppeqs5p4au4u',
};


export default awsmobile;
