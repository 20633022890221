import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { FieldTemplateProps } from "@rjsf/core";
import { CurrentOpenFieldContext } from "./DynamicFormContext";

import { FullEditField } from "./components/FullEditField";
import { BaseField } from "./components/BaseField";

import styles from "./FieldTemplate.module.scss";

export enum FieldState {
  READ_ONLY = "readOnly",
  EDIT = "edit",
  FULL_EDIT = "fullEdit",
}
export const FieldStateContext = createContext<FieldState>(
  FieldState.READ_ONLY
);

export const FieldTemplate = (props: FieldTemplateProps) => {
  const { children, id } = props;
  const [currentOpenField] = useContext(CurrentOpenFieldContext);
  const [currentState, setCurrentState] = useState(FieldState.READ_ONLY);
  useEffect(() => {
    const state =
      currentOpenField === ""
        ? FieldState.READ_ONLY
        : currentOpenField === id
        ? FieldState.FULL_EDIT
        : FieldState.EDIT;
    if (state !== currentState) setCurrentState(state);
  }, [currentOpenField, props.schema /* formData */]);

  return (
    <div
      className={[styles.FieldTemplate, props.schema.type?.toString()].join(
        " "
      )}
    >
      {currentState === FieldState.READ_ONLY ? (
        <BaseField {...props} edit={false}>
          {children}
        </BaseField>
      ) : currentState === FieldState.EDIT ? (
        <BaseField {...props} edit={true}>
          {children}
        </BaseField>
      ) : (
        <FullEditField {...props}>{children}</FullEditField>
      )}
    </div>
  );
};
