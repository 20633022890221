import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { RenderFunction, TooltipPlacement } from "antd/lib/tooltip";
type Props = {
  content: React.ReactNode | RenderFunction;
  className?: string;
  style?: React.CSSProperties;
  placement?: TooltipPlacement;
};
export default (props: Props) => {
  const { content, style } = props;
  return (<Popover content={content} placement={props.placement} className={props.className}>
    <QuestionCircleOutlined style={{ margin: "5px", ...style }} />
  </Popover>);
};
