/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateProject = /* GraphQL */ `
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      projectAccountId
      title
      account {
        id
        gid
        name
        aliasId
        createdAt
        updatedAt
      }
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      allowCreateDataPoint
      allowDataConnect
      analyticsReportUrl
      createdAt
      dataFiles {
        fileName
        tag
        createdAt
      }
      description
      fileName
      jsonChanges
      jsonVidUrl
      playerSettings {
        posterFrame
        showTimeline
        storiesMode
        color_loader
        color_ctrlBtns
        color_rail
        color_progress
        color_thumb
        color_bg
        muted
        autoplay
        autoReplay
        showBlingsLogo
      }
      projectVersions {
        nextToken
      }
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      analyticsEnabled
      owner
      renders {
        nextToken
      }
      notifications {
        nextToken
      }
      aliasId
      minisiteConfigs {
        scenes
        title
        description
        thumbUrl
        faviconUrl
      }
      playerVersionToUse
      customHtml
      updatedAt
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      gid
      name
      projects {
        nextToken
      }
      fileuploads {
        originalName
        createdAt
        fileName
        fileStatus
        fileError
        numRecords
        numErrors
        totalRecords
        writtenRecords
      }
      integrations {
        hubspot
      }
      aliasId
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      gid
      name
      projects {
        nextToken
      }
      fileuploads {
        originalName
        createdAt
        fileName
        fileStatus
        fileError
        numRecords
        numErrors
        totalRecords
        writtenRecords
      }
      integrations {
        hubspot
      }
      aliasId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      gid
      name
      projects {
        nextToken
      }
      fileuploads {
        originalName
        createdAt
        fileName
        fileStatus
        fileError
        numRecords
        numErrors
        totalRecords
        writtenRecords
      }
      integrations {
        hubspot
      }
      aliasId
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      subType
      status
      title
      message
      read
      owner
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      createdAt
      extra
      updatedAt
      projectNotificationsId
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      subType
      status
      title
      message
      read
      owner
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      createdAt
      extra
      updatedAt
      projectNotificationsId
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      subType
      status
      title
      message
      read
      owner
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      createdAt
      extra
      updatedAt
      projectNotificationsId
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      projectAccountId
      title
      account {
        id
        gid
        name
        aliasId
        createdAt
        updatedAt
      }
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      allowCreateDataPoint
      allowDataConnect
      analyticsReportUrl
      createdAt
      dataFiles {
        fileName
        tag
        createdAt
      }
      description
      fileName
      jsonChanges
      jsonVidUrl
      playerSettings {
        posterFrame
        showTimeline
        storiesMode
        color_loader
        color_ctrlBtns
        color_rail
        color_progress
        color_thumb
        color_bg
        muted
        autoplay
        autoReplay
        showBlingsLogo
      }
      projectVersions {
        nextToken
      }
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      analyticsEnabled
      owner
      renders {
        nextToken
      }
      notifications {
        nextToken
      }
      aliasId
      minisiteConfigs {
        scenes
        title
        description
        thumbUrl
        faviconUrl
      }
      playerVersionToUse
      customHtml
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      projectAccountId
      title
      account {
        id
        gid
        name
        aliasId
        createdAt
        updatedAt
      }
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      allowCreateDataPoint
      allowDataConnect
      analyticsReportUrl
      createdAt
      dataFiles {
        fileName
        tag
        createdAt
      }
      description
      fileName
      jsonChanges
      jsonVidUrl
      playerSettings {
        posterFrame
        showTimeline
        storiesMode
        color_loader
        color_ctrlBtns
        color_rail
        color_progress
        color_thumb
        color_bg
        muted
        autoplay
        autoReplay
        showBlingsLogo
      }
      projectVersions {
        nextToken
      }
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      analyticsEnabled
      owner
      renders {
        nextToken
      }
      notifications {
        nextToken
      }
      aliasId
      minisiteConfigs {
        scenes
        title
        description
        thumbUrl
        faviconUrl
      }
      playerVersionToUse
      customHtml
      updatedAt
    }
  }
`;
export const createProjectVersion = /* GraphQL */ `
  mutation CreateProjectVersion(
    $input: CreateProjectVersionInput!
    $condition: ModelProjectVersionConditionInput
  ) {
    createProjectVersion(input: $input, condition: $condition) {
      id
      accountOwner
      owner
      stateJsonSchemaStr
      settingsJsonSchemaStr
      settings
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      publishedProject {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      updatedAt
      playerVersionToUse
      createdAt
      projectProjectVersionsId
    }
  }
`;
export const updateProjectVersion = /* GraphQL */ `
  mutation UpdateProjectVersion(
    $input: UpdateProjectVersionInput!
    $condition: ModelProjectVersionConditionInput
  ) {
    updateProjectVersion(input: $input, condition: $condition) {
      id
      accountOwner
      owner
      stateJsonSchemaStr
      settingsJsonSchemaStr
      settings
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      publishedProject {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      updatedAt
      playerVersionToUse
      createdAt
      projectProjectVersionsId
    }
  }
`;
export const deleteProjectVersion = /* GraphQL */ `
  mutation DeleteProjectVersion(
    $input: DeleteProjectVersionInput!
    $condition: ModelProjectVersionConditionInput
  ) {
    deleteProjectVersion(input: $input, condition: $condition) {
      id
      accountOwner
      owner
      stateJsonSchemaStr
      settingsJsonSchemaStr
      settings
      videoParts {
        name
        jsonUrl
        mods
        updatedAt
        hasVideo
      }
      publishedProject {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      updatedAt
      playerVersionToUse
      createdAt
      projectProjectVersionsId
    }
  }
`;
export const createServerRender = /* GraphQL */ `
  mutation CreateServerRender(
    $input: CreateServerRenderInput!
    $condition: ModelServerRenderConditionInput
  ) {
    createServerRender(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      scenes
      data
      env
      outputFileName
      quality
      frameToStart
      frameToEnd
      format
      status {
        ready
        status
        downloadLink
        code
      }
      cognitoUserIdentifier
      cognitoGroupOwner
      createdAt
      updatedAt
    }
  }
`;
export const updateServerRender = /* GraphQL */ `
  mutation UpdateServerRender(
    $input: UpdateServerRenderInput!
    $condition: ModelServerRenderConditionInput
  ) {
    updateServerRender(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      scenes
      data
      env
      outputFileName
      quality
      frameToStart
      frameToEnd
      format
      status {
        ready
        status
        downloadLink
        code
      }
      cognitoUserIdentifier
      cognitoGroupOwner
      createdAt
      updatedAt
    }
  }
`;
export const deleteServerRender = /* GraphQL */ `
  mutation DeleteServerRender(
    $input: DeleteServerRenderInput!
    $condition: ModelServerRenderConditionInput
  ) {
    deleteServerRender(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        projectAccountId
        title
        allowCreateDataPoint
        allowDataConnect
        analyticsReportUrl
        createdAt
        description
        fileName
        jsonChanges
        jsonVidUrl
        settings
        stateJsonSchemaStr
        settingsJsonSchemaStr
        thumbS3Url
        analyticsEnabled
        owner
        aliasId
        playerVersionToUse
        customHtml
        updatedAt
      }
      scenes
      data
      env
      outputFileName
      quality
      frameToStart
      frameToEnd
      format
      status {
        ready
        status
        downloadLink
        code
      }
      cognitoUserIdentifier
      cognitoGroupOwner
      createdAt
      updatedAt
    }
  }
`;
export const sendInstantNotification = /* GraphQL */ `
  mutation SendInstantNotification($input: SendInstantNotificationInput) {
    sendInstantNotification(input: $input) {
      type
      subType
      status
      title
      message
      owner
      projectId
      extra
    }
  }
`;
