import React from "react";
import "./BlingsButton.scss";

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  children: string | JSX.Element;
  disabled?: boolean;
  type?: "submit";
  className?: string;
  outline?: boolean;
};

export const BlingsButton = ({
  onClick,
  children,
  disabled,
  type,
  className,
  outline = false,
}: Props) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={[
        "BlingsButton",
        disabled ? "Disabled" : "",
        className ? className : "",
        outline ? "Outlined" : "",
      ].join(" ")}
    >
      {children}
    </button>
  );
};
