import {
  COMMON_PATHS,
  findLayerOrLayerAsset,
  getPath,
} from "@blings/blings-player";
import {
  IProjectModel,
  IVidPartModel,
  mainAssetKey,
  PLATFORM_TEXT_CONTROL,
} from "../stores/project/projectModel";
import { Layer, TextLayer } from "../types/LayerTypes";
type Additional = { assetId?: string; layerName: string };

const getTextFromDataStr = (dataStr: any, settings: any) => {
  const defaultValue = dataStr?.defaultValue;
  const liveControlKey = dataStr?.liveControlKey;
  // If the settings object has nested object properties
  let currentNested: any = null; //Object until it becomes a string
  if (liveControlKey?.includes(".")) {
    const nestedProperties = liveControlKey.split(".");
    for (const currentProperty of nestedProperties) {
      currentNested = currentNested
        ? currentNested?.[currentProperty]
        : (currentNested = settings?.[currentProperty]); //If we are at the first level, get from settings, else go deep into object
    }
  }
  return (
    currentNested ||
    settings?.[liveControlKey] ||
    dataStr.value ||
    defaultValue ||
    ""
  );
};
/**
 * This method goes through the mods array and separates the dynamic and static mods
 * @param modsArr
 */
const separateMods = (
  modsArr: IVidPartModel["modsArr"],
  initialModsArr: IVidPartModel["modsArr"],
  settings: any
) => {
  const dynamicMod: string[] = [];
  const staticMod: { text: string; layerName: string }[] = [];
  const initialStaticMod: { text: string; layerName: string }[] = [];
  // Go through all the mods and separate them into dynamic and static mods
  modsArr?.forEach((mod) => {
    const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
    // If this mod is static
    if (dataStr?.liveControlKey || mod?.origin === PLATFORM_TEXT_CONTROL) {
      // Get the initial mods that are the latest ones saved in the db
      const textFromDataStr = getTextFromDataStr(dataStr, settings);
      staticMod.push({
        layerName: dataStr.layerName,
        text: textFromDataStr,
      });
      // Goes through the additional layers and updates staticMod accordingly
      dataStr?.additionals?.forEach((additional: Additional) => {
        staticMod.push({
          layerName: additional.layerName,
          text: textFromDataStr,
        });
      });
    } else {
      // If this mod is Dynamic
      dynamicMod.push(dataStr.layerName);
      dataStr?.additionals?.forEach((additional: Additional) =>
        dynamicMod.push(additional.layerName)
      );
    }
  });
  // Go through all the initial mods which are the latest ones saved in the db
  initialModsArr?.forEach((mod) => {
    const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
    // If this mod is static
    if (dataStr?.liveControlKey || mod?.origin === PLATFORM_TEXT_CONTROL) {
      // Get the initial mods that are the latest ones saved in the db
      const textFromDataStr = getTextFromDataStr(dataStr, settings);

      initialStaticMod.push({
        layerName: dataStr.layerName,
        text: textFromDataStr,
      });
      // Goes through the additional layers and updates staticMod accordingly
      dataStr?.additionals?.forEach((additional: Additional) => {
        initialStaticMod.push({
          layerName: additional.layerName,
          text: textFromDataStr,
        });
      });
    }
  });
  return { staticMod, initialStaticMod, dynamicMod };
};

// Gets all text layers from the currentScene
export const getTextLayers = (
  currentScene: IVidPartModel | undefined,
  project: IProjectModel
) => {
  const allStaticTextLayers: TextLayer[] = []; // Text layers without connectors, or with live-control connectors, or with origin='platform...'
  const allDynamicTextLayers: TextLayer[] = [];
  if (!currentScene) return { allDynamicTextLayers, allStaticTextLayers };
  // If this scene has existing mods, separate them into dynamic and static layers
  const { staticMod, initialStaticMod, dynamicMod } = separateMods(
    currentScene.modsArr,
    currentScene.initialModsArr,
    project?.settings
  );

  if (currentScene?.assetLayerPairs) {
    // Goes through all the layers in the scene and checks if they are text layers
    Array.from(currentScene.assetLayerPairs).forEach((assetLayerPair: any) => {
      assetLayerPair?.[1]?.forEach((currentLayer: string) => {
        const assetId: string | undefined =
          assetLayerPair[0] === mainAssetKey ? undefined : assetLayerPair[0];
        const layer = findLayerOrLayerAsset({
          assetId,
          layerName: currentLayer,
          jsonVid: currentScene?.jsonData,
        });
        const text: string = getPath(layer, COMMON_PATHS.TEXT_IN_LAYER); // If the layer has text
        const data = {
          layerData: layer,
          text,
          asset: assetId,
        };
        // If this layer is not connected to a dynamic connector
        if (!dynamicMod.includes(currentLayer) && text) {
          const liveControl = staticMod.find(
            (a) => a.layerName === currentLayer
          );
          const initialLiveControl = initialStaticMod.find(
            (a) => a.layerName === currentLayer
          );
          // If the current layer has a live control. Get the text from live control in addition to the original text
          liveControl || initialLiveControl
            ? allStaticTextLayers.push({
                ...data,
                controlledText: liveControl?.text || initialLiveControl?.text, //If connector has been deleted, get the initial one
                ...(initialLiveControl && {
                  initialControlledText: initialLiveControl.text,
                }),
              })
            : allStaticTextLayers.push(data);
        } else {
          text && allDynamicTextLayers.push(data);
        }
      });
    });
  }
  return { allStaticTextLayers, allDynamicTextLayers };
};

/**
 *
 * @param allLayers
 * @param layerTypes types of layers that will be processed
 * @param sceneName
 * @param filter
 * @returns an object that contains all the types of layers requested already filtered
 */
export const getLayersFromSceneHelper = (
  allLayers: Layer[],
  layerTypes: ("allDynamicTextLayers" | "allStaticTextLayers")[],
  sceneName: string,
  filter: string
) => {
  type resType = {
    [type: string]: TextLayer[];
  };
  const res: resType = {};
  // Only for text layers. TODO for other layers
  for (const type of layerTypes) {
    const currentType = allLayers
      .find((layers: Layer) => layers.scene === sceneName)
      ?.[type].filter(
        (textLayer) =>
          textLayer.controlledText?.toLowerCase().trim().includes(filter) ||
          textLayer.layerData.nm?.toLowerCase().trim().includes(filter) ||
          textLayer.text?.toLowerCase().trim().includes(filter)
      );
    if (currentType) res[type] = currentType;
  }
  return res;
};
