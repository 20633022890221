import { IProjectModel } from "../../stores/project/projectModel";
import SceneCard from "./SceneCard";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import { useEffect, useRef, useState } from "react";
import { useMst } from "../../stores/Root";
import "./SceneView.scss";
type Props = {
  project: IProjectModel;
  selectedScene: string | undefined;
};

const SceneView = observer(({ project, selectedScene }: Props) => {
  const { setSearchView, setCurrentSearch, searchView, currentSearch } = useMst(
    (store) => {
      const { editVideoStore } = store;
      return {
        setSearchView: editVideoStore.setSearchView,
        setCurrentSearch: editVideoStore.setCurrentSearch,
        searchView: editVideoStore.searchView,
        currentSearch: editVideoStore.currentSearch,
      };
    }
  );
  const [videoPartNames, setVideoPartNames] = useState<string[] | undefined>(
    []
  );
  const sceneRefs = useRef<any>([]); // Import for scroll to the scene on scene change

  /**
   * Check if an element is in viewport
   * @param {number} [offset]
   * @returns {boolean}
   */
  const isInViewport = (element: any, offset = 0): boolean => {
    if (!element) return false;
    const top = element.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  };

  useEffect(() => {
    const currentVideoParts = project.workspaceVideoParts?.map((vp) => vp.name);
    setVideoPartNames(currentVideoParts);
  }, [project.workspaceVideoParts.length, selectedScene, project]);

  useEffect(() => {
    // Only scroll to scene if it is not in the currentViewport
    if (
      selectedScene &&
      sceneRefs.current[selectedScene] &&
      !isInViewport(sceneRefs.current[selectedScene])
    ) {
      sceneRefs.current[selectedScene].scrollIntoView();
    }
  }, [selectedScene]);
  return (
    <div>
      {videoPartNames?.length ? (
        <div
          className={[
            "AllScenes",
            currentSearch === "" && searchView && "IsSelected",
          ].join(" ")}
        >
          <Link
            onClick={() => {
              setSearchView(true);
              setCurrentSearch("");
            }}
            to={toPath(PATHS.project, project.id, PROJECT_PATHS.editVideo)}
          >
            <p>All Scenes</p>
          </Link>
        </div>
      ) : (
        "No Scenes"
      )}
      {videoPartNames?.map((videoPart: string) => {
        return (
          <div
            ref={(currentRef) => (sceneRefs.current[videoPart] = currentRef)}
            key={videoPart}
            className="SceneCard"
          >
            <Link
              onClick={() => setSearchView(false)}
              to={toPath(
                PATHS.project,
                project.id,
                PROJECT_PATHS.editVideo,
                videoPart
              )}
            >
              <SceneCard
                scene={videoPart}
                isSelected={selectedScene === videoPart}
              />
            </Link>
          </div>
        );
      }) || ""}
    </div>
  );
});
export default SceneView;
