import { Collapse, Divider } from "antd";
import TextLayerComponent from "./TextLayerComponent";
import { TextIcon } from "../../assets/Icons";
import staticText from "../../utils/staticText";
import { TextLayer } from "../../types/LayerTypes";
import "./LayersView.scss";

const { Panel } = Collapse;
type Props = {
  layers: TextLayer[];
  editable: boolean;
  save?: (sceneName:string, layer:TextLayer) => void;
  update?: (layer: TextLayer, newValue: string) => void;
  handlePanelCollapse?: (keys: string[] | string) => void;
  activePanels?: string[];
  sceneName?: string;
};

const { layersView: layersViewText } = staticText;

// Method to convert the layer and scene data to a uniform key for every panel
export const panelKey = (layerName: string, asset: string, sceneName: string) =>
  JSON.stringify({ layerName, asset, sceneName });

const LayersView = ({
  layers,
  editable,
  save,
  update,
  handlePanelCollapse,
  activePanels,
  sceneName,
}: Props) => {
  const handleCollapse = (layer: TextLayer) => {
    handlePanelCollapse &&
      handlePanelCollapse(
        panelKey(layer.layerData.nm || "", layer.asset || "", sceneName || "")
      );
  };

  const updateText = (layer: TextLayer, newValue: string, isSave?: boolean) => {
    update && update(layer, newValue);
    if (isSave) {
      save && sceneName && save(sceneName, layer);
    }
  };
  const getPanelKey = (layer: TextLayer) => {
    return panelKey(
      layer.layerData.nm || "",
      layer.asset || "",
      sceneName || ""
    );
  };
  return (
    <div className="LayerView">
      <div className={"Description"}>
        <p>{layersViewText.DESCRIPTION(editable)}</p>
      </div>
      <Divider className={"Divider"} />
      <Collapse
        accordion
        className={"TextLayer"}
        activeKey={activePanels}
        onChange={handlePanelCollapse}
        bordered={false}
        ghost={editable}
        expandIcon={() => <TextIcon />}
        {...(!editable ? { collapsible: "disabled" } : {})}
      >
        {layers.map((layer: TextLayer) => {
          const header = layer.initialControlledText || layer.text;
          return (
            <Panel header={header} key={getPanelKey(layer)} className={"Panel"}>
              {editable && save && update && handlePanelCollapse && (
                <TextLayerComponent
                  layer={layer}
                  update={updateText}
                  collapse={handleCollapse}
                />
              )}
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default LayersView;
