import React from "react";
import { Select, Space } from "antd";
import { useContext } from "react";
import { WidgetProps } from "@rjsf/core";
import { getValue } from ".";
import { FieldStateContext } from "../FieldTemplate";
import styles from "./SelectWidget.module.scss";
import { UPLOADING } from "../components/consts";
import MediaContent from "../components/MediaContent";
export const SelectWidget = (props: WidgetProps) => {
  useContext(FieldStateContext);
  const { enumOptions } = props.options;
  const { value, schema } = props;
  return (
    <div className={styles.SelectWidget}>
      <Select
        onChange={(v: any) => props.onChange(v)}
        value={
          getValue(props)
            ? getValue(props)
            : enumOptions
            ? enumOptions[0]
            : undefined
        }
        className={styles.widget}
        bordered={false}
      >
        {(enumOptions as { label: string; value: string }[]).map(
          ({ label, value }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          )
        )}
      </Select>

      {value &&
        (schema.format === "image" ||
          schema.format === "video" ||
          schema.format === "audio") && (
          <MediaContent src={value} format={schema.format} />
        )}
    </div>
  );
};
