import { Chart } from "react-chartjs-2";
import { Chart as ChartJs } from "chart.js";
import { SessionsPerCountryProps } from "../Analytics";
import * as ChartGeo from "chartjs-chart-geo";
import countryCodeToData from "../../../utils/countryCodeToData";
// @ts-ignore
import world from "world-atlas/countries-50m";

const countries = ChartGeo.topojson.feature(
  world,
  world.objects.countries
  // @ts-ignore
).features;
ChartJs.register(
  ChartGeo.BubbleMapController,
  ChartGeo.ProjectionScale,
  ChartGeo.SizeScale,
  ChartGeo.GeoFeature
);
export const SessionsPerCountry = ({
  data,
}: {
  data?: SessionsPerCountryProps;
}) =>
  data ? (
    <div className="analytics-view map">
      <h2>Views on Map</h2>
      <Chart
        type="bubbleMap"
        options={{
          showOutline: true,
          showGraticule: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            xy: {
              projection: "equalEarth",
            },
          },
        }}
        data={{
          labels: data.map((d) => countryCodeToData[d.key].country),
          datasets: [
            {
              label: "Countries",
              outline: countries,
              showOutline: true,
              backgroundColor: ["rgba(255, 89, 122, 0.6)"],
              data: data.map((d) => ({
                feature: countryCodeToData[d.key].country,
                latitude: countryCodeToData[d.key].latitude,
                longitude: countryCodeToData[d.key].longitude,
                value: d.doc_count,
              })),
            },
          ],
        }}
      />
    </div>
  ) : null;
