import { useRef, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { UpdateProjectInput } from "../../API";
import { ProjectCrudForm } from "../AdminPage/ProjectCrudForm";
import { IProjectModel } from "../../stores/project/projectModel";
import { MSTContext } from "../../stores/Root";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { Card, Space } from "antd";
import { PATHS, toPath } from "../../PATHS";

import "./UpdateProject.scss";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
type Props = {
  project: IProjectModel;
};
export const UpdateProject = observer(({ project }: Props) => {
  const {
    projectsStore: { updateOrCreateProjectStatus, deleteProject, saveProject, refreshProject },
  } = useContext(MSTContext);

  const history = useNavigate();
  const formRef = useRef<HTMLFormElement>();
  return (
    <div className="Update-Project">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Card>
          <ProjectCrudForm<UpdateProjectInput>
            formRef={formRef}
            btnTexts={{ [AsyncOpState.Changed]: "Update" }}
            actionText="Update"
            isCreate={false}
            isAdmin={false}
            onFinish={async (upi) => {
              await saveProject({ ...upi, id: project.id });
              await refreshProject(project.id);
            }}
            onDelete={async (d) => {
              await deleteProject(d);
              history(toPath(PATHS.home)); // Redirect to home
            }}
            initialValues={project}
            btnInForm={false}
          />

          <BlingsBtn
            opState={updateOrCreateProjectStatus}
            htmlType={"submit"}
            btnTexts={{ [AsyncOpState.Changed]: "Save" }}
            onClick={() => {
              formRef.current?.submit();
            }}
          />
        </Card>
      </Space>
    </div>
  );
});
