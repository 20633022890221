import * as React from "react";
import { IProjectModel } from "../../stores/project/projectModel";
import { useRef, useState, useEffect } from "react";
import { Col, Select, Space, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { Player } from "@blings/blings-player";
import PlayerManager from "../../utils/playerManager";
import { getENV } from "../../config";
import { observer } from "mobx-react-lite";
import { EXAMPLE_PLAYER_LINK } from "../../consts";
import { ISdkSettings } from "@blings/blings-player/lib/src/SDK/sdk.api";
import useDebounce from "../../helpers/DebounceHook";
import { Auth } from "aws-amplify";
import { rootStore } from "../../stores/Root";
import {
  NotificationType,
  InstantNotification,
} from "../../types/Notification";
import { Notification } from "../../stores/notification/notificationModel";
import { FrameIndicator } from "./FrameIndicator";
import { toast } from "react-toastify";
import { Instance } from "mobx-state-tree";
import { ExportMp4Icon, ShowInDemoPageIcon } from "../../assets/Icons";

const { Text } = Typography;
type Props = {
  project: IProjectModel;
  data: string;
  selectedDataID?: string;
  settings?: Partial<ISdkSettings>;
  recordChanged?: boolean;
  frameIndicator?: boolean;
  renderMp4?: boolean;
};

interface LinkProps extends Props {
  scenes?: string[];
  selectedDataID?: string;
}

interface InnerProps extends Props {
  // project: IProjectModel;
  // data: string;
  scenes?: string[];
  vertical: boolean;
  settings: any;
  frameIndicator?: boolean;
  playerVersionToUse: string | undefined;
}

/**
 * Component to render the Blings Player using dynamic data
 * @param props - All the data in one object. This data is:
 * - project: IProjectModel; - The project model containing the project ID.
 * - data: string; - The data of the dynamic data for the player.
 * - scenes?: string[]; - The scenes for the video that the player must load.
 * - settings?: Partial<ISdkSettings>; - The settings for the player.
 * @returns {React.FC<LinkProps>} - A div component where the player will be rendered.
 */
const DemoSdkPlayerInner = ({
  project,
  data,
  scenes,
  settings,
  frameIndicator,
  playerVersionToUse
}: InnerProps) => {
  // This is the container reference to where the player will be rendered
  // This will reference the div element that is always rendered
  // And the player SDK will be rendered inside this div
  const containerRef = useRef<HTMLDivElement>(null);

  // This flag will be used to control if the player can be created or not
  // If a player is already created or dont exists, this flag will be set to true
  // If a player is being created, this flag will be set to false
  const [canCreatePlayer, setCanCreatePlayer] = useState(true);

  // This will be used to control if the player exists or not
  // const [player, setPlayer] = useState<Player | null>(null);

  // This will be used to check if the player needs a new configuration
  // If this is different from the last configuration, the player will be recreated
  const [lastConfig, setLastConfig] = useState<string>("");
  const [actualConfig, setActualConfig] = useState<string>("");
  const [currentFrame, setCurrentFrame] = useState<string>("");
  const [totalFrames, setTotalFrames] = useState<string>("");
  // This function will be used to create the player with the actual configuration
  function CreatePlayer() {
    if (!canCreatePlayer) {
      return;
    }
    setCanCreatePlayer(false);

    // Check if a player already exists
    if ((window as any).p) {
      // If a player already exists, we want to destroy it
      try {
        const lastIndex = (window as any).p.index;
        (window as any).p.stop();
        (window as any).p.destroy();
        // Clean old players if exists
        if ((window as any).BlingsPlayer)
          Object.keys((window as any).BlingsPlayer.players).forEach((key) => {
            if (key < lastIndex) {
              (window as any).BlingsPlayer.players[key].destroy();
            }
          });
      } catch (e) {
        console.error("err destroying player", e);
      }
    }
    // Create the object that will be used to create the player
    const { project, data, settings, scenes }: InnerProps =
      JSON.parse(actualConfig);
    // Create the player
    if (containerRef.current && scenes?.length) {
      containerRef.current.innerHTML = "";
      // Create the player
      new PlayerManager();

      // Get the major version of the player
      let majorVersion = parseInt(playerVersionToUse?.split(".")[0] as string);
      if (Number.isNaN(majorVersion))
        majorVersion = -1;

      PlayerManager.get()
        .createPlayer({
          project: { env: getENV(), projectId: project.id },
          data,
          settings: { container: containerRef.current, ...settings },
          scenes,
        }, majorVersion)
        .then((p: Player) => {
          // Store the player on window, on a state and set the flag to allow creation of new players
          (window as any).p = p;
          setCanCreatePlayer(true);
          // Set the last configuration used to the current configuration
          // The last config defines what the player was created with
          setLastConfig(actualConfig);
          setCurrentFrame(p.animation.currentFrame.toString());
          setTotalFrames(p.animation.totalFrames.toString());
          p.EE.on("onFrame", () => {
            setCurrentFrame(p.animation.currentFrame.toString());
          });
        });
    } else {
      setCanCreatePlayer(true);
      setLastConfig(actualConfig);
    }
  }

  // This will be used to control if the player is being created or not
  useEffect(() => {
    // Store the actual config on a state for access for later verification
    setActualConfig(
      JSON.stringify({
        project,
        scenes,
        data,
        settings,
        playerVersionToUse
      })
    );
  }, [project.id, scenes, data, settings, playerVersionToUse]);

  // Try to create the player always that the actual config is changed
  useEffect(() => {
    setLastConfig(actualConfig);
    if (actualConfig !== "") CreatePlayer();
  }, [actualConfig]);

  // This will be used to control if the player needs a new configuration, so a new player needs to be created
  useEffect(() => {
    if (canCreatePlayer) {
      if (actualConfig !== lastConfig) {
        CreatePlayer();
      }
    }
  }, [canCreatePlayer]);
  return (
    <div>
      <div ref={containerRef} />{" "}
      {frameIndicator ? (
        <FrameIndicator currentFrame={currentFrame} totalFrames={totalFrames} />
      ) : (
        <></>
      )}
    </div>
  );
};

/**
 * This function will get all the video values from the current player and send it to the message service to add it to the server render queue
 */
async function CallServerRender(
  project: any,
  scenes: any,
  data: any,
  selectedDataID: any,
  recordChanged: any
) {
  /* Format that the server render accepts
  { 
      "data": { "firstName": "Carlos"},
      "scenes": ["Main"],
      "projectId": "Pilot_SDR",
      "env": "dev",
      "quality": "1",
      "outputFileName": "teste"
  } 
  */
  const serverRenderData = {
    data,
    scenes,
    projectId: project.id,
    env: getENV(),
    quality: "3", // Medium quality
  };
  const currentSession = await Auth.currentSession();
  const userCredentials = currentSession.getAccessToken().getJwtToken();

  toast(`MP4 rendering requested!`, {
    position: "bottom-left",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  // Send the data to the server render queue through the message service
  // If env dev, send to the dev queue: 52.213.177.53
  // If env prod, send to the prod queue: https://mp4.blings.io/
  const url =
    getENV() === "dev" //"http://127.0.0.1:3000/start-create" : "https://mp4.blings.io/start-create"
      ? //"http://localhost:3000/start-create" : ""
        "https://cors.blings.io/http://54.171.239.233:3000/start-create"
      : "https://mp4.blings.io/start-create";
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userCredentials,
    },
    body: JSON.stringify(serverRenderData),
  })
    .then((response) => {
      // Listen to subscriptions from the server render
      // creates a toast notification when receive a instant notification
      // and creates a toast notification when receive a normal notification
      try {
        rootStore.notificationStore.addNotificationSubscription({
          id: "render-callback-normal" + project.id,
          type: [NotificationType.Render],
          subType: ["rendering"],
          executeOnlyOnce: true, // shall only receive 1 normal notification, the one that says the render is done
          sendType: "Normal",
          callback: (
            notification: Instance<typeof Notification> | InstantNotification
          ) => {
            //console.log("Normal notification", notification);
            toast(
              `Your render has started. We will notify you when your file is ready.`,
              {
                position: "bottom-left",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
          },
        });
      } catch (error) {
        console.error(error);
      }
    })
    .catch((error) => {
      toast(`Error when trying to render. Please try again later...`, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
}

function DemoSdkExternalLink({
  project,
  scenes,
  selectedDataID,
  data,
  recordChanged,
  renderMp4 = false,
}: LinkProps) {
  let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${project.id}`;
  if (scenes) {
    url += `&scenes=${encodeURIComponent(scenes.join())}`;
  }

  if (selectedDataID) {
    url += `&id=${selectedDataID}`;
  }

  if (data && (!selectedDataID || recordChanged)) {
    url += `&data=${encodeURIComponent(JSON.stringify(data))}`;
  }
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          width: "100%",
        }}
      >
        {renderMp4 ? (
          <a
            style={{
              display: "inline-block",
              paddingRight: "20px",
              alignSelf: "end",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              CallServerRender(
                project,
                scenes,
                data,
                selectedDataID,
                recordChanged
              );
            }}
          >
            <ExportMp4Icon /> Export to MP4
          </a>
        ) : (
          <></>
        )}
        <a
          style={{ display: "inline-block", paddingRight: "20px" }}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ShowInDemoPageIcon /> Show In Demo Page
        </a>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(url);
            toast(`Copied to clipboard`, {
              position: "bottom-left",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }}
        >
          <CopyOutlined /> Copy Url
        </a>
      </div>
    </div>
  );
}

export const DemoSdkPlayer = observer(
  ({
    project,
    data,
    selectedDataID,
    settings,
    recordChanged,
    frameIndicator,
    renderMp4,
  }: Props) => {
    const { videoPartNames } = project;
    const [scenes, setScenes] = useState<string[]>(
      (videoPartNames && [videoPartNames[0]]) || []
    );

    const selectedScenes = project.workspaceVideoParts?.filter(
      (vp) => vp.name && scenes.includes(vp.name)
    );
    const playerVersionToUse = project.playerVersionToUse || undefined;
    const debouncedSettings = useDebounce<any>(settings, 1000);
    const debouncedScenes = useDebounce<any>(scenes, 1000);
    const vertical = selectedScenes?.length
      ? selectedScenes[0]?.jsonData?.h > selectedScenes[0]?.jsonData?.w
      : false;

    return (
      <Col lg={{ span: 16, order: 2 }} xs={{ span: 24, order: 1 }}>
        <Space
          direction="vertical"
          style={{ width: vertical ? 300 : undefined }}
        >
          {videoPartNames && videoPartNames.length > 1 && (
            <Space>
              <Text strong={true}>Scene:</Text>
              <Select
                mode="multiple"
                allowClear
                onChange={setScenes}
                value={scenes}
                style={{ minWidth: 200 }}
              >
                {videoPartNames.map((vp) => (
                  <Select.Option key={vp} value={vp}>
                    {vp}
                  </Select.Option>
                ))}
              </Select>
            </Space>
          )}
          {scenes.length ? (
            <DemoSdkPlayerInner
              project={project}
              data={data}
              scenes={debouncedScenes}
              vertical={vertical}
              settings={debouncedSettings}
              frameIndicator={frameIndicator}
              playerVersionToUse={playerVersionToUse}
            />
          ) : (
            <div />
          )}
          <DemoSdkExternalLink
            project={project}
            data={data}
            scenes={scenes}
            selectedDataID={selectedDataID}
            settings={settings}
            recordChanged={recordChanged}
            renderMp4={renderMp4}
          />
        </Space>
      </Col>
    );
  }
);
