import { useRef, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Card, Space, Tooltip } from "antd";
import { CodeOutlined } from "@ant-design/icons";
import { IProjectModel } from "../../stores/project/projectModel";
import { MSTContext } from "../../stores/Root";
import { AsyncOpState } from "../../types/enums/async-op-states";

import {
  fillSchemaExamplesWithData,
  jsonSchemaGetExamples,
} from "../../helpers/jsonSchema.helpers";
import "./UpdateProject.scss";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { DynamicForm } from "@blings/dynamic-form";
import "./ProjectSchemas.scss";

import { CodeModeModal } from "../../components/CodeModeModal";
import { cleanEmptyStringInValue } from "../../helpers/object.helpers";
import { FEEDBACK_OPTIONS, FEEDBACK_TIME } from "../../consts";
type Props = {
  project: IProjectModel;
};
const saveWithFeedback = (
  saveFunction: () => void,
  setState: (arg0: AsyncOpState) => void
) => {
  setState(AsyncOpState.Saving);
  try {
    saveFunction();
    setTimeout(() => {
      setState(AsyncOpState.Success);
    }, FEEDBACK_TIME);
  } catch (error) {
    setTimeout(() => {
      setState(AsyncOpState.Error);
    }, FEEDBACK_TIME);
  }
  setTimeout(() => {
    setState(AsyncOpState.Changed);
  }, 2 * FEEDBACK_TIME);
};
export const UpdateProjectSchemas = observer(({ project }: Props) => {
  const {
    projectsStore: { updateOrCreateProjectStatus, selectedProject },
    liveControlStore: { getAssetsUploadStatus, uploadAssetToProject },
  } = useContext(MSTContext);

  const submitLiveControl = useRef<() => void>(() => {
    return;
  });
  const submitPerVideoData = useRef<() => void>(() => {
    return;
  });
  const [perVideoSaveState, setPerVideoSaveState] = useState<AsyncOpState>(
    updateOrCreateProjectStatus
  );
  const [controlSaveState, setControlSaveState] = useState<AsyncOpState>(
    updateOrCreateProjectStatus
  );
  const [perVideoCodeModalOpen, setPerVideoCodeModalOpen] = useState(false);
  const [liveControlCodeModalOpen, setLiveControlCodeModalOpen] =
    useState(false);

  return (
    <div className="Project-Schemas">
      {selectedProject ? (
        <div className="schemas">
          <div className="schema-card">
            <Card
              title={"Per Video Data Schema:"}
              className="schema-card"
              extra={
                <Tooltip title="Code mode">
                  <Button
                    onClick={() => {
                      setPerVideoCodeModalOpen(true);
                    }}
                  >
                    <CodeOutlined />
                  </Button>
                </Tooltip>
              }
            >
              <DynamicForm
                schema={
                  selectedProject.stateJsonSchemaStr &&
                  JSON.parse(selectedProject.stateJsonSchemaStr)
                }
                formData={
                  selectedProject.stateJsonSchemaStr
                    ? jsonSchemaGetExamples(
                        JSON.parse(selectedProject.stateJsonSchemaStr)
                      )
                    : {}
                }
                key={project.stateJsonSchemaStr}
                editable={true}
                readable={false}
                saveData={(data, schema) => {
                  fillSchemaExamplesWithData(schema, data);
                  selectedProject.savePerVideoData(schema);
                }}
                submit={submitPerVideoData}
                getAssetsUploadStatus={getAssetsUploadStatus}
                uploadAssetToProject={uploadAssetToProject}
              />
              <Space>
                <BlingsBtn
                  opState={perVideoSaveState}
                  htmlType={"submit"}
                  btnTexts={FEEDBACK_OPTIONS}
                  onClick={() => {
                    saveWithFeedback(
                      submitPerVideoData.current,
                      setPerVideoSaveState
                    );
                  }}
                />
              </Space>
            </Card>
          </div>

          <div className="schema-card">
            <Card
              title={"Live Control Schema:"}
              extra={
                <Tooltip title="Code mode">
                  <Button
                    onClick={() => {
                      setLiveControlCodeModalOpen(true);
                    }}
                  >
                    <CodeOutlined />
                  </Button>
                </Tooltip>
              }
            >
              <DynamicForm
                schema={project.settingsSchema}
                formData={project.settings || {}}
                editable={true}
                readable={false}
                key={project.settingsJsonSchemaStr}
                saveData={(data, schema) => {
                  fillSchemaExamplesWithData(schema, data);
                  //check project.settingsSchema against schema?
                  project.saveLiveControl(
                    cleanEmptyStringInValue(data),
                    schema
                  );
                }}
                submit={submitLiveControl}
                getAssetsUploadStatus={getAssetsUploadStatus}
                uploadAssetToProject={uploadAssetToProject}
              />
              <Space>
                <BlingsBtn
                  opState={controlSaveState}
                  htmlType={"submit"}
                  btnTexts={FEEDBACK_OPTIONS}
                  onClick={() => {
                    saveWithFeedback(submitLiveControl.current,setControlSaveState)
                  }}
                />
              </Space>
            </Card>
          </div>
        </div>
      ) : (
        <div>Please select a project</div>
      )}
      {perVideoCodeModalOpen && (
        <CodeModeModal
          title="Per video example data"
          setVisible={setPerVideoCodeModalOpen}
          visible={perVideoCodeModalOpen}
          schema={selectedProject?.stateJsonSchema}
          onSave={(schema) => {
            selectedProject?.savePerVideoData(schema);
          }}
        />
      )}
      {liveControlCodeModalOpen && (
        <CodeModeModal
          title="Live control example data"
          setVisible={setLiveControlCodeModalOpen}
          visible={liveControlCodeModalOpen}
          schema={selectedProject?.settingsSchema}
          onSave={(schema, data) => {
            selectedProject?.saveLiveControl(data, schema);
          }}
          formData={selectedProject?.settings}
        />
      )}
    </div>
  );
});
