import { LandingPageIntegration } from "./LandingPageIntegration/LandingPageIntegration";
import { Select } from "antd";
import { useState } from "react";
import { useMst } from "../../../../stores/Root";
import { JSONSchema6 } from "json-schema";
import { IntegrationLink } from "./IntegrationLink/IntegrationLink";
import { IProjectModel } from "../../../../stores/project/projectModel";
import "./CrmIntegration.scss";
const crms = [
  {
    name: "Hubspot",
    img: "https://via.placeholder.com/300x25/fff.png",
    instructions: "Copy the link below to your Hubspot > Email template and replace the variables with your Hubspot tokens.  See our tutorial on how to create the customized link for distributing.",
    video: "https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761"
  }, {
    name: "Salesforce",
    img: "https://via.placeholder.com/300x25/fff.png",
    instructions: "Copy the link below to your Salesforce > Email template and replace the variables with your Salesforce tokens.  See our tutorial on how to create the customized link for distributing.",
    video: "https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761"
  }, {
    name: "Mailchimp",
    img: "https://via.placeholder.com/300x25/fff.png",
    instructions: "Copy the link below to your Mailchimp > Email template and replace the variables with your Mailchimp tokens.  See our tutorial on how to create the customized link for distributing.",
    video: "https://player.vimeo.com/external/371817283.sd.mp4?s=56639e00db07ad3f26d837314e3da531bad01b1b&profile_id=164&oauth2_token_id=57447761"

  }
];
type Props = {
  project: IProjectModel;
  state: {
    alias: string;
    scenes: Array<string>;
    title: string;
    description: string;
    thumbnail: string;
    favicon: string;
  };
  setState: React.Dispatch<React.SetStateAction<{
    alias: string;
    scenes: Array<string>;
    title: string;
    description: string;
    thumbnail: string;
    favicon: string;
  }>>;
};
export const CrmIntegration = (props: Props) => {
  const { project, state, setState } = props;
  return <div className="CrmIntegration">
    <LandingPageIntegration project={project} state={state} setState={setState} />
    <DataIntegration projectAliasId={state.alias} />
  </div>;
};

type DataIntegrationProps = {
  projectAliasId: string;
};

const DataIntegration = (props: DataIntegrationProps) => {
  const state: { accountAliasId: string, schema: JSONSchema6; } = useMst((store) => ({
    accountAliasId: store.accountStore.accountSettings?.aliasId || "",
    schema: store.projectsStore.selectedProject?.stateJsonSchema
  }));
  const { projectAliasId } = props;
  const { accountAliasId, schema } = state;
  const [selectedCrmIndex, setSelectedCrmIndex] = useState(0);
  const selectedCrm = crms[selectedCrmIndex];
  return <div className="DataIntegration">
    <div>
      <h1 className="page-subtitle">2. DATA INTEGRATION</h1>
      <span>You can connect your data in various ways. Choose your preferred way:</span>
      <div>
        <div>
          <Select onChange={e => setSelectedCrmIndex(e)} value={selectedCrmIndex} options={crms.map((crm, i) => ({ value: i, label: <span>{crm.name}<img src={crm.img} /></span> }))} />
          <div>{selectedCrm.instructions}</div>
        </div>
      </div>
      <IntegrationLink accountAliasId={accountAliasId} projectAliasId={projectAliasId} schema={schema} />
    </div>
    <div className="video-container">
      <video autoPlay src={selectedCrm.video}></video>
    </div>
  </div>;
};