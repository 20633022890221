import { observer } from "mobx-react-lite";
import React from "react";

import { useParams } from "react-router-dom";
import { IProjectModel } from "../../stores/project/projectModel";
import { useMst } from "../../stores/Root";
import { IuploadDataModel } from "../../stores/uploadData";
import staticText from "../../utils/staticText";
import DemoPlayer from "./DemoPlayer";
import "./EditVideo.scss";
import EditView from "./EditView";
import SceneView from "./SceneView";
type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
  scene: string;
};
const EditVideo = ({ project, uploadData, scene }: Props) => {
  return (
    <div className={"Main"}>
      <div className={"Container"}>
        <div className={"Scenes"}>
          <SceneView project={project} selectedScene={scene} />
        </div>
        <div className={"Edit"}>
          <EditView project={project} selectedScene={scene} />
        </div>
        <div className={"Demo"}>
          <div className={"TopBar"}>
          </div>
          <DemoPlayer scene={scene} project={project} uploadData={uploadData} />
        </div>
      </div>
    </div>
  );
};
const EditVideoWrapper = observer(() => {
  const { id, scene } = useParams<{ id: string; scene: string; }>();
  const [state, setState] = React.useState<any>();

  const storeMethod = useMst((store) => {
    return {
      setSelectedProjectById: store.projectsStore.setSelectedProjectById,
      isLoading: store.projectsStore.projectsIsLoading,
      project: store.projectsStore.selectedProject,
      setSearchView: store.editVideoStore.setSearchView,
      searchView: store.editVideoStore.searchView,
      setCurrentSearch: store.editVideoStore.setCurrentSearch,
    };
  });
  React.useEffect(() => {
    const {
      setSelectedProjectById,
      project,
      setSearchView,
      setCurrentSearch,
      searchView,
    } = storeMethod;
    setState({ scene, project });
    // On refresh, set default page to search all
    if (!scene && !searchView) {
      // If not in a current search state and no scene is selected
      setSearchView(true);
      setCurrentSearch("");
    }
    const load = async (id: string) => await setSelectedProjectById(id);
    id && load(id);
  }, [id, scene, storeMethod.project?.workspaceVideoParts?.length]);

  if (!state?.project) {
    return null;
  }

  return <EditVideo {...state} />;
});

export default EditVideoWrapper;
