import {
  getLayersFromSceneHelper,
  getTextLayers,
} from "../../helpers/editView.helpers";
import { IProjectModel } from "../../stores/project/projectModel";
import "./EditView.scss";
import { observer } from "mobx-react-lite";
import LayersView from "./LayersView";
import { Layer, TextLayer } from "../../types/LayerTypes";
import { useEffect, useState } from "react";
import staticText from "../../utils/staticText";
import { AsyncOpState } from "../../types/enums/async-op-states";
import SearchLayers from "./SearchLayers";
import { useNavigate } from "react-router-dom";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import { useMst } from "../../stores/Root";
import React from "react";

type Props = {
  project: IProjectModel;
  selectedScene: string;
};
const EditView: React.FC<Props> = observer((props) => {
  const { project, selectedScene } = props;

  const history = useNavigate();

  const { searchView, currentSearch, setSearchView, setCurrentSearch } = useMst(
    (store) => {
      const { editVideoStore } = store;
      return {
        searchView: editVideoStore.searchView,
        currentSearch: editVideoStore.currentSearch,
        setSearchView: editVideoStore.setSearchView,
        setCurrentSearch: editVideoStore.setCurrentSearch,
      };
    }
  );
  const [allLayers, setAllLayers] = useState<Layer[]>([]);
  // Current input of the search box
  const [currentInput, setCurrentInput] = useState("");
  // Current active panels
  const [activePanels, setActivePanels] = useState<string[]>([]); // Panels currently open
  // Get data from current scene
  const currentScene = project.workspaceVideoParts?.find(
    (vp) => vp.name === selectedScene
  );
  /**
   * This method activates and deactivates the active panels and redirects to the correct scene
   * If we are opening a panel which is not in the current scene
   * (ex. if we are opening a panel from a specific scene in search mode)
   * Then we should redirect to that scene
   * @param keys
   * @returns (keys) => void. This method activates and deactivates the active panels
   */
  const handlePanelCollapse = (keys: string[] | string) => {
    if (!keys) setActivePanels([]);
    else {
      typeof keys === "string"
        ? activePanels.includes(keys)
          ? setActivePanels([])
          : setActivePanels([keys])
        : setActivePanels(keys);
    }
    //Get scene name from current panel
    const sceneName =
      typeof keys === "string" ? JSON.parse(keys)?.sceneName : "";
    // Redirect to the scene from the active panel if it is not already
    if (sceneName && sceneName !== selectedScene) {
      history(
        toPath(PATHS.project, project.id, PROJECT_PATHS.editVideo, sceneName)
      );
    }
  };
  // Saves the local state to db
  const save = async (sceneName: string, layer: TextLayer) => {
    await project.saveMod(sceneName, layer, "text");
    update();
  };
  /**
   * If we are going to search view, redirect to the editview url without selecting a specific scene
   * Reset the search bar and update the currentSearch state to show results for that search
   * @param searchString
   */
  const filterLayers = (searchString: string) => {
    if (searchString !== "") {
      history(toPath(PATHS.project, project.id, PROJECT_PATHS.editVideo)); //Unselect scenes and player
      setCurrentInput(""); //Reset the search bar
      setSearchView(true);
      setCurrentSearch(searchString.toLowerCase().trim()); //Set search state to the new string
    }
  };
  /**
   * This method will update the allLayers state to get the most up to date layers
   */
  const update = () => {
    let newState: Layer[] = [];
    project.workspaceVideoParts?.forEach((scene) => {
      const { allDynamicTextLayers, allStaticTextLayers } = getTextLayers(
        scene,
        project
      );
      const currentLayer: Layer = {
        scene: scene.name,
        allStaticTextLayers,
        allDynamicTextLayers,
      };
      newState = [...newState, currentLayer];
    });
    setAllLayers(newState);
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, currentScene?.assetLayerPairs, currentScene]);

  // Updates the state to have the latest changes
  const updateText = (textLayer: TextLayer, newValue: string) => {
    project.updateSaveModsStatus(AsyncOpState.Saving);
    // updates the state
    currentScene?.updateTextMods(textLayer, newValue);
    // Get the latest textLayers
    update();
    project.updateSaveModsStatus(AsyncOpState.Success);
  };

  /**
   *
   * @param sceneName The scene we have to get the layers from
   * @param filter If present, filter the layers with the filter
   * @returns JSX to be shown
   */
  const layersFromSceneView = (
    sceneName: string | undefined = currentScene?.name,
    filter = ""
  ) => {
    // Get all static text layers from the current scene
    const {
      allDynamicTextLayers: currentSceneDynamicTextLayers,
      allStaticTextLayers: currentSceneStaticTextLayers,
    } = getLayersFromSceneHelper(
      allLayers,
      ["allDynamicTextLayers", "allStaticTextLayers"],
      sceneName || "",
      filter
    );
    return (
      <div>
        {currentSceneStaticTextLayers?.length ||
        currentSceneDynamicTextLayers?.length ? (
          <>
            <p id="sceneName">{sceneName}</p>
            {currentSceneStaticTextLayers?.length ? (
              <LayersView
                sceneName={sceneName}
                activePanels={activePanels}
                layers={currentSceneStaticTextLayers}
                editable={true}
                update={updateText}
                save={save}
                handlePanelCollapse={handlePanelCollapse}
              />
            ) : (
              ""
            )}
            {currentSceneDynamicTextLayers.length ? (
              <div className="DynamicText">
                <LayersView
                  layers={currentSceneDynamicTextLayers}
                  editable={false}
                />
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="EditView">
      <div className={"EditViewHeader"}>
        <div className="SubHeader">{staticText.editView.EDIT_SCENE}</div>
        <div className="SearchHeader">
          <SearchLayers
            projectId={project.id}
            scene={selectedScene}
            layers={allLayers}
            handlePanelCollapse={handlePanelCollapse}
            filterLayers={filterLayers}
            currentInput={currentInput}
            setCurrentInput={setCurrentInput}
          />
        </div>
      </div>
      {!searchView ? (
        layersFromSceneView(currentScene?.name)
      ) : (
        <div>
          {staticText.editView.SEARCH_RESULTS(currentSearch)}
          {project?.workspaceVideoParts?.map((vdp) => {
            return (
              <div key={vdp.name}>
                {layersFromSceneView(vdp.name, currentSearch)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});

export default EditView;
