import { JSONSchema7 } from "json-schema";

export const UPLOADING = "Uploading...";
export const LINK = "link";
export const IMAGE = "image";
export const AUDIO = "audio";
export const VIDEO = "video";
export const EMPTY_SCHEMA :JSONSchema7 = {
  $schema: "http://json-schema.org/draft-07/schema",
  $id: "http://example.com/example.json",
  type: "object",
  title: "",
  description: "",
  default: {},
  examples: [],
  required: [],
  properties: {},
  additionalProperties: true,
};
