import { Space, Spin, Row } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useMst } from "../../stores/Root";
import UploadNewFile from "./UploadNewFile";
import DataUploadsTable from "./DataUploadsTable";
import "./DataUpload.scss";
import { IFileUploadModel, IaccountModel } from "../../stores/account";
import { IProjectModel } from "../../stores/project/projectModel";
import { IuploadDataModel } from "../../stores/uploadData";
// const { Title, Text, Paragraph } = Typography;

type Props = {
  loading: boolean;
  account: IaccountModel;
  fileUploads: IFileUploadModel[];
  projects: IProjectModel[];
  uploadData: IuploadDataModel;
};

const DataUploadsWrapper = observer(() => {
  const state = useMst((store) => {
    if (
      !store.accountStore.accountSettings ||
      !store.accountStore.userAttributes ||
      !store.accountStore.fileUploads
    ) {
      return { loading: true };
    }

    return {
      loading: false,
      fileUploads: store.accountStore.fileUploads,
      projects: store.projectsStore.projects,
      uploadData: store.uploadDataStore,
      account: store.accountStore,
    };
  });

  return <DataUploads {...state} />;
});

function DataUploads({ loading, ...props }: Props) {
  // React.useEffect(() => {
  //   rootStore.uploadDataStore.loadDataUploads();
  // }, []);

  return (
    <div className={"DataUploadsPage page-layout-padding"}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div className="DataUploads">
          <UploadNewFile
            uploadData={props.uploadData}
            onUpload={() => props.account.loadAccountData()}
          />
        </div>

        {loading ? (
          <Row justify="center" className="loading">
            <Spin />
          </Row>
        ) : (
          <DataUploadsTable {...props} />
        )}
      </Space>
    </div>
  );
}

export default DataUploadsWrapper;
