const staticText = {
  editView: {
    EDIT_SCENE: "Edit Scene",
    SEARCH_RESULTS: (searchInput: string) => {
      if (searchInput === "") return "All Scenes";
      else return `Search results for ${searchInput}:`;
    },
  },
  header: {
    LIVE_VERSION: "Published Version",
    VERSION: (published: boolean) =>
      `${published ? "Published" : "Unpublished"} Version.`,
    LAST_EDIT: "Last edit: ",
    BY_USER: (user: string) => `By ${user}`,
    PUBLISH: "Publish",
  },
  layersView: {
    DESCRIPTION: (editableText: boolean) =>
      `${editableText ? "TEXT" : "[NOT EDITABLE] DATA AUTOMATION TEXT"}`,
  },
  textLayer: {
    ORIGINAL: "Original",
    CHANGE_TEXT: "Change Text",
    CANCEL: "Cancel",
    SAVE: "Save",
  },
  searchLayer: {
    PLACEHOLDER: "Search in all scenes",
    CONTENT_NOT_FOUND: "Not Found",
  },
};
export default staticText;
