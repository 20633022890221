import {
  IDataFileModel,
  IProjectBaseModel,
} from "./projectModel";
import { UpdateProjectInput } from "../../API";
import { API } from "aws-amplify";
import {
  PLATFORM_REST_API,
  PLATFORM_REST_API__DATA_RECODRS_PATH,
} from "../../consts";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { INITIAL_ASYNC_OP_STATE } from "../../types/mst/async-op-state";
import { savePublished } from "../../utils/projectTools";
// type x = Pick<IProjectModel, 'model'>

export const DATA_ID_SCHEMA_PROP = "data_id";

export const createDataPointActions = (self: IProjectBaseModel) => ({
  updateNewDPFormData(data: any) {
    self.newDPFormData = data;
  },
  async setPlatformAutoSource() {
    const dataFiles: IDataFileModel[] = self.dataFiles || [];
    const df = {
      fileName: self.platformAutoSourceId,
      createdAt: new Date().toISOString(),
      tag: null,
    };

    dataFiles.push(df);

    try {
      const input: UpdateProjectInput = {
        id: self.id,
        dataFiles, // : [df],
      };
      await savePublished(input);
    } catch (e) {
      throw new Error("Failed to set platform-auto-sourceid");
      // console.log("Failed to set platform-auto-sourceid");
    }
  },
  async loadExistingDPs() {
    const res = await API.get(
      PLATFORM_REST_API,
      PLATFORM_REST_API__DATA_RECODRS_PATH + "/" + self.platformAutoSourceId,
      {}
    );

    self.setCreatedDPList(res);
  },

  async putDataPoint(newDp: any) {
    // await API.get('SourceDataRecordsAPI', `${(self).platformAutoSourceId}/${}`)
    const dataId = newDp[DATA_ID_SCHEMA_PROP];
    delete newDp[DATA_ID_SCHEMA_PROP];
    const fullRecord = {
      sourceId: self.platformAutoSourceId,
      dataId,
      record: newDp,
    };
    console.log(fullRecord);
    try {
      await API
        // .get(PLATFORM_REST_API, PLATFORM_REST_API__DATA_RECODRS_PATH + `/object/${self.platformAutoSourceId}/qwe123`, {})
        .put(PLATFORM_REST_API, PLATFORM_REST_API__DATA_RECODRS_PATH, {
          body: fullRecord,
        });
    } catch (e) {
      console.error("err", e);
      throw new Error("Failed to add data point");
    }
  },

  async createDataPoint(newDp: any) {
    this.changeCreateDPStatus(AsyncOpState.Saving);
    try {
      const isPlatformAutoSourceIdCreated =
        self.dataFiles &&
        self.dataFiles.some((df) => {
          return df.fileName === self.platformAutoSourceId;
        });
      if (!isPlatformAutoSourceIdCreated) {
        await this.setPlatformAutoSource();
      }
      await this.putDataPoint(newDp);
      this.createDPSuccess();
    } catch (e: any) {
      this.changeCreateDPStatus(AsyncOpState.Error);
      setTimeout(() => {
        this.changeCreateDPStatus(INITIAL_ASYNC_OP_STATE);
      }, 3000);
      throw new Error(e.message); // for view to show the latest error
    }
  },

  createDPSuccess(/*settingsJsonSchemaStr: string, settings: string*/) {
    this.changeCreateDPStatus(AsyncOpState.Success);
    setTimeout(() => {
      this.changeCreateDPStatus(INITIAL_ASYNC_OP_STATE);
      // this.updateNewDPFormData({});
    }, 2000);
  },
  changeCreateDPStatus(newStatus: AsyncOpState) {
    self.createNewDPStatus = newStatus;
  },
});
