import { Form, Select } from "antd";
import Tooltip from "../../../../../components/BlingsTooltip";
import { BlingsBtn } from "../../../../../components/antd-extensions/blings-btn.component";
import { observer } from "mobx-react-lite";
import { IProjectModel } from "../../../../../stores/project/projectModel";
import { BlingsContainer } from "../../../../../components/BlingsContainer/BlingsContainer";
import "./LandingPageIntegration.scss";
import { BlingsInput } from "../../../../../components/BlingsInput/BlingsInput";
import { useMst } from "../../../../../stores/Root";
import { UploadFile } from "antd/lib/upload";

const DEFAULT_FAVICON = "https://assets.blings.io/projects/demo/favicon.64c6aba1.ico";
export type PlayerIntegrationData = {
  title: string;
  description: string;
  thumbUrl: string;
  faviconUrl: string;
  alias: string;
  scenes: Array<string>;
};
type Props = {
  project: IProjectModel;
  state: {
    alias: string;
    scenes: Array<string>;
    title: string;
    description: string;
    thumbnail: string;
    favicon: string;
  };
  setState: React.Dispatch<React.SetStateAction<{
    alias: string;
    scenes: Array<string>;
    title: string;
    description: string;
    thumbnail: string;
    favicon: string;
  }>>;
};
export const LandingPageIntegration = observer((props: Props) => {
  const { project, state, setState } = props;
  const { videoPartNames, saveMiniSiteConfig: saveConfig, saveMiniSiteConfigStatus: opStatus } = project;

  const {
    uploadAssetToProject
  }: {
    uploadAssetToProject: (file: File | UploadFile<any>, s3Folder: string, cb?: ((key: string) => void) | undefined, progressCallback?: any) => Promise<void>;
  } = useMst((state) => {
    const { uploadAssetToProject } = state.liveControlStore;
    return { uploadAssetToProject };
  });

  async function save() {
    const { alias, scenes, title, description, thumbnail, favicon } = state;
    const merged: PlayerIntegrationData = { alias, scenes, title, description, thumbUrl: thumbnail, faviconUrl: favicon };
    await saveConfig(merged);
  }
  const { alias, scenes, title, description, thumbnail, favicon } = state;
  return <div className="LandingPageIntegration">
    <div>
      <h1 className="page-subtitle">1. PERSONALIZE YOUR LANDING PAGE HTML</h1>
      <span>Once your users click the personalized link - they can watch their own MP5. We already created a responsive landing page for you. Easily customize it to align with your brand, or download the HTML for full SDK customization. </span>
    </div>
    <div className="body">
      <BlingsContainer>
        <div className="content">
          <div className="form">
            <div>
              <h2 className="form-subtitle">GENERAL</h2>
              <Form>
                <Form.Item>
                  <BlingsInput value={alias} label="URL Alias" onChange={e => setState({ ...state, alias: e })} />
                  {/* <Input value={alias} onChange={e => setState({ ...state, alias: e.target.value })} /> */}
                  <Tooltip className="tooltip-integration" placement="right" content={
                    <ul style={{ maxWidth: "450px" }}>
                      <li><b>What is this:</b> This is what comes after the URL prefix: for example: https://assets.blings.io/projects/dana/index.html</li>
                      <li><b>Keep it short:</b> We recommend keeping this as short as possible.</li>
                      <li><b>Allowed:</b> Lowercase letters, dash (-), under score (_) and or numbers.</li>
                      <li><b>Not Allowed:</b> No spaces, no special characters</li>
                    </ul>} />
                </Form.Item>
                <Form.Item label="Scene Lineup (If needed)">
                  <Select
                    value={scenes}
                    onChange={(value) => setState({ ...state, scenes: value })}
                    mode="multiple"
                    /* allowClear
                    style={{ minWidth: 200 }} */
                  >
                    {videoPartNames.map((videoPartName) => (
                      <Select.Option key={videoPartName} value={videoPartName}>
                        {videoPartName}
                      </Select.Option>
                    ))}
                  </Select>
                  <Tooltip className="tooltip-integration" placement="right" content={<ul style={{ maxWidth: "450px" }} >
                    <li>Some MP5 videos are dynamic and all you need to show is the first scene</li>
                    <li>Some videos require adding a scene lineup - if this is the case add it here according to the order you wish to show it.</li>

                  </ul>} />
                </Form.Item>
              </Form>
            </div>
            <div>
              <h2 className="form-subtitle">META TAGS: SOCIAL SHARING APPEARENCE</h2>
              <span>Control the metatags - how will a post look like what sharing your page.</span>
              <Form>
                <Form.Item>
                  <BlingsInput value={title} label="Page title" onChange={e => setState({ ...state, title: e })} />
                  <Tooltip className="tooltip-integration" placement="right" content={
                    <>
                      <div>Page title is the text that appears in the browser's tab</div>
                      <div className="tab-container">
                        <Tab className="svg" />
                        <div className="content">
                          <img src={favicon || DEFAULT_FAVICON} className="icon" />
                          <span className="title">{title}</span></div>
                      </div>
                    </>} />
                </Form.Item>
                <Form.Item >
                  <BlingsInput value={description} label="Page description" onChange={e => setState({ ...state, description: e })} />
                  <Tooltip className="tooltip-integration" placement="right" content={<></>} />
                </Form.Item>
                <Form.Item >
                  <BlingsInput value={thumbnail} label="Thumbnail to show when sharing" onChange={e => setState({ ...state, thumbnail: e })} uploadAssetsToProject={uploadAssetToProject} />
                  <Tooltip className="tooltip-integration" placement="right" content={<></>} />
                </Form.Item>
                <Form.Item >
                  <BlingsInput value={favicon} label="Favicon: 16x16 pixels icon file" onChange={e => setState({ ...state, favicon: e })} uploadAssetsToProject={uploadAssetToProject} />
                  <Tooltip className="tooltip-integration" placement="right" content={
                    <>
                      <div>Favicon is that little icon that appears in the browser's tab</div>
                      <div className="tab-container">
                        <Tab className="svg" />
                        <div className="content">
                          <img src={favicon || DEFAULT_FAVICON} className="icon" />
                          <span className="title">{title}</span></div>
                      </div>
                    </>}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="footer">
            <span>PREVIEW YOUR PAGE</span>
            <BlingsBtn opState={opStatus} onClick={save}></BlingsBtn>
          </div>
        </div>

      </BlingsContainer>
      <div className="video-container">
        <video src="https://joy1.videvo.net/videvo_files/video/free/2013-08/large_watermarked/hd0992_preview.mp4"></video>
      </div>
    </div>

  </div>;
});

const Tab = ({ className }: { className?: string; }) => {
  return (<svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 964.04 161.79" /* style={"enable-background:new 0 0 964.04 161.79;"} */>
    <style type="text/css">
    </style>
    <g id="Layer_3">
      <path fill="currentColor" d="M933.54,144c0,0-25.41,0.66-25.41-41.58V91.88v-40c0-15.96-13.06-29.01-29.01-29.01H73.14
   c-15.96,0-29.01,13.06-29.01,29.01v40v10.54c0,42.24-25.41,41.58-25.41,41.58h-88.09v75.39H44.13h864h113.51V144H933.54z"/>
    </g>
    <g id="Layer_2">
      <g>
        <line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 6, strokeLinecap: "round", strokeLinejoin: "bevel", strokeMiterlimit: 10 }} x1="842.5" y1="73.24" x2="866.11" y2="96.85" />
        <line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 6, strokeLinecap: "round", strokeLinejoin: "bevel", strokeMiterlimit: 10 }} x1="866.11" y1="73.24" x2="842.5" y2="96.85" />
      </g>
    </g>
  </svg>);

};