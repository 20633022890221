import { UpdateProjectInput, UpdateProjectMutation } from "../../API";
import { API, graphqlOperation } from "aws-amplify";
import { updateProject } from "../../graphql/mutations";
import { IProjectBaseModel } from "./projectModel";

// type x = Pick<IProjectModel, 'model'>

export const saveFileNameActions = (self: IProjectBaseModel) => ({
  updateFileName(fileName: string) {
    self.fileName = fileName;
  },

  async saveFileName(newFileName: string) {
    // this.updateFileName(newFileName);
    try {
      const input: UpdateProjectInput = {
        id: self.id,
        fileName: newFileName || "",
      };
      const resp = (await API.graphql(
        graphqlOperation(updateProject, { input })
      )) as { data: UpdateProjectMutation };
      console.log({ resp });
      this.updateFileName(newFileName);
    } catch (e) {
      console.log("Failed to save fileName", newFileName);
    }
  },
});
