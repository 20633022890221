import { AsyncOpState } from "./types/enums/async-op-states";

export const PLATFORM_QS_CONTROL_STATE = "__blings__platform__state"; // should be same as in player
export const PLATFORM_REST_API = "platformRest";
export const PLATFORM_REST_API__PATH = "/platform";
export const PLATFORM_REST_API__DATA_RECODRS_PATH =
  PLATFORM_REST_API__PATH + "/datarecords";
export const ADMIN_GROUP = "blingsadmins";
export const DEMOS_PLAYER_LINK =
  "https://assets.blings.io/projects/demos/index.html";
export const EXAMPLE_PLAYER_LINK = "https://assets.blings.io/player/index.html";
export const FEEDBACK_TIME = 1000;
export const FEEDBACK_OPTIONS = {
  [AsyncOpState.Changed]: "Save",
  [AsyncOpState.Saving]: "Saving",
  [AsyncOpState.Success]: "Saved",
  [AsyncOpState.Error]: "Error",
}