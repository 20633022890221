/// <reference path="../custom.d.ts" />
import React from "react";
import { InternalSchemaTypes } from "../helpers";
import ImageSVG from "./image.svg";
import TextSVG from "./text.svg";
import VideoSVG from "./video.svg";
import LinkSVG from "./link.svg";
import NumberSVG from "./number.svg";
import AudioSVG from "./audio.svg";
import ColorSVG from "./color.svg";
import BooleanSVG from "./boolean.svg";
import InfoSVG from "./info.svg";
import EditSVG from "./edit.svg";
import DeleteSVG from "./delete.svg";
import CopySVG from "./copy.svg";
import ClearSVG from "./clear.svg";
import CheckSVG from "./check.svg";
import VideoThumbSVG from "./video-thumbnail.svg";
import ImageThumbSVG from "./image-thumbnail.svg";
import VideoThumbReadonlySVG from "./video-thumbnail-readonly.svg";
import ImageThumbReadonlySVG from "./image-thumbnail-readonly.svg";

const ImageIcon = ({ size = 1 }) => (
  <ImageSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
const TextIcon = ({ size = 1 }) => (
  <TextSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
const VideoIcon = ({ size = 1 }) => (
  <VideoSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
const LinkIcon = ({ size = 1 }) => (
  <LinkSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
const NumberIcon = ({ size = 1 }) => (
  <NumberSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
const AudioIcon = ({ size = 1 }) => (
  <AudioSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
const ColorIcon = ({ size = 1 }) => (
  <ColorSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
const BooleanIcon = ({ size = 1 }) => (
  <BooleanSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);

export const InfoIcon = ({ size = 1 }) => (
  <InfoSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
export const EditIcon = ({ size = 1 }) => (
  <EditSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
export const DeleteIcon = ({ size = 1 }) => (
  <DeleteSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
export const CopyIcon = ({ size = 1 }) => (
  <CopySVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
export const ClearIcon = ({ size = 1 }) => (
  <ClearSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
export const CheckIcon = ({ size = 1 }) => (
  <CheckSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
export const VideoThumbIcon = ({ size = 1 }) => (
  <VideoThumbSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
export const ImageThumbIcon = ({ size = 1 }) => (
  <ImageThumbSVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
export const VideoThumbReadonlyIcon = ({ size = 1 }) => (
  <VideoThumbReadonlySVG width={`${10 * size}px`} height={`${10 * size}px`} />
);
export const ImageThumbReadonlyIcon = ({ size = 1 }) => (
  <ImageThumbReadonlySVG width={`${10 * size}px`} height={`${10 * size}px`} />
);

export function iconFromInternalType(type: InternalSchemaTypes, size = 1) {
  switch (type) {
    case InternalSchemaTypes.BOOLEAN:
      return <BooleanIcon size={size} />;
    case InternalSchemaTypes.AUDIO:
      return <AudioIcon size={size} />;
    case InternalSchemaTypes.COLOR:
      return <ColorIcon size={size} />;
    case InternalSchemaTypes.IMAGE:
      return <ImageIcon size={size} />;
    case InternalSchemaTypes.LINK:
      return <LinkIcon size={size} />;
    case InternalSchemaTypes.NUMBER:
      return <NumberIcon size={size} />;
    case InternalSchemaTypes.TEXT:
      return <TextIcon size={size} />;
    case InternalSchemaTypes.VIDEO:
      return <VideoIcon size={size} />;
  }
}
