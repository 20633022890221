
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Instance, types } from "mobx-state-tree";

export const uiModel =
  // Define the store structure to be used in the mobx-state-tree store
  types
    .model("NotificationStore", {
      isPsychedelic: types.optional(types.boolean, false),
    })
    // Define the actions that can be performed on the store
    .actions((self) => ({
      setIsPsychedelic(isPsychedelic: boolean) {
        self.isPsychedelic = isPsychedelic;
      },
    }));
