import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Input, Form, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./AddOptionsForm.module.scss";
import ReactDOM from "react-dom";
import UploadButton from "./UploadButton";
import MediaContent from "./MediaContent";
import TextArea from "antd/lib/input/TextArea";
import { AUDIO, IMAGE, UPLOADING, VIDEO } from "./consts";

const AddOptionsFormWrapper = (props: any) => {
  const { setValuesInForm, ValuesInForm, format, container } = props;
  if (container) {
    return ReactDOM.createPortal(
      <AddOptionsForm
        setValuesInForm={setValuesInForm}
        ValuesInForm={ValuesInForm}
        format={format}
      />,
      container
    );
  } else {
    return <></>;
  }
};
const AddOptionsForm = (props: any) => {
  const { setValuesInForm, ValuesInForm, format } = props;
  const [values, setValues] = useState(
    ValuesInForm.length ? ValuesInForm : [{ title: "", const: "" }]
  );
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ values: values });
  }, [values]);
  return (
    <Form
      className={styles.AddOptionsForm}
      form={form}
      name="dynamic_form_nest_item"
      autoComplete="off"
      onValuesChange={(e, e2) => {
        const validValues: any = [];
        setValues(e2.values);
        e2.values.forEach((value: any) => {
          if (value && value.const && value.title) {
            validValues.push(value);
          }
        });
        setValuesInForm(validValues);
      }}
    >
      <Form.List name="values">
        {(fields, { add, remove }) => (
          <Space
            direction="vertical"
            size="large"
            className={styles.VerticalSpace}
          >
            {fields.map(({ key, name, ...restField }, i) => (
              <div key={i} className={styles.Field}>
                <div className={styles.InFieldRow}>
                  <div className={styles.InFieldColumn}>
                    <Form.Item
                      className={styles.InputForm}
                      {...restField}
                      name={[name, "title"]}
                    >
                      <Input placeholder="Add option name" />
                    </Form.Item>
                    <div className={styles.TextAreaCard}>
                      <Form.Item
                        className={styles.InputForm}
                        {...restField}
                        name={[name, "const"]}
                      >
                        <TextArea
                          autoSize={{ minRows: 1, maxRows: 3 }}
                          placeholder={
                            "Add a link" +
                            (format === AUDIO ||
                            format === VIDEO ||
                            format === IMAGE
                              ? " or upload a file"
                              : "")
                          }
                        />
                      </Form.Item>

                      {(format === AUDIO ||
                        format === VIDEO ||
                        format === IMAGE) && (
                        <UploadButton
                          format={format}
                          setValues={setValues}
                          index={name}
                          values={values}
                          urlFunction={fileUrl => {
                            if (!ValuesInForm[name]) {
                              ValuesInForm[name] = {
                                title:
                                  values[name] && values[name].title
                                    ? values[name].title
                                    : "",
                                const: fileUrl,
                              };
                            } else {
                              ValuesInForm[name].const = fileUrl;
                            }
                            setValues(ValuesInForm);
                            setValuesInForm(ValuesInForm);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {(format === AUDIO || format === VIDEO || format === IMAGE) &&
                    values[name] &&
                    values[name].const &&
                    !values[name].const.startsWith(UPLOADING) && (
                      <MediaContent
                        src={values && values[name] ? values[name].const : name}
                        format={format}
                      />
                    )}
                  <div>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </div>
                </div>
              </div>
            ))}
            <Form.Item>
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  add();
                }}
              >
                Add an Option
              </Button>
            </Form.Item>
          </Space>
        )}
      </Form.List>
    </Form>
  );
};

export default AddOptionsFormWrapper;
