import React from "react";
import { Select } from "antd";
import { JSONSchema7 } from "json-schema";
import { iconFromInternalType } from "./Icons";
import baseStyles from "./BaseStyles.module.scss";

export const selectOptionsFromObject = (obj: any) => {
  return Object.entries(obj).map(entry => {
    const [key, value] = entry;
    if (typeof value === "string")
      return (
        <Select.Option key={key} value={key}>
          <div className={baseStyles.TypeIcon}>
            {iconFromInternalType(key as InternalSchemaTypes, 1.7)}
            <span>{value}</span>
          </div>
        </Select.Option>
      );
    else
      return (
        <Select.OptGroup
          key={key}
          label={key}
          children={selectOptionsFromObject(value)}
        ></Select.OptGroup>
      );
  });
};
export enum InternalSchemaTypes {
  TEXT = "text",
  //INTEGER = "integer",
  COLOR = "color",
  LINK = "link",
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  BOOLEAN = "boolean",
  NUMBER = "number",
}

export const defaultValuesFromType = {
  [InternalSchemaTypes.TEXT]: "",
  [InternalSchemaTypes.LINK]: "",
  [InternalSchemaTypes.IMAGE]: "",
  [InternalSchemaTypes.VIDEO]: "",
  [InternalSchemaTypes.AUDIO]: "",
  [InternalSchemaTypes.COLOR]: "#f03564",
  [InternalSchemaTypes.BOOLEAN]: false,
  [InternalSchemaTypes.NUMBER]: 0,
};

export function typeAndFormatFromInternalType(
  internalType: InternalSchemaTypes
) {
  if (
    internalType === InternalSchemaTypes.AUDIO ||
    internalType === InternalSchemaTypes.IMAGE ||
    internalType === InternalSchemaTypes.LINK ||
    internalType === InternalSchemaTypes.VIDEO ||
    internalType === InternalSchemaTypes.TEXT ||
    internalType === InternalSchemaTypes.COLOR
  ) {
    return { format: internalType, type: "string" };
  }
  return { type: internalType };
}
export const SCHEMA_TYPES_STRUCTURE = {
  [InternalSchemaTypes.TEXT]: "Text",
  //[INTERNAL_SCHEMA_TYPES.INTEGER]: "Number",
  [InternalSchemaTypes.NUMBER]: "Number",
  [InternalSchemaTypes.COLOR]: "Color",
  [InternalSchemaTypes.LINK]: "Link",
  [InternalSchemaTypes.BOOLEAN]: "Boolean",
  Media: {
    [InternalSchemaTypes.IMAGE]: "Image",
    [InternalSchemaTypes.VIDEO]: "Video",
    [InternalSchemaTypes.AUDIO]: "Audio",
  },
};
export function internalSchemaTypeFromSchema(schema: JSONSchema7) {
  switch (schema.type) {
    case "string":
      if (schema.format === "video") return InternalSchemaTypes.VIDEO;
      if (schema.format === "image") return InternalSchemaTypes.IMAGE;
      if (schema.format === "audio") return InternalSchemaTypes.AUDIO;
      if (schema.format === "link") return InternalSchemaTypes.LINK;
      if (schema.format === "color") return InternalSchemaTypes.COLOR;
      return InternalSchemaTypes.TEXT;
    case "integer":
    case "number":
      //return INTERNAL_SCHEMA_TYPES.INTEGER;
      return InternalSchemaTypes.NUMBER;
    case "boolean":
      return InternalSchemaTypes.BOOLEAN;
    default:
      return InternalSchemaTypes.TEXT;
  }
}

export function getSchemaFromSchemaPath(
  object: any,
  path: string[],
  index = 1
): { object: any; key: string } {
  if (index >= path.length - 1) return { object, key: path[index] };
  object = object.properties[path[index]];
  if (!object) throw new Error("Invalid path");
  return getSchemaFromSchemaPath(object, path, ++index);
}
export function setFormDataValueFromSchemaPath(
  object: any,
  path: string[],
  value: any,
  index = 1
) {
  if (index >= path.length - 1) {
    object[path[index]] = value;
    return;
  }
  object = object[path[index]];
  if (!object) return;
  setFormDataValueFromSchemaPath(object, path, value, ++index);
}
export function getFormDataValueFromSchemaPath(
  object: any,
  path: string[],
  index = 1
): any {
  if (index >= path.length - 1) return object[path[index]];
  object = object[path[index]];
  if (!object) return "";
  return getFormDataValueFromSchemaPath(object, path, ++index);
}

export function deleteFormDataValueFromSchemaPath(
  object: any,
  path: string[],
  index = 1
) {
  if (index >= path.length - 1) {
    delete object[path[index]];
    return;
  }
  object = object[path[index]];
  if (!object) return;
  deleteFormDataValueFromSchemaPath(object, path, ++index);
}
