/* eslint-disable react/jsx-no-comment-textnodes */

import { Button, Card } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../helpers/CopyToClipboard";
import { jsonSchemaGetExamples } from "../../helpers/jsonSchema.helpers";
import { IProjectModel } from "../../stores/project/projectModel";
import { getENV } from "../../config";

type Props = {
  project: IProjectModel;
};

export const SDKInstructions = ({ project }: Props) => {
  const data = JSON.stringify(
    jsonSchemaGetExamples(JSON.parse(project.stateJsonSchemaStr || "{}"))
  )
    .replaceAll(",", ",\n\t")
    .replace("{", "{\n\t")
    .replace("}", "\n}");

  const scenes = !project.videoPartNames
    ? '""'
    : JSON.stringify(project.videoPartNames?.map((vp) => vp || ""))
      .replaceAll(",", ",\n\t")
      .replace("[", "[\n\t")
      .replace("]", "\n]");

  return (
    <Card
      type={"inner"}
      style={{ background: "#d9d9d9", width: "50%", minWidth: "43em" }}
    >
      <pre
        className="language-jsx"
        style={{
          background: "#d9d9d9",
          marginBottom: "1%",
          marginTop: "1%",
        }}
      >
        <code>
          <p id="code2" style={{ fontSize: "0.9em" }}>
            {`var settings = {
  `}
            <span className={"code-comment"}>
              // Blings video will replace an HTML element on screen
            </span>
            {`
  container: document.getElementById("YOUR-HTML-ELEMENT"), 
  `}
            <span className={"code-comment"}>
              // This option creates a full-screen video over black background
            </span>
            {`
  cinematic: true  
};  

`}
            {data !== "{\n\t\n}" ? (
              <>
                <span className={"code-comment"}>
                  {`// This is the data that will be used in the video. 
// You can gather the data from any resource or API in real time
// and replace the values listed below. 
// This data is never exposed to Blings.io!`}
                </span>
                {`\nvar data = ${data};\n\n`}
              </>
            ) : (
              ""
            )}
            <span className={"code-comment"}>
              // Define the scenes from which the video will be created
            </span>
            {`
var scenes = ${scenes}

`}
            <span className={"code-comment"}>// Finally create the video</span>
            {`
BlingsPlayer.create({
    project: { id: "${project.id}"` +
              (getENV() === "dev" ? `, env: "dev"` : ``) +
              ` },${data !== "{\n\t\n}" ? "\n    data," : ""}
    settings,
    scenes
});`}
          </p>
        </code>
      </pre>
      <Button
        type="primary"
        icon={<CopyOutlined />}
        style={{ float: "right" }}
        onClick={() => {
          copyToClipboard("code2");
        }}
      />
    </Card>
  );
};
