import burgerAnim from "../../assets/burger.json";
import {
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import {
  toPath,
  PATHS,
  PROJECT_PATHS,
  camelCaseToRegular,
  PROJECT_PATHS_HIDE_EDITVIDEO,
} from "../../PATHS";
import "./NavBar.scss";
import { IProjectModel } from "../../stores/project/projectModel";
import { observer } from "mobx-react-lite";
import { rootStore, useMst } from "../../stores/Root";
import NotificationBellWrapper from "../../components/notification/notificationBell";
import { useRef } from "react";
import { HoverableLottie } from "../../helpers/HoverableLottie";
import { Button, Layout, Dropdown, Popover, Select } from "antd";
import { useState } from "react";
import { AsyncOpState } from "../../types/enums/async-op-states";
import staticText from "../../utils/staticText";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { dateFormatter } from "../../helpers/timeAgo.helpers";
import { getENV } from "../../config";
import { CaretDownOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { BlingsButton } from "../../components/BlingsButton/BlingsButton";
import { BlingsLogo, SettingsIcon } from "../../assets/Icons";
import LoadingSpinner from "../../assets/Ellipse 7.png";
import { ProjectSearch } from "../ProjectsList/ProjectSearch";
type Props = {
  project: IProjectModel | undefined;
  projects: IProjectModel[];
  isLoading: boolean;
  operation: string | undefined;
  isPsychedelic: boolean;
  setSearchView: (searchView: boolean) => void;
  setCurrentSearch: (searchString: string) => void;
};
export const EXAMPLE_PLAYER_LINK = "https://assets.blings.io/player/index.html";

const NavBarWrapper = observer(() => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  // Get operation from url
  const operation = location.pathname.split("/")?.[3];
  const state = useMst((store) => {
    return {
      project: id ? store.projectsStore.selectedProject : undefined,
      isLoading: store.projectsStore.projectIdCurrentlyLoading,
      operation,
      projects: store.projectsStore.projects,
      setSearchView: store.editVideoStore.setSearchView,
      setCurrentSearch: store.editVideoStore.setCurrentSearch,
      isPsychedelic: store.uiStore.isPsychedelic,
    };
  });
  return <NavBar {...state} />;
});

const NavBar = ({
  project,
  projects: allProjects,
  operation,
  isPsychedelic,
  isLoading,
  setSearchView,
  setCurrentSearch,
}: Props) => {
  const ddRef = useRef<HTMLDivElement>(null);
  const history = useNavigate();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [projectHover, setProjectHover] = useState(false);
  const overlaySettings = (
    <div
      style={{
        background: "white",
        border: "1px solid #C5C5C5",
        padding: "1rem",
        borderRadius: "4px",
      }}
    >
      <Link to={toPath(PATHS.data)}>
        <p>Data Uploads</p>
      </Link>
      <Link to={toPath(PATHS.settings)}>
        <p>Account Settings</p>
      </Link>
    </div>
  );

  const overLayLogo = (
    <div
      style={{
        background: "white",
        border: "1px solid #C5C5C5",
        padding: "1rem",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        borderRadius: "4px",
      }}
    >
      <Link to={toPath(PATHS.create_new_project)}>
        <p>
          <PlusSquareOutlined /> New Project
        </p>
      </Link>
      <BlingsButton
        outline
        onClick={() => rootStore.accountStore.signOut(history)}
      >
        log out
      </BlingsButton>
    </div>
  );
  const [scenes, setScenes] = useState<string[]>([]);
  const [publishButtonState, setPublishButtonState] = useState<AsyncOpState>(
    AsyncOpState.Changed
  );
  const publishButtonTexts = {
    [AsyncOpState.Changed]: "Publish",
    [AsyncOpState.Saving]: "Publishing",
    [AsyncOpState.Success]: "Published",
    [AsyncOpState.Error]: "Error",
  };

  /**
   *  Check if project has nav item to render in the navbar
   * @param navItem
   * @returns
   */
  const projectHasNavItem = (navItem: string) => {
    switch (navItem) {
      case PROJECT_PATHS.customAnalytics:
        return !!project?.analyticsReportUrl;
      case PROJECT_PATHS.analytics:
        return project?.analyticsEnabled && !project.analyticsReportUrl;
      case PROJECT_PATHS.liveControl:
        let hasControl = false;
        try {
          if (project?.settingsJsonSchemaStr) {
            const control = JSON.parse(project.settingsJsonSchemaStr);
            if (control && control.properties) {
              hasControl = JSON.stringify(control.properties) !== "{}";
            }
          }
        } catch (e) {
          console.error(e);
        }
        return !!hasControl;
      case PROJECT_PATHS.createDataPoint:
        return !!project?.allowCreateDataPoint;
      default:
        return true;
    }
  };
  function openLiveVersion() {
    let url = `${EXAMPLE_PLAYER_LINK}?env=${getENV()}&p=${project?.id || ""}`;
    if (scenes) {
      url += `&scenes=${encodeURIComponent(scenes.join())}`;
    }
    const publishedExampleData = project?.stateJsonSchemaStr
      ? JSON.parse(project.stateJsonSchemaStr).examples[0]
      : null;
    if (publishedExampleData) {
      url += `&data=${encodeURIComponent(
        JSON.stringify(publishedExampleData)
      )}`;
    }
    window.open(url, "_blank");
  }

  const save = async () => {
    try {
      setPublishButtonState(AsyncOpState.Saving);
      await project?.publishProject();
      setPublishButtonState(AsyncOpState.Changed);
    } catch (e) {
      setPublishButtonState(AsyncOpState.Error);
    }
  };

  return (
    <>
      <Layout>
        <Layout.Header className={"Main Header"}>
          <div className={"NavBar"}>
            <Link to={"/"} className="LogoLink">
              <div style={{ color: isPsychedelic ? "orange" : "#FF154D" }}>
                <BlingsLogo />
              </div>
            </Link>
            {project ? (
              <>
                <Popover
                  mouseEnterDelay={0}
                  placement="bottomRight"
                  overlayClassName="overlayClass"
                  content={
                    <ProjectSearch
                      projects={allProjects}
                      setPopoverOpen={setPopoverOpen}
                      popoverOpen={popoverOpen}
                    />
                  }
                  trigger="click"
                  visible={popoverOpen}
                  onVisibleChange={setPopoverOpen}
                >
                  <div
                    className="ProjectTitle"
                    onMouseEnter={() => setProjectHover(true)}
                    onMouseLeave={() => setProjectHover(false)}
                  >
                    {project.title}{" "}
                    <CaretDownOutlined
                      style={{
                        opacity: projectHover || popoverOpen ? "1" : "0",
                      }}
                    />
                  </div>
                </Popover>
                {Object.entries(PROJECT_PATHS_HIDE_EDITVIDEO).map(
                  (navItem) =>
                    projectHasNavItem(navItem[1]) && (
                      <NavLink
                        key={toPath(navItem[1])}
                        // {...(navItem[1] === PROJECT_PATHS.editVideo && {
                        //   onClick: () => {
                        //     setCurrentSearch("");
                        //     setSearchView(true);
                        //   },
                        // })}
                        id={camelCaseToRegular(navItem[0])}
                        to={toPath(PATHS.project, project.id, navItem[1])}
                        className={({ isActive }) =>
                          [
                            "NavItem",
                            isActive
                              ? // ||
                                // (operation === "edit-video" &&
                                //   navItem[1] === "edit-video")
                                "Selected"
                              : "",
                          ].join(" ")
                        }
                      >
                        {camelCaseToRegular(navItem[0])}
                      </NavLink>
                    )
                )}
              </>
            ) : (
              <NavLink
                key={toPath(PATHS.home)}
                id="Blings Platform"
                to={toPath(PATHS.home)}
                className={({ isActive }) =>
                  ["NavItem", "HomePage", isActive ? "Selected" : ""].join(" ")
                }
              >
                Blings Platform
              </NavLink>
            )}
            <div className="RightNav">
              {project ? (
                <>
                  <div id="ExtraInfo" title={new Date(project?.updatedAt || "").toString()}>
                    {staticText.header.VERSION(project.isLiveVersion)}{" "}
                    {staticText.header.LAST_EDIT}
                    {project?.updatedAt
                      ? dateFormatter(new Date(project?.updatedAt))
                      : "done"}{" "}
                    {/* {staticText.header.BY_USER("User")} */}
                  </div>
                  <div className="notificationIcon">
                    <NotificationBellWrapper />
                  </div>
                  <div id="PreviewButton">
                    <Popover
                      overlayClassName="view-live-version-popover"
                      placement="bottomRight"
                      title="Add scenes"
                      content={
                        <div>
                          <Select
                            id="view-live-select"
                            mode="multiple"
                            onChange={setScenes}
                            value={scenes}
                            className="view-live-version-select"
                          >
                            {project?.videoPartNames?.map((vp) => (
                              <Select.Option key={vp} value={vp}>
                                {vp}
                              </Select.Option>
                            ))}
                          </Select>
                          <Button type="primary" onClick={openLiveVersion}>
                            Go
                          </Button>
                        </div>
                      }
                      trigger="click"
                    >
                      <BlingsButton
                        className="LiveVersion ButtonEditVideo"
                        outline
                        onClick={() => {
                          console.log("Open popover");
                        }}
                      >
                        <>
                          {staticText.header.LIVE_VERSION} <CaretDownOutlined />
                        </>
                      </BlingsButton>
                    </Popover>
                  </div>
                  {operation === "edit-video" && (
                    <div id="PublishButton">
                      <BlingsBtn
                        className={"publish ButtonEditVideo"}
                        opState={publishButtonState}
                        htmlType={"submit"}
                        btnTexts={publishButtonTexts}
                        onClick={save}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Dropdown placement="bottomLeft" overlay={overlaySettings}>
                    <div className="settings">
                      <SettingsIcon />
                    </div>
                  </Dropdown>
                  <div className="notificationIcon">
                    <NotificationBellWrapper />
                  </div>
                  <Dropdown placement="bottomLeft" overlay={overLayLogo}>
                    <div id="Logo">
                      <span ref={ddRef}>
                        <HoverableLottie
                          hoverableElement={ddRef}
                          animationData={burgerAnim}
                          size={32}
                        />
                      </span>
                    </div>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </Layout.Header>
        <Layout.Content className={"Main-Content"}>
          <div className="inner-div">
            <Outlet />
          </div>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default NavBarWrapper;
