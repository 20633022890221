import { types } from "mobx-state-tree";
import { API, Auth } from "aws-amplify";

const adminApiName = "AdminQueries";

interface ICognitoGroupRespSingle {
  CreationDate: string;
  GroupName: string;
  LastModifiedDate: string;
  Precedence: number;
  RoleArn: string;
  UserPoolId: string;
}
type AwsICognitoGroupResp = {
  Groups: ICognitoGroupRespSingle[];
  NextToken: string;
};

export const adminModel = types
  .model("adminStore", {
    groups: types.optional(types.array(types.string), []),
    // isLoadingDataUploads: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setGroups(groupsFullList: ICognitoGroupRespSingle[]) {
      self.groups.replace(groupsFullList.map((g) => g.GroupName));
    },
  }))
  .actions((self) => ({
    async getGroupsList() {
      const groups: Array<ICognitoGroupRespSingle> = [];
      let res: AwsICognitoGroupResp | undefined;
      do {
        const nextTokenParam =
          res != null ? `&token=${encodeURIComponent(res.NextToken)}` : "";

        res = (await API.get(
          adminApiName,
          `/listGroups?limit=60${nextTokenParam}`,
          {
            headers: {
              Authorization: `${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            },
          }
        )) as AwsICognitoGroupResp;
        groups.push(...res.Groups);
      } while (res.NextToken);
      self.setGroups(groups);
      console.log(groups); // res
    },
    async createGroup(groupName: string) {
      await API.post(adminApiName, "/createGroup", {
        body: { group: groupName },
        headers: {
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      });
      await this.getGroupsList();
    },
    async createUser(email: string, groupName: string, password: string) {
      const res = await API.post(adminApiName, "/createUser", {
        body: { group: groupName, password, email, username: email },
        headers: {
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      });
      return res;
    },
  }));
