import React from "react";
export const PlusIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 4V8"
        stroke="currentColor"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <path
        d="M4 6H8"
        stroke="currentColor"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <path
        d="M0.5 6C0.5 4.63407 0.534512 3.61416 0.66293 2.84099C0.79077 2.0713 1.00216 1.60994 1.30605 1.30605C1.60994 1.00216 2.0713 0.79077 2.84099 0.66293C3.61416 0.534512 4.63407 0.5 6 0.5C7.36593 0.5 8.38584 0.534512 9.15901 0.66293C9.9287 0.79077 10.3901 1.00216 10.6939 1.30605C10.9978 1.60994 11.2092 2.0713 11.3371 2.84099C11.4655 3.61416 11.5 4.63407 11.5 6C11.5 7.36593 11.4655 8.38584 11.3371 9.15901C11.2092 9.9287 10.9978 10.3901 10.6939 10.6939C10.3901 10.9978 9.9287 11.2092 9.15901 11.3371C8.38584 11.4655 7.36593 11.5 6 11.5C4.63407 11.5 3.61416 11.4655 2.84099 11.3371C2.0713 11.2092 1.60994 10.9978 1.30605 10.6939C1.00216 10.3901 0.79077 9.9287 0.66293 9.15901C0.534512 8.38584 0.5 7.36593 0.5 6Z"
        stroke="currentColor"
      />
    </svg>
  );
};
