import Auth from "@aws-amplify/auth";
import Amplify, { API, PubSub, Storage } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react"; // not fully customized yet. Maybe in the future (31/1/21), or change to AmplifyAuthenicator
import LogRocket from "logrocket";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import config from "../aws-exports";
import { PATHS, PROJECT_PATHS, toPath } from "../PATHS";
import { Provider, rootStore } from "../stores/Root";
import AccountSettings from "./AccountSettings/AccountSettings";
import ProjectView from "./ProjectView/ProjectView";
import ProjectsGallery from "./ProjectsList/ProjectsGallery";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { ProjectCreatePage } from "./ProjectCreateAndUpdate/ProjectCreatePage";
import "./App.scss";
import { OAuthCallback } from "./Integrations/OAuthCallback";
import NavBar from "./NavBar/NavBar";
import DataUploads from "./DataUploads/DataUploads";

const config3 = {
  ...config,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  authorizationType: "AMAZON_COGNITO_USER_POOLS",
  // FOR ANY SUBSCRIPTION TO WORK, THIS NEEDS TO BE SET TO FALSE
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing: false,
};

API.configure(config3); // Configure Amplify
PubSub.configure(config3);
Amplify.configure(config3);
Storage.configure(config3);
Auth.configure(config3);
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "eu-west-1",
    aws_pubsub_endpoint:
      "wss://aa548ngrivwoo-ats.iot.eu-west-1.amazonaws.com/mqtt",
  })
);

// console.log({ config3 });
async function addPubSubPermission() {
  const { identityId } = await Auth.currentCredentials();
  const apiName = "addPubSubPermission";
  const path = "/addPubSubPermission";
  const myInit = {
    crossDomain: true,
    body: { cognitoIdentityId: identityId },
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };
  try {
    await API.post(apiName, path, myInit);
  } catch (e) {
    console.error(e);
  }
}

const App: React.FC = () => {
  useEffect(() => {
    window.addEventListener(
      "keypress",
      (function () {
        const strToType = "thingsandstuff";
        let strTyped = "";
        return function (event) {
          const character = String.fromCharCode(event.which);
          strTyped += character;
          if (strToType.indexOf(strTyped) === -1) strTyped = "";
          else if (strTyped === strToType) {
            const themeStyle = document.createElement("style");
            (window as any).themeS = themeStyle;
            themeStyle.innerHTML = `*::-webkit-scrollbar-thumb {
              // background-color: black !important;
              background: linear-gradient(178deg, #9a6646, #c6b548) !important; 
              border-radius: 16px !important;
              border: 4px solid #fff !important;
          } :root {--blings_pink: orange; --blings_background3:linear-gradient(178deg, #9062c4, #32e210);}
           .ant-layout-header {
            background: linear-gradient(139deg, #7d17d7, #d5986b);
          }`;
            document.body.append(themeStyle);
            rootStore.uiStore.setIsPsychedelic(true);
            strTyped = "";
          }
        };
      })()
    );
    (async () => {
      await rootStore.accountStore.loadAccountData();
      await rootStore.notificationStore.init();
      addPubSubPermission();
    })();
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      LogRocket.init("xodu2t/blings_platform-q7eko");
      (async () => {
        const data = await Auth.currentUserInfo();
        LogRocket.identify("USER_IN_APP_ID", {
          name: data.username,
          email: data.attributes.email,
          id: data.id,
        });
      })();
    }
  }, []);

  return (
    <Provider value={rootStore}>
      <Router>
        <Routes>
          <Route path="/" element={<NavBar />}>
            <Route index element={<ProjectsGallery />} />
            <Route
              path={toPath(PATHS.settings)}
              element={<AccountSettings />}
            />
            <Route path={toPath(PATHS.data)} element={<DataUploads />} />
            <Route
              path={toPath(PATHS.create_new_project)}
              element={<ProjectCreatePage />}
            />
            <Route
              path={toPath(PATHS.project, ":id", "*")}
              element={<ProjectView />}
            />

            <Route
              path={toPath(PATHS.project, ":id")}
              element={<Navigate to={PROJECT_PATHS.demo} replace />}
            />
            <Route
              path={toPath(PATHS.oauthCallback, ":platform")}
              element={<OAuthCallback />}
            />
            {/* <AdminRoute path={toPath(PATHS.admin)} component={AdminPage} /> */}
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>

      <ToastContainer
        position="bottom-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Provider>
  );
};

export default withAuthenticator(App);
