import { CtaPerScene } from "../Analytics";
import { Table } from "antd";
export const TopCtas = ({ data }: { data?: Array<CtaPerScene> }) => {
  if (!data) return null;
  const ctas: { [key: string]: number } = {};
  if (data.length) {
    data.forEach((scene) => {
      scene.perCta.buckets.forEach(
        (cta) =>
          (ctas[cta.key] = ctas[cta.key]
            ? ctas[cta.key] + cta.doc_count
            : cta.doc_count)
      );
    });
  }
  const processedData = Object.entries(ctas)
    .map((cta) => ({ key: cta[0], doc_count: cta[1] }))
    .filter((cta) => cta.key !== "no-cta");
  console.log(processedData);
  const totalCtas = processedData.reduce(
    (prev, curr) => prev + curr.doc_count,
    0
  );
  return processedData.length ? (
    <div className="analytics-view top-cta" style={{ height: "100%" }}>
      <div className="view-title">
        <h2>Top CTAs</h2>
        <h2>
          Total <span>{totalCtas}</span>
        </h2>
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <Table
          pagination={false}
          style={{ height: "350px", overflow: "hidden" }}
          scroll={{ y: "300px" }}
          dataSource={processedData}
          columns={[
            {
              title: "Name",
              key: "key",
              dataIndex: "key",
            },
            {
              title: "Clicks",
              key: "doc_count",
              dataIndex: "doc_count",
            },
          ]}
        />
      </div>
    </div>
  ) : null;
};
