import { SessionsPerOsProps } from "../Analytics";
import { theme } from "../../../theme";
import { ResponsivePie } from "@nivo/pie";
import { TooltipPie } from "./Tooltips";

export const SessionsPerOs = ({ data }: { data?: SessionsPerOsProps; }) => {
  if (!data) return null;
  const total = data.reduce((prev, curr) => prev + curr.doc_count, 0);
  const processedData = data.map((d) => ({
    id: d.key,
    value: d.doc_count,
    percentage: (d.doc_count * 100 / total).toFixed(0),
  }));

  const colors = [
    theme.primary,
    "#FF8AA6",
    "#FFD0DB",
    "#FFE8ED",
    theme.blings_teal,
    "#9BA0A2",
    "#D7D9DA",
    "#EAECEC",
  ];
  return (
    <div style={{ height: "350px" }} className="sessions-os analytics-view">
      <h2>View per OS</h2>
      <ResponsivePie
        data={processedData}
        innerRadius={0.25}
        sortByValue={true}
        colors={(r) =>
          colors[
          processedData.findIndex((d) => d.id === r.id) % processedData.length
          ] || theme.primary
        }
        animate={true}
        fit={true}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={8}
        legends={[
          {
            data: processedData.map((d) => ({
              id: d.id,
              label: `${d.id}: ${d.value} (${d.percentage}%)`,
              color:
                colors[
                processedData.findIndex((e) => e.id === d.id) %
                processedData.length
                ] || theme.primary,
            })),
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 55,
            translateY: 30,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 25,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
        margin={{
          left: 50,
          right: 100,
          top: 10,
          bottom: 100,
        }}
        tooltip={TooltipPie}
      />
    </div>
  );
};
