import { types, Instance, getRoot } from "mobx-state-tree";
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import { getAccount } from "../graphql/queries";
import { PATHS, toPath } from "../PATHS";
import { GetAccountQuery, UpdateAccountMutation } from "../API";
import { updateAccount } from "../graphql/mutations";
import { ADMIN_GROUP } from "../consts";
import { AccountProps, FileUploadProps } from "./platformSchema";
import { IRootModel } from "./Root";
import { ITokenResponseIF } from "../view/Integrations/OAuthCallback";
import { NavigateFunction } from "react-router";

const accountSettingsModel = types.model({
  // id: types.string,
  // name: types.string,
  ...AccountProps,
  // phone: types.maybeNull(types.string)
});
export type IAccountSettingsModel = Instance<typeof accountSettingsModel>;

const userAttributesModel = types.model({
  email: types.string,
  phone_number: types.maybeNull(types.string),
});
export type IUserAttributesModel = Instance<typeof userAttributesModel>;

const fileUploadModel = types.model({
  ...FileUploadProps,
  // originalName: types.string,
  createdAt: types.string,
  fileName: types.string,
  // fileStatus: types.string,
  // fileError: types.maybeNull(types.string),
  // numRecords: types.maybeNull(types.number),
  // numErrors: types.maybeNull(types.number),
});
export type IFileUploadModel = Instance<typeof fileUploadModel>;
export type IFileUploadsModel = Instance<typeof fileUploadModel>[];

export const accountModel = types
  .model("accountStore", {
    cognitoGroupId: types.maybe(types.string),
    accountSettings: types.maybe(accountSettingsModel),
    userAttributes: types.maybe(userAttributesModel),
    fileUploads: types.optional(types.array(fileUploadModel), []),
    hubspotConnection: types.maybeNull(types.string),
  })
  .views((self) => ({
    get isAdmin() {
      return self.cognitoGroupId === ADMIN_GROUP;
    },
    get hubspotCredentialsAreValid() {
      if (!self.hubspotConnection) return false;
      const cred = JSON.parse(self.hubspotConnection);
      if (Date.now() >= cred.updatedAt + cred.expiresIn * 1000) {
        return false;
      }
      return true;
    },
  }))
  .actions((self) => ({
    setGroupId(cognitoGroupId: string) {
      self.cognitoGroupId = cognitoGroupId;
    },
    setUserAttributes(attr: IUserAttributesModel) {
      // const {email} = attr
      self.userAttributes = attr;
    },
    setAccountData(accountSettings: IAccountSettingsModel) {
      self.accountSettings = accountSettings;
    },
    setFileUploads(fileUploads: any) {
      console.log(fileUploads);
      self.fileUploads.replace(fileUploads);
    },
  }))
  .actions((self) => ({
    async signOut(history: NavigateFunction) {
      try {
        await Auth.signOut();
        getRoot<IRootModel>(self).resetStore();
        history(toPath(PATHS.home));
        window.location.reload();
      } catch (e) {
        console.log(e, "failed to log out");
      }
    },
    async loadAccountData() {
      try {
        const authData = await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        console.log({ authData });
        const groups =
          authData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.length > 1) {
          console.log("more than one group!");
        }
        self.setGroupId(groups[0]);
        self.setUserAttributes(authData.attributes);

        const resp = (await API.graphql(
          graphqlOperation(getAccount, { id: self.cognitoGroupId })
        )) as { data: GetAccountQuery };

        if (resp.data.getAccount) {
          const { projects, fileuploads, ...accountSettings } =
            resp.data.getAccount;
          self.setFileUploads(fileuploads);
          self.setAccountData(accountSettings);

          await getRoot<IRootModel>(self).projectsStore.loadProjects();
          // if (projects && projects.items && projects.items.length) {
          //   getRoot<IRootModel>(self).projectsStore.loadProjectsSuccess(
          //     projects.items as any
          //   );
          // } else {
          //   getRoot<IRootModel>(self).projectsStore.loadProjectsSuccess([]);
          // }
        }
      } catch (e) {
        console.log(e);
      }

      /*
      signInUserSession.accessToken.payload[""cognito:groups""]
      */
      // const data = await API.graphql(graphqlOperation(listProjects))
      // self.loadProjectsSuccess(projectsData.data.listProjects.items)
    },
    /**
     * Update the account linked CSV files and set the fileToDelete to a temp folder on S3 to be deleted in 7 days
     * @param updatedFileNames A array of files to update the account information with
     * @param fileToDelete The file that was set to be deleted 
     */
    async updateFileUploads(updatedFileNames: any, fileToDelete: string) {
      const input = { id: self.cognitoGroupId, fileuploads: updatedFileNames };

      const resp = (await API.graphql(
        graphqlOperation(updateAccount, { input })
      )) as { data: UpdateAccountMutation };
      if (resp.data.updateAccount) {
        self.setFileUploads(updatedFileNames);
      } else {
        throw new Error("");
      }

      // After updating the account information on DynamoDB, move the CSV file from the S3 public folder to the S3 temp folder
      // The temp folder has this format: public/temp/account's cognito group id/file name
      // The deletion of the file will be handled by a lifecicle rule already implemented on S3

      // Copy the file to the temp folder
      const from = { key: fileToDelete };
      const to = { key: "temp/" + self.cognitoGroupId + "/" + fileToDelete };
      console.log({ from, to });
      await Storage.copy(from, to);
      // Delete the file from the public folder
      await Storage.remove(from.key);

    },
    async connectHubspot(data: ITokenResponseIF) {
      self.hubspotConnection = JSON.stringify({
        ...data,
        updatedAt: Date.now(),
      });
      const input = {
        id: self.cognitoGroupId,
        integrations: { hubspot: self.hubspotConnection },
      };
      (await API.graphql(graphqlOperation(updateAccount, { input }))) as {
        data: UpdateAccountMutation;
      };
    },
    async disconnectHubspot() {
      self.hubspotConnection = null;
      const input = {
        id: self.cognitoGroupId,
        integrations: { hubspot: self.hubspotConnection },
      };
      (await API.graphql(graphqlOperation(updateAccount, { input }))) as {
        data: UpdateAccountMutation;
      };
    },
  }))
  .actions((self) => ({
    async refreshHubspotCredentials() {
      const apiName = "platformRest";
      const path = "/integration/hubspot/refreshToken";
      const myInit = {
        crossDomain: true,
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        body: {
          refreshToken: JSON.parse(self.hubspotConnection || "").refreshToken,
        },
      };
      const data: ITokenResponseIF = await API.post(apiName, path, myInit);
      await self.connectHubspot(data);
    },
  }));

export default accountModel.create({});
export type IaccountModel = Instance<typeof accountModel>;
