import { observer } from "mobx-react-lite";
import * as React from "react";
import { Route, useParams, Routes } from "react-router-dom";
import { PROJECT_PATHS, toPath } from "../../PATHS";
import { IProjectModel } from "../../stores/project/projectModel";
import { useMst } from "../../stores/Root";
import DemoPage from "../ProjectDemoPage/DemoPage";
import ProjectLiveControl from "../ProjectLiveControl/ProjectLiveControl";
import CreateDataPointPage from "../ProjectCreateDataPoint/CreateDataPointPage";
import { ProjectAnalytics as CustomAnalytics } from "./AnalyticsIframe";
import { AnalyticsNew } from "./Analytics";
import "./ProjectView.scss";
import { IuploadDataModel } from "../../stores/uploadData";
import { ProjectSettings } from "../ProjectSettings/ProjectSettings";
import EditVideo from "../EditVideo/EditVideo";
import { Integration } from "../IntegrationPage/Integration";
import { AsyncOpState } from "../../types/enums/async-op-states";
import LoadingSpinner from "../../assets/Ellipse 7.png";
import { CloseOutlined } from "@ant-design/icons";
import { SdkIntegration } from "../IntegrationPage/SiteIntegration/SdkIntegration";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
  selectedProjectLoading: boolean;
};

const ProjectViewWrapper = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = React.useState<any>();

  const storeMethod = useMst((store) => {
    return {
      setSelectedProjectById: store.projectsStore.setSelectedProjectById,
      selectedProjectLoading: store.projectsStore.projectIdCurrentlyLoading,
      project: store.projectsStore.selectedProject,
      projectLoadingStatus: store.projectsStore.projectLoadingStatus,
      uploadData: store.uploadDataStore,
      selectedProject: store.projectsStore.selectedProject,
    };
  });
  const {
    setSelectedProjectById,
    project,
    selectedProjectLoading,
    uploadData,
    projectLoadingStatus,
  } = storeMethod;

  React.useEffect(() => {
    const load = async (id: string) => await setSelectedProjectById(id);
    id && load(id);
    setState({ project, selectedProjectLoading, uploadData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    storeMethod.project?.workspaceVideoParts?.length,
    selectedProjectLoading,
  ]);

  if (projectLoadingStatus === AsyncOpState.Error) {
    return (
      <div className="spinner">
        <CloseOutlined /> Invalid Project ID. Please retry or contact support
      </div>
    );
  } else if (selectedProjectLoading) {
    return (
      <div className="spinner">
        <img src={LoadingSpinner} className="Rotate" alt="Loading Icon" />{" "}
        Gathering your MP5 assets
      </div>
    );
  }

  if (!state?.project) {
    return null;
  }
  return <ProjectView {...state} />;
});
/*

enum Operation {
  analytics,
  settings,
  demo,
  csvfile
}
*/

// const ButtonGroup = Button.Group;

function ProjectView({ project, uploadData, selectedProjectLoading }: Props) {
  // const [operation, setOperation] = useState(Operation.settings);
  // const rm = useRouteMatch();
  //const { op } = useParams<{ op: string; }>();

  let hasControl = false;
  try {
    if (project.settingsJsonSchemaStr) {
      const control = JSON.parse(project.settingsJsonSchemaStr);
      if (control && control.properties) {
        hasControl = JSON.stringify(control.properties) !== "{}";
      }
    }
  } catch (e) {
    console.error(e);
  }

  return (
    <Routes>
      <Route
        path={PROJECT_PATHS.demo}
        element={
          <DemoPage
            project={project}
            uploadData={uploadData}
            isLoading={selectedProjectLoading}
          />
        }
      />
      <Route
        path={PROJECT_PATHS.customAnalytics}
        element={
          <CustomAnalytics analyticsReportUrl={project.analyticsReportUrl} />
        }
      />
      {
        <Route
          path={PROJECT_PATHS.analytics}
          element={<AnalyticsNew projectId={project.id} />}
        />
      }
      <Route
        path={PROJECT_PATHS.liveControl}
        element={<ProjectLiveControl project={project} />}
      />
      <Route
        path={PROJECT_PATHS.settings}
        element={<ProjectSettings project={project} />}
      />
      <Route
        path={PROJECT_PATHS.createDataPoint}
        element={<CreateDataPointPage project={project} />}
      />
      <Route
        path={toPath(PROJECT_PATHS.editVideo, ":scene?")}
        // element={
        //   <Navigate
        //     to={ toPath( PROJECT_PATHS.editVideo,  project.workspaceVideoParts[0]?.name || "")}
        //     replace
        //   />
        // }
        element={<EditVideo />}
      />
      <Route //TODO: Remove route after new integration is done
        path={PROJECT_PATHS.integration}
        element={
          <div style={{ padding: "2rem" }}>
            <SdkIntegration project={project} uploadData={uploadData} />
          </div>
        }
      />
      <Route // TODO: temporary until old integration is removed
        path={"integration-new"}
        element={<Integration project={project} uploadData={uploadData} />}
      />
    </Routes>
  );
}

export default ProjectViewWrapper;
